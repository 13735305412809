import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";

function DayDetail({index, startIndex, inputValues, selectedYear, selectedMonth, headerOnClick, taskOnClick, removeTask, dayClass}) {
    const cardClass = `card shadow calendar-card-${dayClass}`;
    const headerClass = `card-header calendar-card-header-${dayClass}`;
    const bodyClass = `card-body calendar-card-body-${dayClass}`;

    useEffect(() => {
        $('.calendar-card-body-active, .calendar-card-body-today').each(function(){
            if($(this).text() === ''){
                $(this).css('overflow','hidden');
            }else{
                $(this).css('overflow','scroll');
            }
        });
    },[inputValues]);

    return ( 
        <div key={index} className={cardClass} style={{margin:"1px"}}>
            <div className={headerClass} onClick={() => headerOnClick(`${selectedMonth}/${index - startIndex + 1}/${selectedYear}`)}>
                <div className="calendar-card-header-day">{index - startIndex + 1}</div>
                <div className="calendar-card-header-icon">
                    {/* <Link >
                        <FontAwesomeIcon icon={faCalendar} size="xs" />
                    </Link> */}
                </div>
            </div>
            <div className={bodyClass}>
                <ul>
                {
                    (inputValues.tasks.filter(t => t.startDate === `${selectedMonth}/${index - startIndex + 1}/${selectedYear}`).map((t,i) => 
                        <li key={i}>
                            <Link className="calendar-detail-link" onClick={() => taskOnClick(t)} >{t.messageName}<br/>{t.startHourText}</Link>&nbsp;&nbsp;
                            <Link onClick={() => removeTask(t)}><FontAwesomeIcon icon={faTrash} size="xs" /></Link>
                        </li>
                    ))
                }
                </ul>
            </div>
        </div>
     );
}

export default DayDetail;