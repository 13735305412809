import React from 'react';
//import Card from 'react-bootstrap/Card';
//import splashImg1 from '../assets/clientpro-splash1.jpg';

function Splash() {

    return (  
        <>
            <div className="splash-container">
                <h2 className="splash-title">Welcome to ClientPro</h2>
                <div className="splash-card shadow">
                    <div className="splash-image-odd image1" />                    
                    <div className="splash-text-odd">
                        Do you have a need to reach your clients via SMS text messaging but find it hard to keep track of all those clients in your contacts on your phone?<br/>&nbsp;<br/>
                        Do you spend ungodly amounts of time texting each client?<br/>&nbsp;<br/>
                        Look no further! ClientPro is here to help!
                    </div>
                </div>

                <div className="splash-card shadow">
                    <div className="splash-text-even">
                        We understand how time-consuming it is to reach out to multiple clients. ClientPro allows you to easily manage your clients and send bulk messages to your list of clients.
                        <br/>&nbsp;<br/>
                        With ClientPro, not only can you create custom messages to be sent to all or some of your clients. You can even schedule your messages to be sent while you spend your time doing
                         other important things!
                         <br/>&nbsp;<br/>
                         Spend more time with the family or spend more time working on other important needs. ClientPro will take care of the messaging for you!
                         <br/>&nbsp;<br/>
                         ClientPro offers three ways to send messages to your clients.<br/>&nbsp;<br/>
                        <ul>
                            <li><b>Send messages instantly</b></li>
                            <li><b>Schedule repeating messages</b></li>
                            <li><b>Schedule non-repeating messages</b></li>
                        </ul>                         
                    </div>
                    <div className="splash-image-even image2" />
                </div>

                <div className="splash-card shadow">
                    <div className="splash-image-odd image3" />                    
                    <div className="splash-text-odd text3">
                        <h3>Instant Text Messages</h3>
                        With Client pro, create your message, create your custom client list, and send it!<br/>&nbsp;<br/>
                        <h3>Repeating Messages</h3>
                        Do you need the same message sent to the same clients at multiple intervals? ClientPro has you covered. You can schedule a message 
                        to be sent daily, weekly, monthly or customize when you want your message to repeat. You can edit the messages and client lists between sends!<br/>&nbsp;<br/>
                        <h3>Scheduled Messages</h3>
                        With ClientPro's calendar, you will be able to add message tasks to be carried out on calendar days. You have the ability to choose the day, time, message and who will receive the message.
                    </div>
                </div>

                <h1 className="h2-centered">Sign up and get started today!</h1>

            </div>
        </>
    );
}

export default Splash;