import React from 'react';
import { toast } from 'react-toastify';

async function popTask(task) {
    //console.log('task length',task);
    if(task){
        const toastProps = {
            position: "top-center",
            autoClose: 7000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        }

        const theMsg = ({closeToast, toastProps}) => (
            <div style={{width:"100%"}}>
                <table style={{width:"100%",fontSize:"8px"}}>
                    <thead>
                        <tr><th colSpan="2" style={{textAlign:"center"}}>{task.startDate} {task.startHourText}</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="25%">Message:</td><td width="75%">{task.messageName}</td>
                        </tr> 
                        <tr>
                            <td>List:</td><td>{task.listName}</td>
                        </tr>                                  
                    </tbody>
                </table>
            </div>
        );
        
        toast(theMsg, toastProps);
    }
};

export default popTask;