import _ from 'lodash';

export const weekArray = [
    {"value":"Sun"},
    {"value":"Mon"},
    {"value":"Tue"},
    {"value":"Wed"},
    {"value":"Thu"},
    {"value":"Fri"},
    {"value":"Sat"}
  ];

export const gridArray = _.range(0, 42);

export const yearOptions = [
  {"value":"2022"}, {"value":"2023"}, {"value":"2024"}, {"value":"2025"}
];

export const monthOptions = [
  {"value":"1", "abbrev":"Jan", "name": "January"},
  {"value":"2", "abbrev":"Feb", "name": "Febuary"},
  {"value":"3", "abbrev":"Mar", "name": "March"},
  {"value":"4", "abbrev":"Apr", "name": "April"},
  {"value":"5", "abbrev":"May", "name": "May"},
  {"value":"6", "abbrev":"Jun", "name": "June"},
  {"value":"7", "abbrev":"Jul", "name": "July"},
  {"value":"8", "abbrev":"Aug", "name": "August"},
  {"value":"9", "abbrev":"Sep", "name": "September"},
  {"value":"10", "abbrev":"Oct", "name": "October"},
  {"value":"11", "abbrev":"Nov", "name": "November"},
  {"value":"12", "abbrev":"Dec", "name": "December"}
];