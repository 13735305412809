import React, {useState, useEffect, useRef, forwardRef} from 'react';

const TextArea = forwardRef((props, ref) => {
    const {name, label, value, onChange, error, autoFocus, rows, cols, customClass, setInputValue} = props;
    const [remaining, setRemaining] = useState(0);
    const dataFetchedRef = useRef(false);
    const limit = window.env.MESSAGE_LIMIT;
    
    function handleKeyUp(e){
        const { value: taValue } = e.target;
        setRemaining(limit - taValue.length);
    }

    const insertAtCursor = (textToInsert) => {
        let textarea = document.getElementById(name);
        let textBeforeCursor = textarea.value.substring(0, textarea.selectionStart);
        let textAfterCursor = textarea.value.substring(textarea.selectionEnd);
        let newValue = textBeforeCursor + textToInsert + textAfterCursor;
        textarea.value = newValue;
        textarea.focus();
        setInputValue(newValue);
    };

    setTimeout(() => insertAtCursor, 1000);

    useEffect(() => {
        function initCount(){
            setRemaining(limit - value.length);                                
        }        
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        initCount();
        
    },[limit, value.length]);

    return ( 
        <div className="form-group mb-2">
            <div>
                <label htmlFor={name}>{label}</label>
                <span onClick={() => insertAtCursor('[[first]]')} className="badge badge-secondary badge-sm" style={{marginLeft:"10px"}}>Client FName</span>
                <span onClick={() => insertAtCursor('[[last]]')} className="badge badge-secondary badge-sm">Client LName</span>
                <span onClick={() => insertAtCursor('[[myfirst]]')} className="badge badge-secondary badge-sm">My FName</span>
                <span onClick={() => insertAtCursor('[[mylast]]')} className="badge badge-secondary badge-sm">My LName</span>
                <span onClick={() => insertAtCursor('[[myemail]]')} className="badge badge-secondary badge-sm">My Email</span>
                <span onClick={() => insertAtCursor('[[myphone]]')} className="badge badge-secondary badge-sm">My Phone</span>
            </div>
            <textarea 
                ref={ref}
                className={`form-control shadow `+customClass} 
                name={name} 
                id={name} 
                value={value} 
                onChange={onChange}
                onKeyUp={(e) => handleKeyUp(e)}
                error={error} 
                rows={rows}
                cols={cols}
                maxLength={limit}
                autoFocus={autoFocus}
            />
            <div id="chrCountDiv" style={{textAlign:"right",fontSize:"9pt"}}>{remaining} characters of {limit} remaining.</div>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
});

export default TextArea;