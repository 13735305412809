import React, {useState, useEffect, useRef} from 'react';
import {getClientList, deleteClient} from '../../services/clientService';
import PopDelete from "../common/popDelete";
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import {formatDate} from '../../utils/formatDate';
//import Pagination from "./common/pagination";
//import { paginate } from "../utils/paginate";
//import _ from "lodash";
//import SearchBox from "./searchBox";

const ClientList = ({user}) => {
    const [clients, setClients] = useState({});
    const dataFetchedRef = useRef(false);

    function popDelete(item){
        toast(
            <PopDelete 
                handleDelete={handleDelete} 
                itemId={item._id} 
                itemName={item.firstName + '' + item.lastName}
                toastId='delete'
        />
        ,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'delete'});
    }

    async function handleDelete(_id){
        const res = await deleteClient(_id);
        if(res){
            toast('Client deleted.');
            setTimeout(function(){
                window.location = '/clients';
            },2000);
        }
    }

    useEffect(() => {
        async function fetchClients(){
            try {
                const clients = await getClientList(user._id);
                if(clients){
                    //console.log('clients',clients);
                    setClients(clients);
                }
            } catch (ex) {
                toast('No clients found.');
            }                            
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchClients();
        window.scrollTo(0, 0);
    },[user._id]);

    if(Object.keys(clients).length === 0) 
        return <><p>You have no clients in the database.</p>
                    <p><Link to="/addclient" className='btn btn-primary shadow'>Add Client</Link></p>
                    <p><Link to="/uploadclients" className="btn btn-primary shadow" style={{ marginBottom: 20 }}>Import Clients</Link></p>
                </>;
    return (
        <React.Fragment>
            <h3 className="h3-centered">My Clients</h3>
            
            <Link to="/addclient" className="btn btn-primary shadow" style={{ marginBottom: 20 }}>Add Client</Link>            
            
            <p>Showing {Object.keys(clients).length} clients in the database.</p>
            <table className="client-list table table-striped shadow">
                <thead>
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        {user.isOptavia === false && 
                            <th scope="col">State</th>
                        }
                        <th scope="col">Entry Date</th>
                        {user.isOptavia === true &&
                            <th scope="col">Last Order</th>
                        }
                        <th scope="col">Status</th>
                        {user.isOptavia === true &&
                            <th scope="col">Premier</th>
                        }
                        {user.isOptavia === false &&
                            <th scope="col">Contact Pref</th>
                        }
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(clients).map((key) => 
                    <tr key={key}>
                        <td>{clients[key].firstName}</td> 
                        <td>{clients[key].lastName}</td>
                        <td>{clients[key].email}</td>
                        <td>{clients[key].phone}</td>
                        {user.isOptavia === false &&
                            <td>{clients[key].state}</td>
                        }
                        <td>{formatDate(clients[key].entryDate)}</td>
                        {user.isOptavia === true &&
                            <td>{(clients[key].lastOrderDate !== '' ? formatDate(clients[key].lastOrderDate) : '')}</td>
                        }
                        <td>{clients[key].accountStatus}</td>
                        {user.isOptavia === true &&
                            <td>{clients[key].premierMember}</td>
                        }
                        {user.isOptavia === false &&
                            <td>{clients[key].contactPreference}</td>
                        }
                        <td nowrap="nowrap">
                            <Link to={`/client`} state={{client: clients[key]}}><FontAwesomeIcon icon={faUserEdit} size="sm" /></Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link onClick={() => popDelete(clients[key])}><FontAwesomeIcon icon={faTrash} size="sm" /></Link>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </React.Fragment>
    );
}
 
export default ClientList;