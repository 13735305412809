import React from 'react';

function TaskDisclaimer() {
    return ( 
        <>
        <p><u>Note:</u> Please use respect in scheduling text messages. Not everyone enjoys being solicited by text messages or receiving messages at certain times of the day. This app 
        will limit your ability to send texts as frequent as daily to help guard from over-soliciting your clients and for the usage costs for this app.</p>
        <p>Vulgarity, racial, or hate speech will not be tolorated and will result in your account being banned with no refund.</p>
        <p>Thank you for your understanding and cooperation!</p>
        <i>* All times are Eastern Standard Time.</i>
        </>
     );
}

export default TaskDisclaimer;