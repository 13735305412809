import React, { useState, useEffect, useRef } from 'react';
//import { getClientList } from "../services/clientService";
import { checkPlanLimits } from "../services/userService";
import { sendMessage } from "../services/smsService";
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import { toast } from "react-toastify";
import Splash from "./splash";

const Home = ({user}) => {
    //const [clients, setClients] = useState({});
    const [showSteps, setShowSteps] = useState({
        steps: {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false
        }
    });
    const [selectedMessage, setSelectedMessage] = useState("");
    const [selectedList, setSelectedList] = useState("");
    const [loading, setLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [limitReached, setLimitReached] = useState({pass: true, reason: ''});
    const dataFetchedRef = useRef(false);

    const sendTheMessage = async () => {
        setLoading(true);
        const result = await sendMessage({"messageID": selectedMessage, "listID": selectedList});
        if(result){
            //console.log('result',result);
            toast(result.data);
            setLoading(false);
            setMessageSent(true);
        }
    }

    useEffect(() => {
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if(user){
            async function checkLimits(){
                await checkPlanLimits(user, 'sms')
                    .then(results => {
                        setLimitReached({...limitReached, pass: results.pass, reason: results.reason});
                        if(results.pass === true){
                            setShowSteps({steps: { step1: false, step2: true, step3: false, step4: false, step5: false }});
                        }
                    });
            }
            checkLimits();
            setShowSteps({
                steps: {
                    step1: true,
                    step2: false,
                    step3: false,
                    step4: false
                }
            });
        }
        window.scrollTo(0, 0);
    });

    return (
            <React.Fragment>
                {user !== null ? (
                <div>
                    <h3 className="h3-centered">Welcome to ClientPro</h3>
                    <div>
                        {showSteps.steps.step1 &&
                            <Step1/>
                        }
                        {showSteps.steps.step2 &&
                            <Step2 onStep2NextClick={() => {setShowSteps({steps: { step1: false, step2: false, step3: true, step4: false, step5: false }})}}/>
                        }
                        {showSteps.steps.step3 &&
                            <Step3 
                                user={user} 
                                onStep3BackClick={() => setShowSteps({steps: { step1: false, step2: true, step3: false, step4: false, step5: false }},setSelectedMessage(''))} 
                                onStep3NextClick={() => setShowSteps({steps: { step1: false, step2: false, step3: false, step4: true, step5: false }})}
                                selectedMessage={selectedMessage} 
                                setSelectedMessage={setSelectedMessage}
                            />
                        }
                        {showSteps.steps.step4 &&
                            <Step4 
                                user={user} 
                                onStep4BackClick={() => setShowSteps({steps: { step1: false, step2: false, step3: true, step4: false, step5: false }},setSelectedList(''))} 
                                onStep4NextClick={() => setShowSteps({steps: { step1: false, step2: false, step3: false, step4: false, step5: true }})} 
                                selectedList={selectedList} 
                                setSelectedList={setSelectedList}
                            />
                        }
                        {showSteps.steps.step5 &&
                            <Step5 user={user} 
                                onStep5BackClick={() => setShowSteps({steps: { step1: false, step2: false, step3: false, step4: true, step5: false }})} 
                                onStep5NextClick={() => sendTheMessage()}
                                loading={loading}
                                messageSent={messageSent}
                                reset={() => setShowSteps({steps: { step1: false, step2: true, step3: false, step4: false, step5: false }}, setSelectedMessage(''), setSelectedList(''), setMessageSent(false))}
                                selectedMessage={selectedMessage}
                                selectedList={selectedList}
                                userID={user._id}
                            />
                        }
                        {limitReached.pass === false &&
                            <div className="error-box container-width-5">{limitReached.reason}</div>
                        }
                    </div>
                </div>  
                ):(
                    <Splash />
                )}           
            </React.Fragment>
    );
}
 
export default Home;