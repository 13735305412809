import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getTasks, deleteTask } from '../../services/taskService';
import { getMessage } from "../../services/messageService";
import PopDelete from "../common/popDelete";
import popStatus from "../common/popStatus";
import popMessage from "../common/popMessage";
import popClientList from "../common/popClientList";
import popCron from "../common/popCron";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faMessage, faTrash, faClipboard, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import moment from "moment/moment";

function TaskList({user}) {
    const [tasks, setTasks] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [messageLog, setMessageLog] = useState({});
    const dataFetchedRef = useRef(false);

    function popDelete(item){
        toast(
            <PopDelete 
                handleDelete={handleDelete} 
                itemId={item._id} 
                itemName={item.name}
                toastId='delete'
        />
        ,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'delete'});
    }

    async function showLog(messageID){
        await getMessage(messageID)
            .then(message => {
                if(message.hasOwnProperty('log')){
                    setMessageLog(message.log);
                    setShowModal(true);
                }
            });
    }

    async function handleDelete(_id){
        const res = await deleteTask(_id);
        if(res){
            toast('Task deleted.');
            setTimeout(function(){
                window.location = '/tasks';
            },2000);
        }
    }

    function getDateFromTask(startDate){
        let tDate = moment(startDate);
        return tDate.year() + '/' + Number(tDate.month()+1).toString();
    }

    useEffect(() => {
        async function fetchTasks(){
            try{
                setTasks(await getTasks(user._id));
            }catch(ex){
                toast('No scheduled SMS found.');
            }                            
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchTasks();
        window.scrollTo(0, 0);
    },[user._id]);

    return ( 
        <React.Fragment>
            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Results from the last time this message was sent:</h3>
                <div className="container">
                    {Object.keys(messageLog).map(m =>
                    <div className="row" key={messageLog[m].phone}>
                        <div className="col">
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Client:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{messageLog[m].clientName}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Phone:</div>
                                <div className="col" style={{maxWidth:"70%"}}>+{messageLog[m].phone}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Status:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{messageLog[m].status}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Date Time:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{new Date(messageLog[m].timestamp).toString()}</div>
                            </div>
                            {messageLog[m].status === "Error" &&
                                <div className="row">
                                    <div className="col" style={{maxWidth:"30%"}}>Error:</div>
                                    <div className="col" style={{maxWidth:"70%",color:"red"}}>{messageLog[m].result}</div>
                                </div>
                            }
                            <div className="row">
                                <div className="col" style={{width:"100%"}}><hr/></div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    <Link onClick={()=>setShowModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>
            <h3 className="h3-centered">My Repeating SMS</h3>
            
            <Link to="/addtask" className="btn btn-primary" style={{ marginBottom: 20 }}>Add Repeating SMS</Link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/calendar" className="btn btn-primary" style={{ marginBottom: 20 }}>Add Scheduled SMS</Link>            
            
            <p>Showing {Object.keys(tasks).length} tasks in the database.</p>
            <table className="task-list table table-striped">
                <thead>
                    <tr>
                        <th scope="col" width="28%">Name</th>
                        <th scope="col" width="12%">Type</th>
                        <th scope="col" width="10%" style={{textAlign:"center"}}>Message</th>
                        <th scope="col" width="10%" style={{textAlign:"center"}}>Client List</th>
                        <th scope="col" width="10%" style={{textAlign:"center"}}>Schedule</th>                        
                        <th scope="col" width="10%" style={{textAlign:"center"}}>Status</th>
                        <th scope="col" width="10%" style={{textAlign:"center"}}>Log</th>
                        <th scope="col" width="10%" ></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(tasks).map((key) => 
                    <tr key={key}>                        
                        <td>{tasks[key].name}</td> 
                        <td>{tasks[key].type === 'repeating' ? 'Repeating' : 'Non-Repeating'}</td>
                        <td align="center">
                            <Link onClick={() => {popMessage(tasks[key].messageID, user)}}>
                                <FontAwesomeIcon icon={faMessage} size="sm" />
                            </Link>
                        </td>
                        <td align="center">
                            <Link onClick={() => {popClientList(tasks[key].listID)}}>
                                <FontAwesomeIcon icon={faClipboard} size="sm" />
                            </Link>
                        </td>
                        <td align="center">
                            {tasks[key].type === 'repeating' ? (
                                <Link onClick={() => {popCron(tasks[key])}}>
                                    <FontAwesomeIcon icon={faCalendar} size="sm" />
                                </Link>
                            ):(
                                <Link onClick={() => {popCron(tasks[key])}}>
                                    <FontAwesomeIcon icon={faCalendar} size="sm" />
                                </Link>
                            )}
                        </td>                    
                        <td align="center">
                            <Link onClick={() => {popStatus(tasks[key], tasks[key].type)}}>
                                <FontAwesomeIcon icon={faClock} size="sm" />
                            </Link>
                        </td>
                        <td align="center">
                            <Link onClick={() => {setShowModal(true, showLog(tasks[key].messageID))}}>
                                <FontAwesomeIcon icon={faClipboard} size="sm" />
                            </Link>
                        </td>
                        <td align="center" nowrap="nowrap">
                            {tasks[key].type === 'repeating' ? (
                                <Link to={`/task/${tasks[key]._id}`} state={{task: tasks[key]}}><FontAwesomeIcon icon={faFilePen} size="sm" /></Link>
                            ):(
                                <Link to={`/calendar/${getDateFromTask(tasks[key].startDate)}`} state={{task: tasks[key]}}><FontAwesomeIcon icon={faFilePen} size="sm" /></Link>
                            )}
                                &nbsp;&nbsp;&nbsp;
                            <Link onClick={() => popDelete(tasks[key])}><FontAwesomeIcon icon={faTrash} size="sm" /></Link>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </React.Fragment>
    );
}

export default TaskList;