import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/clients";
http.setJwt(auth.getJwt());
 
export async function getClientList(userid) {
    try{
        let { data: clients } = await http.get(apiEndpoint+'/all/'+userid, {});
        return clients.sort(c => c.lastName);
    }catch(ex){
        //toast.error(ex.response.data);
        return {};
    }
}

export async function getClientCount(userid) {
    try{
        let { data: count } = await http.get(apiEndpoint+'/count/'+userid, {});
        return count;
    }catch(ex){
        //toast.error(ex.response.data);
        return {};
    }
}

export async function getClient(clientid) {
    const { data: client } = await http.get(apiEndpoint+'/'+clientid, {});
    return client;
}

export async function getClientsFiltered(data) {
    try{      
        let clients = null; 
        const query = JSON.stringify(data);
        await http.get(`${apiEndpoint}?search=${query}`)
            .then(results => {
                clients = results.data;
            });
        return clients;
    }catch(ex){
        toast.error(ex.response.data);
    }
}

export async function createClient(data) {
    let dataArray = [];
    dataArray.push(data);
    try {
        let { data: client } = await http.post(apiEndpoint, dataArray);
        return client;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function createClients(data) {
    // remove _id from data before sending to backend
    Object.keys(data).forEach(d => {
        delete data[d]._id;
        delete data[d].existing;
        delete data[d].disabled;
        delete data[d].ref;
        delete data[d].import;
    });
    try {
        let { data: client } = await http.post(apiEndpoint, data);
        return client;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function updateClient(data) {
    const {_id} = data;
    try {
        delete data._id;
        let { data: client } = await http.put(apiEndpoint+'/'+_id, data);
        return client;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function deleteClient(_id) {
    try {
        let { data: client } = await http.delete(apiEndpoint+'/'+_id, {});
        return client;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export const accountStatusOptions = [
    {text:"Active",value:"Active"},
    {text:"Inactive",value:"Inactive"},
    {text:"Reverted",value:"Reverted"}
];

export const premierMemberOptions = [
    {text:"Yes",value:"Yes"},
    {text:"No",value:"No"}
];

export const contactPreferenceOptions = [
    {text:"None",value:"none"},
    {text:"Morning",value:"morning"},
    {text:"Mid-Day",value:"mid-day"},
    {text:"Evening",value:"evening"},
    {text:"Never",value:"never"}
];

const exports = {
    getClientList,
    getClientCount,
    getClient,
    getClientsFiltered,
    createClient,
    createClients,
    deleteClient,
    accountStatusOptions,
    premierMemberOptions,
    contactPreferenceOptions
}
  
export default exports;