import React, { useState, useEffect, createRef, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from '../common/select';
import CNDate from "../common/cndate";
import { default as countries } from "../../services/countries.json";
import { default as states } from "../../services/states.json";
import { default as yesNoOptions } from "../../services/yesNo.json";
import { formatDate } from "../../utils/formatDate";
import { getClientsFiltered, accountStatusOptions } from "../../services/clientService";

function ClientFilters({user, setClients, list, setListFilters}) {
    const dataFetchedRef = useRef(false);
    const [showModal, setShowModal] = useState(false);
    const stateRef = createRef();
    const countryRef = createRef();
    const accountStatusRef = createRef();
    const premierMemberRef = createRef();
    const orderDateFromRef = createRef();
    const orderDateToRef = createRef();
    const entryDateFromRef = createRef();
    const entryDateToRef = createRef();
    const contactPreferenceRef = createRef();
    const [filters, setFilters] = useState([
        {
            name: 'userID',
            value: user._id
        },
        {
            name: 'state',
            value: null
        },
        {
            name: 'country',
            value: null
        },
        {
            name: 'accountStatus',
            value: null
        },
        {
            name: 'lastOrderDate',
            value: null,
            condition: null,
            altvalue: null
        },
        {
            name: 'entryDate',
            value: null,
            condition: null,
            altvalue: null
        },
        {
            name: 'premierMember',
            value: null
        },
        {
            name: 'excludeContactPreference',
            value: null
        }
    ]);
    
    function addFilter(e, date, filter){
        /* if(e !== null){
            e.stopPropagation();
        } */
        switch(filter){
            case 'state':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === filter){
                            return {...obj, value: stateRef.current.value};
                        }
                    return obj;
                    }),
                );
            break;
            case 'country':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === filter){
                            return {...obj, value: countryRef.current.value};
                        }
                    return obj;
                    }),
                );
            break;
            case 'accountStatus':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === filter){
                            return {...obj, value: accountStatusRef.current.value};
                        }
                    return obj;
                    }),
                );
            break;
            case 'premierMember':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === filter){
                            return {...obj, value: premierMemberRef.current.value};
                        }
                    return obj;
                    }),
                );
            break;
            case 'entryDateFrom':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === 'entryDate'){
                            return {...obj, value: (date === null ? null : formatDate(date))};
                        }
                    return obj;
                    }),
                );
            break;
            case 'entryDateTo':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === 'entryDate'){
                            return {...obj, altvalue: (date === null ? null : formatDate(date))};
                        }
                    return obj;
                    }),
                );
            break;
            case 'lastOrderDateFrom':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === 'lastOrderDate'){
                            return {...obj, value: (date === null ? null : formatDate(date))};
                        }
                    return obj;
                    }),
                );
            break;
            case 'lastOrderDateTo':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === 'lastOrderDate'){
                            return {...obj, altvalue: (date === null ? null : formatDate(date))};
                        }
                    return obj;
                    }),
                );
            break;
            case 'excludeContactPreference':
                setFilters(f =>
                    f.map(obj => {
                        if(obj.name === 'excludeContactPreference'){
                            if(e.target.checked === true){
                                return {...obj, value: true};
                            }else{
                                return {...obj, value: null};
                            }
                        }
                    return obj;
                    }),
                );
            break;
            default:
                break;
        }
    }

    function removeFilter(filter){
        let tempFilters = filters;
        Object.keys(tempFilters).forEach(f => {
            if(tempFilters[f].name === filter){
                tempFilters[f].value = null;
            }
        });
        setFilters(tempFilters);
        processFilters(tempFilters);
    }

    const fetchFilteredClients = useCallback(async(data) => {
        try {
            await getClientsFiltered(data)
                .then(filteredClients => {
                    if(Object.keys(filteredClients).length){
                        
                        Object.keys(filteredClients).forEach(c => {
                            // add selected key
                            if(typeof list !== 'undefined' && Object.keys(list).length){
                                let selectedClients = list.clients;
                                let found = selectedClients.find(s => s === filteredClients[c]._id);
                                filteredClients[c] = {...filteredClients[c], selected: (typeof found !== 'undefined' ? true : false)};
                            }else{
                                filteredClients[c] = {...filteredClients[c], selected: false};
                            }
                        });
                        //console.log('filteredClients',filteredClients);
                        setClients(filteredClients);
                        setListFilters(filters);                    
                    }else{
                        // this should never be called because there should be at least one filter that of the userID
                        setClients([]);
                    } 
                });           
        } catch (ex) {
           //toast('No clients found with these filters.');
           //console.log(ex.message);
        }                       
    });

    const processFilters = (filters) => {      
        let data = JSON.stringify(filters);
        fetchFilteredClients(JSON.parse(data));             
    }; 

    useEffect(() => {
        if(dataFetchedRef.current === false && typeof list !== 'undefined' && Object.keys(list).length){
            dataFetchedRef.current = true;
            if(list.filters.length){
                setFilters(list.filters);
                setTimeout(()=>{
                    processFilters(list.filters);
                },400);
            }
        }else{
            processFilters(filters);
        }
        
    },[filters]);

    return ( 
        <React.Fragment>
           
            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Client Filters</h3>
                <div className="container text-center">
                    <div className="row">
                        <div className="col" style={{textAlign:"left"}}>
                            <Select 
                                ref={stateRef}
                                name="state" 
                                label="Client State" 
                                onChange={(e)=>addFilter(e,null,"state")}
                                items={states} 
                                keys={['name','abbreviation']}
                                selectedOption={filters.filter(f => f.name === 'state').map(f => f.value)[0] === null ? "" : filters.filter(f => f.name === 'state').map(f => f.value)[0]}
                                customClass="filters"
                            />
                        </div>
                        <div className="col" style={{textAlign:"left"}}>
                            <Select 
                                ref={countryRef}
                                name="country" 
                                label="Client Country" 
                                onChange={(e)=>addFilter(e,null,"country")}
                                items={countries} 
                                keys={['name','code']}
                                selectedOption={filters.filter(f => f.name === 'country').map(f => f.value)[0] === null ? "" : filters.filter(f => f.name === 'country').map(f => f.value)[0]}
                                customClass="filters"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign:"left"}}>
                            <Select 
                                ref={accountStatusRef}
                                name="accountStatus" 
                                label="Client Account Status" 
                                onChange={(e)=>addFilter(e,null,"accountStatus")}
                                items={accountStatusOptions} 
                                keys={['text','value']}
                                selectedOption={filters.filter(f => f.name === 'accountStatus').map(f => f.value)[0] === null ? "" : filters.filter(f => f.name === 'accountStatus').map(f => f.value)[0]}
                                customClass="filters"
                            />
                        </div>
                        <div className="col" style={{textAlign:"left"}}>
                            <Select 
                                ref={premierMemberRef}
                                name="premierMember" 
                                label="Premier Member" 
                                onChange={(e)=>addFilter(e,null,"premierMember")}
                                items={yesNoOptions} 
                                keys={['text','value']}
                                selectedOption={filters.filter(f => f.name === 'premierMember').map(f => f.value)[0] === null ? "" : filters.filter(f => f.name === 'premierMember').map(f => f.value)[0]}
                                customClass="filters"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign:"left"}}>
                            <CNDate
                                ref={entryDateFromRef}
                                name="entryDateFrom" 
                                label="Entry Date From"
                                value={Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].value})[0] === null ? "" : Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].value})[0]}
                                selected={Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].value})[0] === null ? new Date() : new Date(Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].value})[0])} 
                                onChange={(date) => addFilter(null,date,'entryDateFrom')}
                                customClass="filters"
                            />
                        </div>
                        <div className="col" style={{textAlign:"left"}}>
                            <CNDate
                                ref={entryDateToRef}
                                name="entryDateTo" 
                                label="Entry Date To"
                                value={Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].altvalue})[0] === null ? "" : Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].altvalue})[0]}
                                selected={Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].altvalue})[0] === null ? new Date() : new Date(Object.keys(filters).filter(f => filters[f].name === 'entryDate').map(f => {return filters[f].altvalue})[0])} 
                                onChange={(date) => addFilter(null,date,'entryDateTo')}
                                customClass="filters"
                            />
                        </div>                     
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign:"left"}}>
                            <CNDate
                                ref={orderDateFromRef}
                                name="lastOrderDateFrom" 
                                label="Last Order Date From"
                                value={Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].value})[0] === null ? "" : new Date(Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].value})[0])} 
                                selected={Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].value})[0] === null ? new Date() : new Date(Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].value})[0])} 
                                onChange={(date) => addFilter(null,date,'lastOrderDateFrom')}
                                customClass="filters"
                            />
                        </div>
                        <div className="col" style={{textAlign:"left"}}>
                            <CNDate
                                ref={orderDateToRef}
                                name="lastOrderDateTo" 
                                label="Last Order Date To"
                                value={Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].altvalue})[0] === null ? "" : new Date(Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].altvalue})[0])} 
                                selected={Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].altvalue})[0] === null ? new Date() : new Date(Object.keys(filters).filter(f => filters[f].name === 'lastOrderDate').map(f => {return filters[f].altvalue})[0])} 
                                onChange={(date) => addFilter(null,date,'lastOrderDateTo')}
                                customClass="filters"
                            />
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col" style={{textAlign:"left"}}>
                            {/* <input 
                                ref={contactPreferenceRef}
                                name="contactPreference" 
                                label="Exclude Clients With Contact Preference of Never?" 
                                onChange={(e)=>addFilter(e,null,"contactPreference")}
                                type="checkbox"
                                value={filters.filter(f => f.name === 'contactPreference').map(f => f.value)[0] === null ? false : filters.filter(f => f.name === 'contactPreference').map(f => f.value)[0]}
                                customClass=""
                                checked={contactPrefChecked}
                            /> */}
                            <span>Exclude Clients With Contact Preference of Never? &nbsp;</span>
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name="excludeContactPreference" 
                                id="excludeContactPreference" 
                                value={filters.filter(f => f.name === 'excludeContactPreference').map(f => f.value)[0] === null ? false : filters.filter(f => f.name === 'excludeContactPreference').map(f => f.value)[0]}
                                checked={filters.filter(f => f.name === 'excludeContactPreference').map(f => f.value)[0] === null ? false : filters.filter(f => f.name === 'excludeContactPreference').map(f => f.value)[0]} 
                                onChange={(e)=>addFilter(e,null,"excludeContactPreference")}
                                disabled=""
                            />
                        </div>
                    </div>
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    {/* contactPrefChecked = {contactPrefChecked.toString()}<br/> */}
                    <Link onClick={()=>setShowModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>

            <div>
                <Link onClick={()=>setShowModal(true)} className="btn btn-dark shadow cn-custom">Apply Filters</Link>&nbsp;&nbsp;&nbsp;
                {/* {  <div>{JSON.stringify(filters)}</div> }
                <div>&nbsp;</div>
                {  <div>list: {JSON.stringify(list.filters)}</div> } */}
                <div>
                    {Object.keys(filters).map(key => 
                        (filters[key].value !== null && filters[key].name !== 'userID') ? (
                            <button 
                                key={key} 
                                type="button" 
                                className="btn btn-secondary position-relative shadow cn-custom" 
                                style={{margin:"15px 10px 10px 0px"}}
                                onClick={() => removeFilter(filters[key].name)}
                            >                        
                            {filters[key].name}                              
                            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle shadow cn-custom">
                                X
                            </span>
                            </button>
                        
                        ):('')
                    )}
                <div>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}

export default ClientFilters;