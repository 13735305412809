import React from "react";
import { toast } from "react-toastify";

function UnsubscribeConfirm({setUnsubscribed}) {

  function handleUnsubscribeConfirm(){
    /* gateway.subscription.cancel("theSubscriptionId", (err, result) => {

    }); */
    setUnsubscribed(true);
  }

  function handleCancel(){
    toast.dismiss('UnsubscribeConfirm');
    setUnsubscribed(false);
  }

  return ( 
    <div>
      Are you sure you want to cancel your subscription plan?<br/>
      <button className="btn btn-primary" onClick={handleUnsubscribeConfirm}>Unsubscribe</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
    </div>
   );
}

export default UnsubscribeConfirm;