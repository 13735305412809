import React from 'react';
import Logout from '../components/auth/logout';
import {Link} from 'react-router-dom';
import appLogo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUser, faMessage, faUsers, faList, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useEffect } from "react";
import $ from "jquery";

const Menu = ({user, notificationCount}) => {
    function handleLogout(){
        toast(<Logout />,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'logout'});
    }

    useEffect(() => { // auto hide expanded menu after clicking on a nav link
        $('.dropdown-item ,.nav-item:first, .navbar-login-signup .nav-item').on('click',function(){
            $('.navbar-collapse')[0].classList.remove('show');
        });
    });

    return (
        <>
        <header className="bd-header bg-dark py-3 d-flex align-items-stretch border-bottom border-dark">
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark shadow">
                <div className="container-fluid">
                    <Link className="nav-link" aria-current="page" to="/">
                        <img className="navbar-app-logo" src={appLogo} alt="ClientPro" />&nbsp;
                        <span className='navbar-app-name'>ClientPro</span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse" style={{marginLeft:"15px"}}>
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item home">
                            <Link className="nav-link" aria-current="page" to="/">
                                <FontAwesomeIcon icon={faHouse} size="sm" /> Home
                            </Link>
                        </li>
                        {user != null &&
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faUsers} size="sm" /> Clients
                            </Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/clients">My Clients</Link></li>
                                    <li><Link className="dropdown-item" to="/addclient">Create Client</Link></li>
                                    <li><Link className="dropdown-item" to="/uploadclients">Import Clients</Link></li>
                                </ul>
                        </li>
                        }
                        {user != null &&
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faList}  size="sm" /> Client Lists
                            </Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/lists">My Client Lists</Link></li>
                                    <li><Link className="dropdown-item" to="/addlist">Create Client List</Link></li>
                                </ul>
                        </li>
                        }
                        {user != null &&
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faMessage}  size="sm" /> Messages
                            </Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/messages">My Messages</Link></li>
                                    <li><Link className="dropdown-item" to="/addmessage">Create Message</Link></li>
                                    <li><Link className="dropdown-item" to="/">Send Message</Link></li>
                                </ul>
                        </li>
                        }
                        {user != null &&
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faCalendar}  size="sm" /> Scheduled SMS
                            </Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/tasks">My Scheduled SMS</Link></li>
                                    <li><Link className="dropdown-item" to="/addtask">Create Repeating SMS</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/tasksets">My Scheduled SMS</Link></li> */}
                                    <li><Link className="dropdown-item" to="/calendar">Scheduled SMS Calendar</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/weeklytasks">My 1st Week Scheduled SMS</Link></li>
                                    <li><Link className="dropdown-item" to="/addweeklytask">Create 1st Week Scheduled SMS</Link></li> */}
                                </ul>
                        </li>
                        }
                        
                        
                    </ul>
                    {user != null &&
                        <div className="navbar-current-user">
                            <li className="nav-item dropdown" style={{listStyle:'none'}}>
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            { notificationCount > 0 &&
                            <span id="notificationCount" className="notifications translate-middle badge rounded-pill bg-danger">
                                {notificationCount}
                            </span>
                            }
                            <FontAwesomeIcon icon={faUser} /> <span id="menuUserName">{user.firstName}</span>
                            </Link>
                                <ul className="dropdown-menu shadow">
                                    <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                    {user.admin === true &&
                                        <li><Link className="dropdown-item" to="/bullboard" target="_blank" >Bull Board</Link></li>
                                    }
                                    <li><Link className="dropdown-item" to="/notifications">Notifications</Link></li>
                                    <li><Link className="dropdown-item" to="/stats">User Stats</Link></li>
                                    <li><Link className="dropdown-item" to="#" onClick={handleLogout} >Logout</Link></li>
                                </ul>
                            </li>
                        </div>
                    }
                    {user === null && 
                        <div className="navbar-login-signup">                            
                            <li className="nav-item">
                                <Link className="nav-link" to="/signup">Sign Up</Link>
                            </li>
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/login">Login</Link>
                            </li>
                        </div>
                    }
                </div>
            </div>
            </nav>
        </header>
        {/* <div style={{marginTop:"30px"}}>
            msg: {serverMessage}
        </div> */} 
        </>       
    );
}
 
export default Menu;