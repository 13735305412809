import React, { useState } from 'react';
import PayPalButton from "./payPalButton";

function Subscribe({user}) {
    const [subscriptionPlans] = useState([
        {
            subscriptionPaymentPlan: "Bronze Plan",
            subscriptionDescription: window.env.BRONZE_PLAN_DESCRIPTION,
            subscriptionPlanID: window.env.BRONZE_PLAN_ID,
            frequency: "month",
            currency: "USD",
            currencySign: "$",
            paymentAmount: window.env.BRONZE_PLAN_AMOUNT,
            paymentFrequency: "Monthly Plan"
        },
        {
            subscriptionPaymentPlan: "Bronze Plus Plan",
            subscriptionDescription: window.env.BRONZE_PLUS_PLAN_DESCRIPTION,
            subscriptionPlanID: window.env.BRONZE_PLUS_PLAN_ID,
            frequency: "month",
            currency: "USD",
            currencySign: "$",
            paymentAmount: window.env.BRONZE_PLUS_PLAN_AMOUNT,
            paymentFrequency: "Monthly Plan"
        },
        {
            subscriptionPaymentPlan: "Silver Plan",
            subscriptionDescription: window.env.SILVER_PLAN_DESCRIPTION,
            subscriptionPlanID: window.env.SILVER_PLAN_ID,
            frequency: "month",
            currency: "USD",
            currencySign: "$",
            paymentAmount: window.env.SILVER_PLAN_AMOUNT,
            paymentFrequency: "Monthly Plan"
        },
        {
            subscriptionPaymentPlan: "Silver Plus Plan",
            subscriptionDescription: window.env.SILVER_PLUS_PLAN_DESCRIPTION,
            subscriptionPlanID: window.env.SILVER_PLUS_PLAN_ID,
            frequency: "month",
            currency: "USD",
            currencySign: "$",
            paymentAmount: window.env.SILVER_PLUS_PLAN_AMOUNT,
            paymentFrequency: "Monthly Plan"
        }
    ]);

    const supportEmail = `emailto:${window.env.SUPPORT_EMAIL}?subject=Upgrade Plan`;

    return ( 
        <React.Fragment>
            <h3 className="h3-centered">Subscribe Now!</h3>
            <div className="container-width-6" style={{fontWeight:'500'}}>
                <p>Basic application features consists of importing clients, manage clients, creating custom messages and custom client lists. With an affordable 
                monthly subscription, you can save yourself valuable time with the ability to schedule your text messages to be sent when you want them or 
                have messages to repeat automatically with just a few clicks.</p>
                <p>Each plan includes basic features with limits a total clients and monthly total text messages. Choose the plan that closely fits your needs.</p>
            </div>
            <div className="container-full-width" style={{textAlign:'center'}}>
                 {subscriptionPlans.map((item, index) => (
                    <div key={index} className="container-width-4 subscription-plans">
                        <PayPalButton user={user} plan={item} />
                    </div>
                ))}
            </div>
            <div className="container-width-6">
                <h3 className="h3-centered">
                    Other monthly plans may be available in the future. Let us know your needs and we can help advise you with a plan that will work for you.
                    <br/>
                    Contact support with any questions. <a href={supportEmail}>{window.env.SUPPORT_EMAIL}</a>

                </h3>
            </div>
        </React.Fragment>
    );
}

export default Subscribe;