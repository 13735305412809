import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {getLists, deleteList} from '../../services/listService';
import PopDelete from "../common/popDelete";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faTrash } from '@fortawesome/free-solid-svg-icons';

function ListList({user}) {
    const [lists, setLists] = useState({});
    const dataFetchedRef = useRef(false);

    function popDelete(item){
        toast(
            <PopDelete 
                handleDelete={handleDelete} 
                itemId={item._id} 
                itemName={item.name}
                itemType='list'
                toastId='delete'
        />
        ,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'delete'});
    }

    async function handleDelete(_id){
        const res = await deleteList(_id);
        if(res){
            toast(res.list);
            setTimeout(function(){
                window.location = '/lists';
            },2000);
        }
    }

    useEffect(() => {
        async function fetchLists(){
            try{
                setLists(await getLists(user._id));
            }catch(ex){
                toast('No lists found.');
            }                           
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchLists();
        window.scrollTo(0, 0);
    },[user._id]);

    return ( 
        <React.Fragment>
            <div className="container-width-9">
            <h3 className="h3-centered">My Lists</h3>
            
            <Link to="/addlist" className="btn btn-primary" style={{ marginBottom: 20 }}>Add List</Link>            
            
            <p>Showing {Object.keys(lists).length} lists in the database.</p>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" width="80%">Name</th>
                        <th scope="col" width="20%"></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(lists).map((key) => 
                    <tr key={key}>                        
                        <td>{lists[key].name}</td>
                        <td align="right">
                            {/* <Link to={`/list`} state={{list: lists[key]}}><FontAwesomeIcon icon={faFilePen} size="sm" /></Link> */}
                            <Link to={`/list/${lists[key]._id}`} state={{list: lists[key]}}><FontAwesomeIcon icon={faFilePen} size="sm" /></Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link onClick={() => popDelete(lists[key])}><FontAwesomeIcon icon={faTrash} size="sm" /></Link>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
        </React.Fragment>
    );
}

export default ListList;