import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/sms";
http.setJwt(auth.getJwt());
 
export async function sendMessage(data) {
    try {
        let res = await http.post(apiEndpoint, data);
        return res;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

const exports = {
    sendMessage
}
  
export default exports;