import React, {useState, useEffect, useRef, useReducer} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {getClientList, createClients, deleteClient} from '../../services/clientService';
import { toast } from "react-toastify";
import {formatDate} from '../../utils/formatDate';
import csv from "jquery-csv";

function Optavia({user}) {
    const location = useLocation();
    const {fileContents} = location.state;
    //const [existingClients, setExistingClients] = useState(); 
    const [selectAll, setSelectAll] = useState(false); 
    const [existingCount, setExistingCount] = useState(0);
    const [disabledCount, setDisabledCount] = useState(0);  
    const [contents, setContents] = useState({});
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const dataFetchedRef = useRef(false);
    
    function analyzeImport(ec){
        let content = csv.toObjects(fileContents);
        let ecount = 0;
        let dcount = 0;
        const mapFields = [
            {from:'AccountStatus',to:'accountStatus'},
            {from:'CountryCode',to:'countryCode'},
            {from:'CurrentCoachID',to:'currentCoachID'},
            {from:'CurrentCoachName',to:'currentCoachName'},
            {from:'Email',to:'email'},
            {from:'EntryDate',to:'entryDate'},
            {from:'FirstName',to:'firstName'},
            {from:'GlobalDirector',to:'globalDirector'},
            {from:'LastName',to:'lastName'},
            {from:'LastOrderDate',to:'lastOrderDate'},
            {from:'Level',to:'level'},
            {from:'OPTAVIAID',to:'accountID'},
            {from:'PQV',to:'pqv'},
            {from:'Phone',to:'phone'},
            {from:'PremierMember',to:'premierMember'},
            {from:'PresidentialDirector',to:'presidentialDirector'}            
        ];
        Object.keys(content).forEach(c => {
            // rename keys to match mongoDB
            Object.keys(mapFields).forEach((x) =>{
                content[c][mapFields[x].to] = content[c][mapFields[x].from];
                delete content[c][mapFields[x].from];
            });
            // add new keys
            content[c] = {...content[c], _id: ''};
            content[c] = {...content[c], userID: user._id};
            content[c] = {...content[c], existing: false};
            content[c] = {...content[c], import: true};
            content[c] = {...content[c], ref: `client${c}`};
            content[c] = {...content[c], disabled: false};
            content[c] = {...content[c], street1: ''};
            content[c] = {...content[c], street2: ''};
            content[c] = {...content[c], city: ''};
            content[c] = {...content[c], state: ''};
            content[c] = {...content[c], zip: ''};
            if(ec){ // if there are Existing clients for this user
                Object.keys(ec).forEach(x =>{
                    if(ec[x].accountID === Number(content[c].accountID) || content[c].phone === '' || content[c].email === ''){
                        content[c] = {...content[c], _id: ec[x]._id};
                        content[c] = {...content[c], existing: true};
                        content[c] = {...content[c], import: false};
                        ecount++;
                    }
                });
            }
            if(content[c].phone === '' || content[c].email === ''){
                content[c] = {...content[c], disabled: true};
                content[c] = {...content[c], import: false};
                dcount++;
            }
        });
        // format / clean phone numbers
        Object.keys(content).forEach(c => {
            if(content[c].phone !== ''){
                content[c].phone = content[c].phone.replace(/[\(\)\-\s]+/g, '');
            }
        });
        setExistingCount(ecount);
        setDisabledCount(dcount);
        setContents(content);
    }

    function handleSelectAll(e){
        const { name } = e.target;
        let checkStatus = document.getElementById(name).checked;
        let content = contents;
        
        Object.keys(content).forEach(c => {
            if(content[c].disabled === false){
                content[c].import = checkStatus;                
            }
        });
        
        setSelectAll(!selectAll);
        setContents(content);
    }

    function handleChange(e){
        const { name } = e.target;
        let checkStatus = document.getElementById(name).checked;
               
        let content = contents;
        Object.keys(content).forEach(c => {
            if(content[c].ref === name){
                content[c].import = checkStatus;                
            }
        });
        setContents(content);
        forceUpdate();
    }

    async function handleImport(){
        let content = contents;
        let newContent = [];
        let toDelete = 0;

        // get count of how many existing clients to overwrite / delete
        Object.keys(content).forEach(c => {
            if(content[c].import === true && content[c]._id !== '')
                toDelete++;
        });

        Object.keys(content).forEach(async c => {
            if(content[c].import === true){
                newContent.push(content[c]);
            }
        });

        let dcount = 0;
        Object.keys(content).forEach(async c => {
            if(content[c].import === true && content[c]._id !== ''){
                // delete the client before re-creating
                await deleteClients(content[c])
                    .then(async () => {
                        dcount++;
                        if(dcount === toDelete){
                            try{
                                const result = await createClients(newContent);
                                if(result){
                                    toast("Clients added.");
                                    setTimeout(function(){
                                        window.location = '/clients';
                                    },2000);
                                }
                            } catch (ex) {
                                if(ex.response && ex.response.status === 400){
                                    toast(ex.response.message);
                                }
                            }
                        }
                    })                
            }
        });
        
        if(toDelete === 0){
            try{
                const result = await createClients(newContent);
                if(result){
                    toast("Clients added.");
                    setTimeout(function(){
                        window.location = '/clients';
                    },2000);
                }
            } catch (ex) {
                if(ex.response && ex.response.status === 400){
                    toast(ex.response.message);
                }
            }
        }       
    }

    async function deleteClients(data){
        const {_id} = data;        
        await deleteClient(_id);
        return true;
    }

    useEffect(() => {
        async function fetchExistingClients(){
            try {
                const cc = await getClientList(user._id);
                if(cc){
                    //setExistingClients(cc);
                    analyzeImport(cc);
                }
            } catch (error) {
               //toast(error);
            }                            
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchExistingClients(); 
        window.scrollTo(0, 0);       
    },[user._id]);

    function handleCustomStyle(existing, email, phone){        
        if(existing === true && email !== '' && phone !== '')
            return {backgroundColor:"lemonchiffon"};
        if(email === '' || phone === '')
            return {color:"red"};        
    }

    return (  
        <React.Fragment>
            <div>
                <h3>Optavia&reg; Import</h3>
            </div>
            <div>
                {existingCount > 0 && 
                    <div style={{color:"red",fontWeight:"bold"}}>Existing clients found! If you check the box to import them, they will be overwritten!</div>
                }
                {disabledCount > 0 && 
                    <div style={{color:"red",fontWeight:"bold"}}>Any clients marked in red are disabled for import due to missing information. Either fix them and re-import or add them manually.</div>
                }
                <table className="client-list table table-striped shadow">
                <thead>
                    <tr style={{backgroundColor:"inherit"}}>
                        <th scope="col" nowrap="nowrap" align="center">                            
                            Import?<br/>
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name="selectAll" 
                                id="selectAll" 
                                value={selectAll} 
                                checked={selectAll} 
                                onChange={(e) => handleSelectAll(e)} 
                            /> All
                        </th>
                        <th scope="col" nowrap="nowrap">First Name</th>
                        <th scope="col" nowrap="nowrap">Last Name</th>
                        <th scope="col" nowrap="nowrap">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Entry Date</th>
                        <th scope="col">Last Order</th>
                        <th scope="col">Account Status</th>
                        <th scope="col">Premier</th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(contents).map((key) => 
                    <tr key={key} style={handleCustomStyle(contents[key].existing, contents[key].email, contents[key].phone)}>
                        <td align="center">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name={contents[key].ref} 
                                id={contents[key].ref} 
                                value={contents[key].import}
                                checked={contents[key].import} 
                                onChange={(e) => handleChange(e)}
                                disabled={contents[key].disabled}
                            />
                        </td>
                        <td>{contents[key].firstName}</td> 
                        <td>{contents[key].lastName}</td>
                        <td>{contents[key].email}</td>
                        <td>{contents[key].phone}</td>
                        <td>{formatDate(contents[key].entryDate)}</td>
                        <td>{(contents[key].lastOrderDate !== '' ? formatDate(contents[key].lastOrderDate) : '')}</td>
                        <td>{contents[key].accountStatus}</td>
                        <td>{contents[key].premierMember}</td>
                    </tr>
                )}                    
                </tbody>
                </table>
                <div className="form-group mb-2" style={{textAlign:"center"}}>
                    <button className="btn btn-primary shadow" onClick={handleImport}>Import</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to={`/`} className="btn btn-secondary shadow">Cancel</Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Optavia;