import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/messages";
http.setJwt(auth.getJwt());
 
export async function getMessageList(userid) {
    try{
        let { data: message } = await http.get(apiEndpoint+'/all/'+userid, {});
        return message.sort(m => m.name);
    }catch(ex){
        //toast.error(ex.response.data);
        return {};
    }
}

export async function getMessage(messageid) {
    const { data: message } = await http.get(apiEndpoint+'/'+messageid, {});
    return message;
}

export async function createMessage(data) {
    try {
        let { data: message } = await http.post(apiEndpoint, data);
        return message;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function updateMessage(data) {
    const {_id} = data;
    try {
        delete data._id;
        let { data: message } = await http.put(apiEndpoint+'/'+_id, data);
        return message;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function deleteMessage(_id) {
    try {
        let { data: message } = await http.delete(apiEndpoint+'/'+_id, {});
        return message;
    } catch (ex) {
        toast.error(ex.response.data);
    } 
}

export async function mapMessageVariables(message, user, client){
    let msg = message.replaceAll('[[first]]', client.firstName)
        .replaceAll('[[last]]', client.lastName)
        .replaceAll('[[myfirst]]', user.firstName)
        .replaceAll('[[mylast]]', user.lastName)
        .replaceAll('[[myphone]]', user.phone)
        .replaceAll('[[myemail]]', user.email);
    return msg;
}

/* export const messageTypeOptions = [
    {
        text: "Day 1",
        value: "day-1"
    },
    {
        text: "Day 2",
        value: "day-2"
    },
    {
        text: "Day 3",
        value: "day-3"
    },
    {
        text: "Day 4",
        value: "day-4"
    },
    {
        text: "Day 5",
        value: "day-5"
    },
    {
        text: "Day 6",
        value: "day-6"
    },
    {
        text: "Day 7",
        value: "day-7"
    },
    {
        text: "Day 8",
        value: "day-8"
    },
    {
        text: "Day 10",
        value: "day-10"
    }
]; */

const exports = {
    getMessageList,
    getMessage,
    createMessage,
    updateMessage,
    deleteMessage,
    mapMessageVariables,
    //messageTypeOptions
}
  
export default exports;