import React from 'react';
import { Link } from "react-router-dom";

function Terms() {
    window.scroll(0, 0);
    return ( 
        <>
        <h2 className="h2-centered">ClientPro Website Terms of Use</h2>
        <p>
        These terms govern your access and use of the ClientPro website and any associated sites of ClientPro, which are operated by South Atlantic Software Solutions and/or its subsidiaries and affiliates. By accessing or using the ClientPro website, as well as the content, and services available on the site, you are accepting these terms. If you do not agree with these terms, you do not have the right to access or use the ClientPro website.
        </p>
        <p>
        Certain features, functionality, and content may be subject to additional terms, which will be provided to you at the time those services are offered. We reserve the right (at any time) to modify, suspend, or remove certain features, functionality, or content from the ClientPro website.
        </p>
        <h3>Using the ClientPro Website</h3>
        <p>
        To access certain features and functionality on the ClientPro website, purchase ClientPro services or submit content, you will need to register with us and/or create a ClientPro account. Our Privacy Policy provides information on how we collect, use and disclose information from our users and the use of this website and our services.
        </p>
        <p>
        In accessing or using the ClientPro website, you may not take any action that interferes with the proper working of the ClientPro website, compromises the security of the site, or otherwise damages the site or any materials and information available through the site, such as (1) using automated means to collect information or content from or to access the site (such as using technical tools known as robots, spiders, or scrapers, without our prior permission), (2) attempting to gain unauthorized access to any portion or feature of the site, to other systems or networks connected to our site or servers, or to any of the services offered on or through our site (such as by hacking, password “mining”, or any other unauthorized means), (3) probing, scanning, or testing the vulnerability of the ClientPro website or any network connected to our site or bypassing any authentication measures on our site or any network connected to the site, (4) interfering with or disrupting the operation of the ClientPro website, our servers, or the networks connected to the site, or disobeying any requirements, procedures, policies, or regulations of the networks connected to our site, or (5) forging headers or otherwise manipulating any identifiers (such as to disguise yourself) or any service offered on or through the ClientPro website. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity. You may not harvest or otherwise collect and store information about other users of the ClientPro website. You are responsible for any loss or damage ClientPro suffers because of your breach of any of the above restrictions. We reserve the right to investigate and prosecute violations of any of the above and/or involve and cooperate with law enforcement authorities in their investigation.
        </p>

        <h3>Restrictions</h3>
        <p>
        Copying or redistribution of certain content is strictly prohibited, and digital rights management or other technologies may be used to prevent or restrict any copying or re-distributing of any content, which you agree not to try to circumvent or avoid. Certain content is only available in certain countries. If you attempt to access content outside of these countries, the rights granted under these terms will not apply. You may not link to or frame the ClientPro website on your website or service that you own, operate, or control unless you have our written permission. If you violate any of these terms, we may suspend or disable your access to or use of the ClientPro website.
        </p>
        <p>
        Clients of this application must agree to opt-in to receive SMS messages and it is souly your duty to manage your clients and agree to refrain from sending SMS messages to clients who choose not to receive messages.
        </p>

        <h3>Copyright and Content Takedown</h3>
        <p>
        If you believe in good faith that certain content appearing on our website or through our services infringes upon your copyright, other proprietary rights, or contains prohibited or illegal content, send us a content takedown notice.
        </p>
        <h3>Subscriptions</h3>
        <p>
        To make a purchase on the ClientPro website, you will need to provide us with certain payment information to complete the order. The required payment information and payments methods available vary based on your country. Go to our Privacy Policy for more information on the payment information we collect and how it is used. We will use your payment information to complete the purchase of your order. In some cases, we may use a payment service provider to process those payments for us, and in those cases, we may provide your payment information to them so they can complete the transaction for you.
        </p>
        <p>
        Your purchase may be subject to sales or use tax. The amount and type of tax applicable to your purchase will depend on what was purchased and the applicable tax rules (where the service is sold). You are responsible for paying the taxes that are applicable to your transactions.
        </p>
        <p>
        We reserve the right to (1) limit or discontinue the available of any service for purchase, (2) limit or condition the use of any promotional code or discount offer, or (3) limit or prohibit someone from making or completing a purchase. Your use of a ClientPro service is subject to its terms of use.
        </p>

        <h3>Content on the ClientPro Website</h3>
        <p>
        The ClientPro website may contain information about ClientPro, such as press releases, industry news, or corporate financial information. Some of this information may not be entirely accurate, complete, reliable, or up-to-date, and use of such information is at your own risk. The ClientPro website may also contain offers, promotions, and/or links to services provided by others (who are not affiliated with ClientPro). We do not make (or imply) any endorsement or recommendation of these services or services, which are subject to their own terms and policies (including data privacy and data gathering practices), and any charges or obligations you incur in your dealings with them, which are your responsibility and done at your own risk (ClientPro is not responsible or liable for those interactions or the services or services they provide to you).
        </p>

        <h3>ClientPro’s Proprietary Rights</h3>
        <p>
        All content on the ClientPro website is proprietary to South Atlantic Software Solutions (and/or its licensors) and protected by copyright, trademark, and other laws of the United States and other countries.  All rights are reserved by South Atlantic Software Solutions (or its applicable licensors). You may not remove, alter, or obscure any copyright, trademark, service mark or other proprietary rights notices appearing on or accompanying any content on (or any portion of) the ClientPro website. Any permitted use of ClientPro’s trademarks is subject to the ClientPro Trademark Guidelines.
        </p>

        <h3>Disclaimers</h3>
        <p>
        The ClientPro website is provided “AS IS” and on an “AS AVAILABLE” basis and to the maximum extent permitted by applicable law, WE DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the services provided through the ClientPro website will meet your requirements or will be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality of any information or content provided through the ClientPro website.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
        WHEN PERMITTED BY LAW, WE WILL NOT BE RESPONSIBLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO THE EXTENT PERMITTED BY LAW, OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE PURCHASE PRICE (THAT YOU PAID) FOR ANY ClientPro services PURCHASED VIA THE ClientPro WEBSITE. IN ALL CASES, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
        </p>

        <h3>Exclusions</h3>
        <p>
        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the exclusions and limitations above may not apply to you.
        </p>

        <h3>Arbitration of Disputes</h3>
        <p>
        All claims arising out of or relating to your ClientPro account or any ClientPro product or service will be resolved exclusively through arbitration, according to these Dispute Resolution Terms, unless you opted out or another exception applies. We both agree that all claims may only be conducted on an individual basis (and not in a class, consolidated, or representative action).
        </p>

        <h3>Governing Law, Jurisdiction and Venue</h3>
        <p>
        These terms are governed by the laws of the State of Florida, without regard to any conflict of laws principles that may provide the application of the law of another jurisdiction. If, for any reason, a claim proceeds in court rather than in arbitration, we both agree that the exclusive jurisdiction and venue for any legal proceeding is either federal court of the Middle District of Florida or state court in Volusia County, Florida.
        </p>

        <h3>General</h3>
        <p>
        These terms are between you and ClientPro and no other person has any right to enforce these terms on your behalf. Your rights are not transferable. Each paragraph is intended to operate separately. If any court or relevant authority determines that any terms are invalid or unenforceable, those terms will be enforced to the maximum extent permissible and the remaining terms will remain in full force and effect. If we delay or chose not to enforce these terms, that will not mean that we have waived our rights or that you do not have to comply with these terms. These terms may be amended by us from time to time. If we make material changes to these terms, we will post the revised terms on our website and may also notify you through your ClientPro product or your ClientPro account. Your use after the changes go into effect will constitute your acceptance of those changes. If you do not agree to the new terms, you may not be able to continue using your product with your ClientPro account.
        </p>

        <h3>Contacting Us</h3>
        <p>
        If you have any questions about these terms, you can <Link to="/contact">contact</Link> us.
        </p>

        <p><b>Effective:</b> January 1, 2023</p>
        </>
    );
}

export default Terms;