import Joi from "joi";
import React, {useState, useEffect, useRef, useReducer} from 'react';
import { Link } from "react-router-dom";
import Input from "../common/input";
import Select from '../common/select';
import { getUser, updateUser } from "../../services/userService";
import { toast } from "react-toastify";
import { default as countries } from "../../services/countries.json";
import { default as states } from "../../services/states.json";

function Profile({user}) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const dataFetchedRef = useRef(false);
    const [currentPlan, setCurrentPlan] = useState("");
    const [inputValues, setInputValue] = useState({
        _id: user._id,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        isOptavia: false 
    });

    const [validation, setValidation] = useState({
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        isOptavia: false
    });

    const refValidation = {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        isOptavia: false 
    };

    const schema = Joi.object({
        firstName: Joi.string().min(1).max(50).required().label("First Name"),
        lastName: Joi.string().min(1).max(50).required().label("Last Name"),
        //email: Joi.string().min(3).max(255).email({ tlds: { allow: false }}).label("Email"),
        phone: Joi.string().min(7).max(50).required().label("Phone"),
        street1: Joi.string().max(250).required().label('Street Address 1'),
        street2: Joi.string().max(250).allow('').label('Street Address 2'),
        city: Joi.string().max(250).required().label('City'),
        state: Joi.string().max(100).required().label('State'),
        zip: Joi.string().max(10).required().label('Zip Code'),
        countryCode: Joi.string().max(50).required().label('Country'),
        //password: Joi.string().required().label("Password"),
        //confirmPassword: Joi.ref('password')
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function handleChange(e){
        const { name, value } = e.target;
        switch(name){
            case 'isOptavia':                
                if(e.target.checked === true){
                    setInputValue({...inputValues, [name]: true });
                }else if(e.target.checked === false){
                    setInputValue({...inputValues, [name]: false });
                }
            break;
            default:
                setInputValue({...inputValues, [name]: value });
                setValidation({validation, [name]: ''});
            break;
        }
        forceUpdate();
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;        
        try {
            //console.log('inputValues', inputValues);
            await updateUser(inputValues)
                .then(result => {
                    toast('User updated.');             
                    setTimeout(function(){
                        window.location = '/profile';
                    },2000);
                });
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }        
    };

    useEffect(() => {
        async function fetchUser(){
            await getUser(user._id)
                .then(results => {
                    console.log(results);
                    const data = {
                        _id: results._id,
                        firstName: results.firstName,
                        lastName: results.lastName,
                        email: results.email,
                        phone: results.phone,
                        street1: results.street1,
                        street2: results.street2,
                        city: results.city,
                        state: results.state,
                        zip: results.zip,
                        countryCode: results.countryCode,
                        password: results.password,
                        isOptavia: results.isOptavia
                    };
                    setInputValue(data);
                    setCurrentPlan(results.plan);
                });
        }

        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            fetchUser();
        }
    },[user._id, inputValues]);

    return(
        <React.Fragment>
            <h3 style={{textAlign:'center'}}>My Profile</h3>
            <div className='form-container'>
            <form onSubmit={handleSubmit}>
                <div><span style={{fontWeight:'500'}}>User ID:</span> {inputValues._id}</div>
                <div>&nbsp;</div>
                <div><i>To change your email or name, please send a request to {window.env.SUPPORT_EMAIL}.</i></div>
                <div>&nbsp;</div>
                <div><span style={{fontWeight:'500'}}>Current Plan:</span> <b>{currentPlan}</b><br/>
                    {currentPlan === 'Bronze Plan' ? (
                        <span style={{color:"gray"}}>
                            <i>{window.env.BRONZE_PLAN_DESCRIPTION}</i>
                        </span>
                    ): currentPlan === 'Silver Plan' ? (
                        <span style={{color:"gray"}}>
                            <i>{window.env.SILVER_PLAN_DESCRIPTION}</i>
                        </span>
                    ): currentPlan === 'Bronze Plus Plan' ? (
                        <span style={{color:"gray"}}>
                            <i>{window.env.BRONZE_PLUS_PLAN_DESCRIPTION}</i>
                        </span>
                    ): currentPlan === 'Silver Plus Plan' ? (
                        <span style={{color:"gray"}}>
                            <i>{window.env.SILVER_PLUS_PLAN_DESCRIPTION}</i>
                        </span>
                    ): (
                        <span></span>
                    )}
                </div>
                
                    {currentPlan !== 'none' ? (
                        <div className="row">
                            <div className="col">
                                To change your plan, please unsubsribe to your current plan and then re-subscribe to another.
                            </div>
                            <div className="col">
                                <Link to='/unsubscribe' className="btn btn-danger shadow cn-custom">Unsubscribe</Link>
                            </div> 
                        </div>
                    ):(
                        <div className="row">
                            <div className="col">
                                <Link to='/subscribe' className="btn btn-warning shadow cn-custom">Subscribe</Link>
                            </div>
                            <div className="col">
                                &nbsp;
                            </div> 
                        </div>
                    )}

                                       
                
                <div>&nbsp;</div>
                <Input name="firstName" label="First Name" value={inputValues.firstName} onChange={(e) => handleChange(e)} error={validation.firstName} autoFocus={true} customClass="disabled" />
                <Input name="lastName" label="Last Name" value={inputValues.lastName} onChange={(e) => handleChange(e)} error={validation.lastName} customClass="disabled" />
                <Input name="email" label="Email" value={inputValues.email} onChange={(e) => handleChange(e)} error={validation.email} customClass="disabled" />
                <Input name="phone" label="Phone" value={inputValues.phone} onChange={(e) => handleChange(e)} error={validation.phone} />
                <Input name="street1" label="Street Address 1" value={inputValues.street1} onChange={(e) => handleChange(e)} error={validation.street1} />
                <Input name="street2" label="Street Address 2" value={inputValues.street2} onChange={(e) => handleChange(e)} error={validation.street2} />
                <Input name="city" label="City" value={inputValues.city} onChange={(e) => handleChange(e)} error={validation.city} />
                <Select name="state" label="State" items={states} keys={['name','abbreviation']} onChange={(e) => handleChange(e)} error={validation.state} selectedOption={inputValues.state} />
                <Input name="zip" label="Zip Code" value={inputValues.zip} onChange={(e) => handleChange(e)} error={validation.zip} />
                <Select name="countryCode" label="Country" items={countries} keys={['name','code']} error={validation.countryCode} onChange={(e) => handleChange(e)} selectedOption={inputValues.countryCode}/>
                <div>I'm an Optavia<span style={{fontSize:'7pt',position:'relative',top:'-3px'}}>&reg;</span> Health Coach&nbsp;&nbsp;
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        name="isOptavia" 
                        id="isOptavia" 
                        value={inputValues.isOptavia}
                        checked={inputValues.isOptavia} 
                        onChange={(e) => handleChange(e)}
                        disabled=""
                    />
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign:'center'}}>
                    <button className="btn btn-primary">Update Profile</button>
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign:'center'}}>
                    <Link to="/changepassword" className="btn btn-secondary shadow" >Change Password</Link>
                </div>
            </form>
            </div>
        </React.Fragment>
    );
}

export default Profile;