import React from 'react';
import { getTaskStatus } from '../../services/taskService';
import { getMessage } from "../../services/messageService";
import { toast } from 'react-toastify';
import { getDurationWithMS, convertEpochTime } from '../../utils/formatDate';

async function popStatus(task, type) {
    const status = await getTaskStatus(task._id);

    if(status){
        const toastProps = {
            position: "top-center",
            autoClose: 7000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        }            
    
        let theMsg = '';
        if(typeof status === 'object'){
            // for repeating messages
            theMsg = ({closeToast, toastProps}) => (
                <div style={{width:"100%"}}>
                    <table style={{width:"100%",fontSize:"8px"}}>
                        <thead>
                            <tr><th style={{textAlign:"center"}} colSpan="2">Status of: {task.name}</th></tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="30%">Last Run:</td>
                            { type === 'repeating' ? (
                                <td width="70%">{status.opts.prevMillis < status.timestamp ? 'Never' : convertEpochTime(status.opts.timestamp) + ' EST'}</td>
                            ):(
                                <td width="70%">Scheduled to run.</td>
                            )}
                        </tr>
                        <tr>
                            <td width="30%">Next Run:</td>
                            { type === 'repeating' ? (
                                <td width="70%">{convertEpochTime(status.opts.prevMillis)} EST</td>
                            ):(
                                <td width="70%">{getDurationWithMS(status.timestamp, status.opts.delay)}</td>
                            )}
                        </tr>                                   
                        </tbody>
                    </table>
                </div>
            );
        }else{
            // for non-repeating messages
            await getMessage(task.messageID)
                .then(message => {
                    theMsg = ({closeToast, toastProps}) => (
                        <div style={{width:"100%"}}>
                            <table style={{width:"100%",fontSize:"8px"}}>
                                <thead>
                                    <tr><th colSpan="2" style={{textAlign:"center"}}>Status of: {task.name}</th></tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td width="30%">Last ran on:</td>
                                    <td width="70%">{new Date(message.stats.lastSentDate).toLocaleString() + ' EST'}</td>
                                </tr>                          
                                </tbody>
                            </table>
                        </div>
                    );
                });
        }
        
        toast(theMsg, toastProps);
    }
};

export default popStatus;