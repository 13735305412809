import React from "react";
import { toast } from "react-toastify";
import auth from "../../services/authService";

function Logout() {

  function handleLogout(){
    auth.logout();
    window.location = '/';
  }

  function handleCancel(){
    toast.dismiss('logout');
  }

  return ( 
    <div>
      Are you sure you want to log out?<br/>
      <button className="btn btn-primary" onClick={handleLogout}>Logout</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
    </div>
   );
}

export default Logout;
