import {default as cronData} from '../services/cronService.json';

export function convertCron(cronExpression) {
    //console.log(' ');
    //console.log('cron expression: '+cronExpression);
    /*************************************
    * init variables
    *************************************/
    var cron = cronExpression.split(" ");
    var minutes = cron[0];
    var hours = cron[1];
    var dayOfMonth = cron[2];
    var month = cron[3];
    var dayOfWeek = cron[4];
  
    var cronToString = "Runs ";
    var minutesString = "", hoursString = "", dayOfMonthString = "", monthString = "", dayOfWeekString = "";
  
    /*************************************
    * controllers
    *************************************/
    // minutes
    if(minutes === 0){
        minutesString = '';
    }else if(minutes.indexOf(',') > -1){
        // we have multiple minutes selected
        let minutesArray = minutes.split(',');
        minutesArray.forEach((item, idx) => {            
            minutesString += handleMinutes(item);
            if(idx < minutesArray.length-2){
                minutesString += ', ';
            }else if(idx === minutesArray.length-2){
                minutesString += ' and ';
            }
        });
        minutesString += ' minutes past the hour'
    }else{
        minutesString = handleMinutes(minutes);
    }

    // hours
    if(minutes === 0){
        hoursString = 'every hour';
    }else if(hours.indexOf(',') > -1){
        // we have multiple hours selected
        let hourArray = hours.split(',');
        hourArray.forEach((item, idx) => {            
            hoursString += handleHours(item);
            if(idx < hourArray.length-2){
                hoursString += ', ';
            }else if(idx === hourArray.length-2){
                hoursString += ' and ';
            }
        });
    }else if(minutes === '*' && hours !== '*'){
        hoursString = ' between' + handleHours(hours) + ' and ' + handleHours(Number(hours)+1);
    }else{
        hoursString = handleHours(hours);
    }

    // dayOfMonth
    if(dayOfMonth.indexOf(',') > -1){
        // we have multiple dayOfMonth selected
        let dayOfMonthArray = dayOfMonth.split(',');
        dayOfMonthString += " on the ";
        dayOfMonthArray.forEach((item, idx) => {            
            dayOfMonthString += handleDayOfMonth(item);
            if(idx < dayOfMonthArray.length-2){
                dayOfMonthString += ', ';
            }else if(idx === dayOfMonthArray.length-2){
                dayOfMonthString += ' and ';
            }
        });
        if(month !== '*'){
            dayOfMonthString += " days of the month";
        }
    }else{
        if(dayOfWeek !== '*' && dayOfMonth === '*'){
            dayOfMonthString = '';
        }else if(dayOfMonth === '*'){
            dayOfMonthString = ' on ' + handleDayOfMonth(dayOfMonth);
            dayOfMonthString += ' of the month';
        }else{
            dayOfMonthString = handleDayOfMonth(dayOfMonth) + ' day of the month';
        }
    }

    // month
    if(month.indexOf(',') > -1){
        // we have multiple months selected
        let monthArray = month.split(',');
        monthString += ', only in ';
        monthArray.forEach((item, idx) => {            
            monthString += handleMonths(item);
            if(idx < monthArray.length-2){
                monthString += ', ';
            }else if(idx === monthArray.length-2){
                monthString += ' and ';
            }
        });
    }else{
        if(month !== '*' && dayOfMonth === '*'){
            monthString = ', only in ' + handleMonths(month);
        }else{
            monthString = ' of ' + handleMonths(month);
        }
    }

    // dayOfWeek
    if(dayOfWeek.indexOf(',') > -1){
        // we have multiple dayOfWeek selected
        let dayOfWeekArray = dayOfWeek.split(',');
        if(dayOfMonth !== '*'){
            dayOfWeekString += ' and on ';
        }else{
            dayOfWeekString += ' on ';
        }
        dayOfWeekArray.forEach((item, idx) => {            
            dayOfWeekString += handleDayOfWeek(item);
            if(idx < dayOfWeekArray.length-2){
                dayOfWeekString += ', ';
            }else if(idx === dayOfWeekArray.length-2){
                dayOfWeekString += ' and ';
            }
        });
        if(dayOfWeekArray.length === 7){
            dayOfWeekString = 'every day';
        }
    }else{
        if(dayOfWeek === '*' && dayOfMonth === '*'){
            dayOfWeekString = '';
        }else if(dayOfWeek !== '*' && dayOfMonth === '*'){
            dayOfWeekString = ' on ' + handleDayOfWeek(dayOfWeek);
        }else{
            dayOfWeekString = handleDayOfWeek(dayOfWeek);
        }

    }     


    /*************************************
    * handlers
    *************************************/
    function handleMinutes(minutes){
        let minutesStr = '';
        if(minutes === '*'){
            minutesStr = 'every minute';
        }else{
            cronData.minute.forEach(m => {
                if(minutes === m.value){
                    minutesStr += m.name;
                }
            });
        }

        return minutesStr.toLowerCase();
    }

    function handleHours(hours){
        let hourStr = '';
        if(hours === "*"){
            hourStr = "every hour";    
        }else if(Number(hours) === 0){
            hourStr = " 12:00 AM";
        }else if(Number(hours) < 12){
            hourStr = hours.toString() + ":00 AM";
        }else if(Number(hours) === 12){
            hourStr = " 12:00 PM";
        }else if(Number(hours) > 21){
            hourStr = (" 1"+(Number(hours)-2).toString().substring(1)) + ":00 PM";
        }else{
            hourStr = (Number(hours)-2).toString().substring(1) + ":00 PM";
        }

        return hourStr;
    }

    function handleDayOfMonth(dayOfMonth){
        let domStr = '';
        if (dayOfMonth === "*"){
            domStr += "every day";
        }else if(dayOfMonth !== "*"){
            domStr += dayOfMonth;
            if(dayOfMonth === "1" || dayOfMonth === "21" || dayOfMonth === "31"){
                domStr += "st";
            } else if (dayOfMonth === "2" || dayOfMonth === "22"){
                domStr += "nd";
            } else if (dayOfMonth === "3" || dayOfMonth === "23"){
                domStr += "rd";
            } else {
                domStr += "th";
            }            
        }

        return domStr;
    }

    function handleMonths(month){    
        let monthStr = '';
        if(month !== "*"){
            cronData.month.forEach(m => {
                if(Number(month) === m.value){
                    monthStr += m.name;
                }
            });
        }else{
            monthStr += 'every month';
        }

        return monthStr;
    }

    function handleDayOfWeek(dayOfWeek, dayOfMonth){
        let dowStr = '';
        switch (Number(dayOfWeek)) {
            case 0:
                dowStr = "Sundays";
              break;
            case 1:
                dowStr = "Mondays";
              break;
            case 2:
                dowStr = "Tuesdays";
              break;
            case 3:
                dowStr = "Wednesdays";
              break;
            case 4:
                dowStr = "Thursdays";
              break;
            case 5:
                dowStr = "Fridays";
              break;
            case 6:
                dowStr = "Saturdays";
              break;
            default:
        }
    
        return dowStr;
    }

    /*************************************
    * return results
    *************************************/
    /* console.log('minutesString:',minutesString);
    console.log('hoursString:',hoursString);
    console.log('dayOfMonthString:',dayOfMonthString);
    console.log('monthString:',monthString);
    console.log('dayOfWeekString:',dayOfWeekString); */

    if(minutes !== '0' && hours === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*'){
        //console.log('1st cronToString =>');
        return cronToString += minutesString;
    }else if(minutes === '0' && hours === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*'){
        //console.log('2nd cronToString =>');
        return cronToString += hoursString;
    }

    if(dayOfMonth !== '*' && dayOfWeek !== '*'){
        //console.log('3rd cronToString =>');
        cronToString += ' ' + minutesString + ' ' + hoursString + ', ' + dayOfMonthString + ' ' + monthString + ' ' + dayOfWeekString;
    }else{
        //console.log('4th cronToString =>');
        cronToString += ' ' + minutesString + ' ' + hoursString + ', ' + dayOfMonthString + ' ' + dayOfWeekString + monthString;
    }

    return cronToString;
}