import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { getNotifications, updateNotification, deleteNotification } from "../../services/notificationService";
import { toast } from 'react-toastify';
import { formatDateTime } from '../../utils/formatDate';

function NotificationList({user}) {
    const dataFetchedRef = useRef(false);
    const [notifications, setNotifications] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function handleSelectAll(e){
        const { name } = e.target;
        const el = document.getElementById(name);
        let checkStatus = el.checked;
        let notification = notifications;
        Object.keys(notification).forEach((n, i) => {
            notification[i].checked = checkStatus;
        });
        
        setSelectAll(!selectAll);
        setNotifications(notification);
    }

    function handleChange(e){
        const { name } = e.target;
        let checkStatus = document.getElementById(name).checked;
       
        let notification = notifications;
        Object.keys(notification).forEach(c => {
            if(notification[c].ref === name){              
                notification[c].checked = checkStatus;                
            }
        });
        setNotifications(notification);
        forceUpdate();
    }

    function updateNotificationCount(){
        const unread = notifications.filter(n => n.status === 'unread');
        let pill = document.getElementById('notificationCount');
        if(unread.length > 0){            
            pill.innerHTML = unread.length;
            pill.classList.remove('d-none');
        }else{
            pill.classList.add('d-none');
        }
    }

    async function handleMark(e){
        let action = e.target.name;
        let text = e.target.text;
        let button = document.getElementById(action);
        let data = [];
        notifications.forEach(n => {
            if(action === 'read' && n.checked === true && n.status === 'unread'){
                data.push(n);
            }else if(action === 'unread' && n.checked === true && n.status === 'read'){
                data.push(n);
            }else{
                return;
            }
        });
        if(data.length){
            e.target.text = 'Please Wait...';
            button.classList.add('disabled-link');
            await updateNotification({notifications: data})
                .then(results => {
                    if(results.indexOf('successful') > -1){
                        let notification = notifications;
                        data.forEach(d => {
                            notification.forEach((n,i) => {
                                if(d._id === notification[i]._id){
                                    notification[i].status = notification[i].status === 'read' ? 'unread' : 'read';
                                    notification[i].checked = false;
                                    let el = document.getElementById(notification[i].ref);
                                    el.checked = false;
                                }
                            });
                        });
                        setNotifications(notification);
                        toast(results);
                        forceUpdate();
                        e.target.text = text;
                        button.classList.remove('disabled-link');
                        updateNotificationCount();
                        setSelectAll(false);
                    }
                });
        }
    }

    async function handleDelete(e){
        let text = e.target.text;
        let button = document.getElementById(e.target.name);
        let data = [];
        notifications.forEach(n => {
            if(n.status === 'read'){
                data.push(n);
            }
        });
        if(data.length){
            e.target.text = 'Please Wait...';
            button.classList.add('disabled-link');
            await deleteNotification({notifications: data})
                .then(results => {
                    if(results.indexOf('successful') > -1){
                        let notification = notifications;
                        data.forEach(d => {
                            notification.forEach((n,i) => {
                                if(d._id === notification[i]._id){                                    
                                    let el = document.getElementById(notification[i].ref);
                                    el.checked = false;
                                }
                            });
                        });
                        const filtered = notification.filter(n => n.status === 'unread');
                        setNotifications(filtered);
                        toast(results);
                        forceUpdate();
                        e.target.text = text;
                        button.classList.remove('disabled-link');
                        updateNotificationCount();
                    }
                });
        }
    }

    useEffect(() => {
        async function fetchTasks(){
            try{
                await getNotifications(user._id)
                    .then(results => {
                        if(results.length){
                            let notificationArr = [];
                            results.forEach((n,i) => {
                                // add key to array
                                n = {...n, checked: false};
                                n = {...n, ref: `notification${i}`};
                                notificationArr.push(n);
                            });
                            setNotifications(notificationArr);
                        }
                    });
            }catch(ex){
                toast('No scheduled SMS found.');
            }                            
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchTasks();
        window.scrollTo(0, 0);
    },[user._id]);

    function handleCustomStyle(status){        
        if(status === 'unread'){
            return {fontWeight:'bold'};
        }else{
            return {fontWeight:'normal'};
        }       
    }

    return ( 
        <React.Fragment>
            <h3 className="h3-centered">My Notifications</h3>
            <div className="container-width-9">
                <Link id="read" name="read" className="btn btn-secondary shadow cn-custom" onClick={(e) => handleMark(e)}>Mark Read</Link>
                &nbsp;&nbsp;&nbsp;
                <Link id="unread" name="unread" className="btn btn-secondary shadow cn-custom" onClick={(e) => handleMark(e)}>Mark Unread</Link>
                &nbsp;&nbsp;&nbsp;
                <Link id="delete" name="delete" className="btn btn-secondary shadow cn-custom" onClick={(e) => handleDelete(e)}>Trash Read Messages</Link>
            </div><br/>
            <table className="client-list table table-striped shadow">
                <thead>
                    <tr style={{backgroundColor:"lightgray",borderBottom:"solid 1px #696969"}}>
                        <th scope="col" nowrap="nowrap" align="center">                            
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name="selectAll" 
                                id="selectAll" 
                                value={selectAll} 
                                checked={selectAll} 
                                onChange={(e) => handleSelectAll(e)} 
                            />
                        </th>
                        <th scope="col" nowrap="nowrap">Message</th>
                        <th scope="col" nowrap="nowrap">Name</th>
                        <th scope="col" nowrap="nowrap">Job Name</th>
                        <th scope="col">List</th>
                        <th scope="col">Notification Date</th>
                    </tr>
                </thead>
                <tbody style={{fontSize:'8pt'}}>
                {Object.keys(notifications).map((key) => 
                    <tr key={key} style={handleCustomStyle(notifications[key].status)}>
                        <td align="center">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name={notifications[key].ref} 
                                id={notifications[key].ref} 
                                value={notifications[key].status}
                                checked={notifications[key].checked} 
                                onChange={(e) => handleChange(e)}
                            />
                        </td>
                        <td>{notifications[key].message}</td> 
                        <td>{notifications[key].name}</td>
                        <td>{notifications[key].jobData.jobName}</td>
                        <td>{notifications[key].jobData.listName}</td>
                        <td nowrap="nowrap">{formatDateTime(notifications[key].createdDate)}</td>
                    </tr>
                )}                    
                </tbody>
            </table>
        </React.Fragment>
    );
}

export default NotificationList;