import React, { forwardRef } from 'react';
import $ from 'jquery';

const Input = forwardRef((props, ref) => {
    const {name, label, value, onChange, error, autoFocus, customClass, checked} = props;
    let { type } = props;
    let inputClass = "";
    switch(type){
        case 'hidden':
            inputClass = "form-control visually-hidden";
            break;
        case 'checkbox':
        case 'radio':
            inputClass = "form-check-input shadow";
            break;
        case 'text':
        default:
            inputClass = "form-control shadow";
            break;
    }

    // using jquery to help hide bootstrap form controls when using hidden input types
    setTimeout(()=>{
        $('input[type=hidden]').each(function(){
            $(this).parent('.form-group').css('display','none');
        });
    },500);

    return (
        (type !== 'checkbox') ? (
            <div className="form-group mb-2">
                <label htmlFor={name}>{label}</label>
                <input ref={ref} id={name} name={name} type={type} className={inputClass +` `+ customClass} value={value} onChange={onChange} autoFocus={autoFocus} checked={checked} />
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        ):(
            <div className="form-group mb-2">
                <label htmlFor={name}>{label}</label>&nbsp;
                <input ref={ref} id={name} name={name} type={type} className={inputClass +` `+ customClass} value={value} onChange={onChange} autoFocus={autoFocus} />
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        )
    );
});

export default Input;