import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {getMessageList} from '../../services/messageService';
import popMessage from "../common/popMessage";
import Select from '../common/select';
import MessageAddNew from "../messages/messageAddNew";

function Step3({onStep3NextClick, onStep3BackClick, user, selectedMessage, setSelectedMessage}) {
    const [messages, setMessages] = useState({});
    const [nextDisabled, setNextDisabled] = useState("disabled");
    const [displayOr, setDisplayOr] = useState("block");

    function handleChange(e){
        const { value } = e.target;
        handleSelectedMessage(value);
        if(value !== ''){
            setNextDisabled("");
            setDisplayOr("none");
        }else{
            setNextDisabled("disabled");
            setDisplayOr("block");
        }
    }

    function handleSelectedMessage(_id){
        if(_id !== ''){
            setSelectedMessage(_id);
            setNextDisabled("");
            setDisplayOr("none");
        }
    }

    useEffect(() => {
        async function fetchMessages(){
            //let filtered = [];
            await getMessageList(user._id)
                .then(messages => {
                    /* messages.forEach(m => {
                        if(m.type === undefined || m.type === '')
                            filtered.push(m);
                    });
                    setMessages(filtered); */
                    setMessages(messages);
                });                                      
        }
        
        if(selectedMessage !== ''){
            setNextDisabled("");
            setDisplayOr("none");
        }
        
        fetchMessages();
        window.scrollTo(0, 0);
    },[user._id,selectedMessage]);

    return (  
        <React.Fragment>
        {Object.keys(messages).length > 0 &&  
            <div style={{width:"60%",margin:"auto"}}>      
            <Select 
                name="messageID" 
                label="Select Message" 
                value={selectedMessage} 
                selectedOption={selectedMessage} 
                items={messages} 
                keys={['name','_id']} 
                onChange={(e) => handleChange(e)} 
            />
            
            <div style={{display:displayOr}}>Or</div>
            </div>
        }
        {!selectedMessage &&
        <div style={{width:"100%"}}>           
            <MessageAddNew 
                user={user} 
                customStyle={{width:"70%",margin:"auto"}}
                source="home"
                handleSelectedMessage={handleSelectedMessage}
            />
        </div>
        }
        <div style={{textAlign:"center"}}>
            <Link className="btn btn-secondary shadow" onClick={onStep3BackClick}>Back</Link>
            {selectedMessage &&
                <span>&nbsp;&nbsp;&nbsp;
                <Link className="btn btn-light shadow" onClick={() => {popMessage(selectedMessage, user)}}>Preview Message</Link>
                </span>
            }             
            &nbsp;&nbsp;&nbsp;<Link className={`btn btn-primary shadow ` + nextDisabled} onClick={onStep3NextClick}>Next</Link>
        </div>
        </React.Fragment>
    );
}

export default Step3;