import Joi from 'joi';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { updateTask } from '../../services/taskService';
import { getMessageList } from "../../services/messageService";
import { getLists } from "../../services/listService";
import TaskDisclaimer from "./taskDisclaimer";
import TaskForm from "./taskForm";
import {convertCron} from '../../utils/convertCron';
import { toast } from "react-toastify";
import auth from "../../services/authService";

function Task() {
    const {_id} = useParams();
    const location = useLocation();
    const {task} = location.state;
    const dataFetchedRef = useRef(false);
    const user = auth.authCurrentUser();
    const formClass = user.admin === true ? 'container-full-width' : 'container-width-8'; 
    const [messages, setMessages] = useState({});
    const [lists, setLists] = useState({});
    const [inputValues, setInputValue] = useState({
        _id: _id,
        name: task.name,
        type: task.type,
        userID: task.userID,
        userEmail: task.userEmail,
        userName: task.userName,
        messageID: task.messageID,
        messageName: task.messageName,
        listID: task.listID,
        listName: task.listName,
        cron: task.cron      
    });

    const [cronMinute, setCronMinute] = useState(task.cron.split(' ')[0]);
    const [cronHour, setCronHour] = useState([task.cron.split(' ')[1]]);
    const [cronDayOfMonth, setCronDayOfMonth] = useState([task.cron.split(' ')[2]]);
    const [cronMonth, setCronMonth] = useState([task.cron.split(' ')[3]]);
    const [cronDayOfWeek, setCronDayOfWeek] = useState([task.cron.split(' ')[4]]);

    const [cronAsText, setCronAsText] = useState("");

    const [validation, setValidation] = useState({
        name: '',
        type: '',
        userID: '',
        userEmail: '',
        userName: '',
        messageID: '',
        messageName: '',
        listID: '',
        listName: '',
        cron: ''
    });

    const refValidation = {
        name: '',
        type: '',
        userID: '',
        userEmail: '',
        userName: '',
        messageID: '',
        messageName: '',
        listID: '',
        listName: '',
        cron: ''
    };

    const schema = Joi.object({
        name: Joi.string().min(1).max(50).required(),
        type: Joi.string().required(),
        userID: Joi.string().required(),
        userEmail: Joi.string().required(),
        userName: Joi.string().required(),
        messageID: Joi.string().required().label('Message'),
        messageName: Joi.string().required(),
        listID: Joi.string().required().label('Client List'),
        listName: Joi.string().required()
    }).options({abortEarly: false, allowUnknown: true});

    function handleChange(e){
        const { name, value } = e.target;
        switch(name){
            case 'minute':                
                setCronMinute(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronHour} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'hour':                
                setCronHour(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'dayOfMonth':
                setCronDayOfMonth(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'month':
                setCronMonth(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${cronDayOfMonth} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronDayOfWeek}`});
            break;
            case 'dayOfWeek':
                setCronDayOfWeek(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${cronDayOfMonth} ${cronMonth} ${Array.from(e.target.selectedOptions, (item) => item.value)}`});
            break;
            case 'name':
                setInputValue({...inputValues, name: value });
                setValidation({validation, name: ''});
            break;
            case 'message':
                setInputValue({...inputValues, messageID: value });
                setValidation({validation, messageID: ''});
            break;
            case 'list':
                setInputValue({...inputValues, listID: value });
                setValidation({validation, listID: ''});
            break;
            default:
        }      
    }

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function validateCron(){
        if(user.admin === false && inputValues.cron === '0 * * * *')
            return {message: "Please choose at least one scheduling option."}

        return;
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        const cronError = validateCron();
        if(cronError){
            toast.error(cronError.message);
            return;
        }
        if(errors){
            console.log('errors!',errors);
            return;
        }
        
        try {
            const result = await updateTask({"_id": _id, "values": inputValues});
            if(result){
                toast(result);
                setTimeout(function(){
                    window.location = '/tasks';
                },2000);
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast.error(ex.response.message);
            }
        }        
    };

    useEffect(() => {
        async function fetchMessages(){
            let filtered = [];
            await getMessageList(user._id)
                .then(messages => {
                    messages.forEach(m => {
                        if(m.type === undefined || m.type === '')
                            filtered.push(m);
                    });
                    setMessages(filtered);
                });                                      
        }

        async function fetchLists(){
            setLists(await getLists(user._id));                                      
        }               
        
        /*  only needed if minutes is multiple select
            if((cronMinute.length > 1 && cronMinute.find(x => x === '*')) || (cronMinute.length > 1 && cronMinute.find(x => x === '0'))){
            setCronMinute(cronMinute.splice(0,1));
        } */
        if(cronHour.length > 1 && cronHour.find(x => x === '*')){
            setCronHour(cronHour.splice(0,1));
        }
        if(cronDayOfMonth.length > 1 && cronDayOfMonth.find(x => x === '*')){
            setCronDayOfMonth(cronDayOfMonth.splice(0,1));
        }
        if(cronMonth.length > 1 && cronMonth.find(x => x === '*')){
            setCronMonth(cronMonth.splice(0,1));
        }
        if(cronDayOfWeek.length > 1 && cronDayOfWeek.find(x => x === '*')){
            setCronDayOfWeek(cronDayOfWeek.splice(0,1));
        }
        setCronAsText(convertCron(`${cronMinute.toString()} ${cronHour.toString()} ${cronDayOfMonth.toString()} ${cronMonth.toString()} ${cronDayOfWeek.toString()}`));

        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            fetchMessages();
            fetchLists();
        }
    },[cronMinute, cronHour, cronDayOfMonth, cronMonth, cronDayOfWeek, user._id, lists, messages, task]);

    return ( 
        <React.Fragment>
            <h3 className='h3-centered'>Edit Scheduled Text Message</h3>
            <div className="form-container" style={{textAlign:"left",fontSize:"9px"}}>
                <TaskDisclaimer />
            </div>
            <div className="container-full-width">
                <form onSubmit={handleSubmit} style={{textAlign:"left",marginTop:"10px"}}>
                    <TaskForm 
                        inputValues={inputValues}
                        user={user}
                        messages={messages}
                        lists={lists}
                        handleChange={(e) => handleChange(e)}
                        cronMinute={cronMinute}
                        cronHour={cronHour}
                        cronDayOfMonth={cronDayOfMonth}
                        cronMonth={cronMonth}
                        cronDayOfWeek={cronDayOfWeek}
                        cronAsText={cronAsText}
                        validation={validation}
                        formClass={formClass}
                    />
                    <div>&nbsp;</div>
                    <div style={{textAlign:"center"}}>
                        <Link className="btn btn-secondary shadow" to="/tasks">Cancel</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary shadow">Update Task</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default Task;
