import React from 'react';
import { getList } from '../../services/listService';
import { getClient } from '../../services/clientService';
import { toast } from 'react-toastify';

async function popClientList(listID) {
    await getList(listID)
        .then(async results => {
            const clientArray = [];
            const clientListLength = results.clients.length;
            results.clients.forEach(async id => {
                await getClient(id)
                    .then(results => {
                        clientArray.push(results);
                    });
            });
            
            setTimeout(() => {              
                if(clientArray.length === clientListLength){
                    const toastProps = {
                        position: "top-center",
                        autoClose: 7000,
                        closeOnClick: true,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    }
                
                    const theMsg = ({closeToast, toastProps}) => (
                        <div style={{width:"100%"}}>
                            <table style={{width:"100%",fontSize:"8px"}}>
                                <thead>
                                    <tr><th style={{textAlign:"center"}}>{results.name}</th></tr>
                                </thead>
                                <tbody>
                                {Object.keys(clientArray).map((key) =>
                                    <tr key={key}>
                                        <td>{clientArray[key].firstName} {clientArray[key].lastName}</td>
                                    </tr>
                                )}                                    
                                </tbody>
                            </table>
                        </div>
                    );
                    
                    toast(theMsg, toastProps);
                }
            },500);
    }); 
};

export default popClientList;