import Joi from 'joi';
import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import {createTask} from '../../services/taskService';
import { getMessageList } from "../../services/messageService";
import { getLists } from "../../services/listService";
import TaskForm from "./taskForm";
import {convertCron} from '../../utils/convertCron';
import { toast } from "react-toastify";
import auth from "../../services/authService";
import TaskDisclaimer from "./taskDisclaimer";
import { checkPlanLimits } from "../../services/userService";

function TaskAddNew({ selectedMessage, selectedList, userID, setShowTaskCreate, source }) {
    const dataFetchedRef = useRef(false);
    const [limitReached, setLimitReached] = useState({pass: true, reason: ''});
    const user = auth.authCurrentUser();
    const formClass = user.admin === true ? 'cron-form container-full-width' : 'cron-form container-width-8';  
    const [inputValues, setInputValue] = useState({
        name: '',
        type: 'repeating',
        userID: user._id,
        userEmail: user.email,
        userName: user.firstName + ' ' + user.lastName,
        messageID: source === 'steps' ? selectedMessage : '',
        messageName: '',
        listID: source === 'steps' ? selectedList : '',
        listName: '',
        cron: '0 * * * *'       
    });

    const [cronMinute, setCronMinute] = useState(user.admin === true ? '*' : 0);
    const [cronHour, setCronHour] = useState(['*']);
    const [cronDayOfMonth, setCronDayOfMonth] = useState(['*']);
    const [cronMonth, setCronMonth] = useState(['*']);
    const [cronDayOfWeek, setCronDayOfWeek] = useState(['*']);
    const [messages, setMessages] = useState({});
    const [lists, setLists] = useState({});
    const [cronAsText, setCronAsText] = useState("");

    const [validation, setValidation] = useState({
        name: '',
        type: '',
        userID: '',
        userEmail: '',
        userName: '',
        messageID: '',
        messageName: '',
        listID: '',
        listName: '',
        cron: ''
    });

    const refValidation = {
        name: '',
        type: '',
        userID: '',
        userEmail: '',
        userName: '',
        messageID: '',
        messageName: '',
        listID: '',
        listName: '',
        cron: ''
    };

    const schema = Joi.object({
        name: Joi.string().min(1).max(50).required(),
        type: Joi.string().required(),
        userID: Joi.string().required(),
        userEmail: Joi.string().required(),
        userName: Joi.string().required(),
        messageID: Joi.string().required().label('Message'),
        messageName: Joi.string().required(),
        listID: Joi.string().required().label('Client List'),
        listName: Joi.string().required()
    }).options({abortEarly: false, allowUnknown: true});


    function handleChange(e){
        const { name, value } = e.target;
        //console.log(e.target.type);
        //console.log(name, value);
        switch(name){
            case 'minute':                
                setCronMinute(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronHour} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'hour':                
                setCronHour(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'dayOfMonth':
                setCronDayOfMonth(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronMonth} ${cronDayOfWeek}`});
            break;
            case 'month':
                setCronMonth(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${cronDayOfMonth} ${Array.from(e.target.selectedOptions, (item) => item.value)} ${cronDayOfWeek}`});
            break;
            case 'dayOfWeek':
                setCronDayOfWeek(Array.from(e.target.selectedOptions, (item) => item.value));
                setInputValue({...inputValues, cron: `${cronMinute} ${cronHour} ${cronDayOfMonth} ${cronMonth} ${Array.from(e.target.selectedOptions, (item) => item.value)}`});
            break;
            case 'name':
                setInputValue({...inputValues, name: value });
                setValidation({validation, name: ''});
            break;
            case 'message':
                setInputValue({...inputValues, messageID: value, messageName: Array.from(e.target.selectedOptions, (item) => item.text).toString()});
                setValidation({validation, messageID: ''});
            break;
            case 'list':
                setInputValue({...inputValues, listID: value, listName: Array.from(e.target.selectedOptions, (item) => item.text).toString()});
                setValidation({validation, listID: ''});
            break;
            default:
        }        
    }

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return result.error;
        }
        return false;
    }

    function validateCron(){
        if(user.admin === false && inputValues.cron === '0 * * * *')
            return {message: "Please choose at least one scheduling option."}

        return;
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        const cronError = validateCron();
        if(cronError){
            toast.error(cronError.message);
            return;
        }

        if(errors){
            console.log('errors!',errors);
            return;
        } 
        
        try {
            const result = await createTask(inputValues);

            if(result){
                //console.log(result);
                toast(result);
                setTimeout(function(){
                    window.location = '/tasks';
                },2000);
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast.error(ex.response.message);
            }
        }
    };

    useEffect(() => {
        let selectedMessageName = '';
        let selectedListName = '';
        async function fetchMessages(){
            //let filtered = [];
            await getMessageList(user._id)
                .then(messages => {
                    if(messages.length){
                        messages.forEach(m => {
                            //if(m.type === undefined || m.type === '')
                                //filtered.push(m);
                            if(source === 'steps' && m._id === selectedMessage){
                                selectedMessageName = m.name;
                            }
                        });
                        //setMessages(filtered);
                        setMessages(messages);
                    }else{
                        toast.error('You have no messages to send.');
                        setTimeout(()=>{
                            window.location = '/';
                        },2000);
                    }
                });                                      
        }

        async function fetchLists(){
            await getLists(user._id)
                .then(lists => {
                    lists.forEach(l => {
                        if(lists.length){
                            if(source === 'steps' && l._id === selectedList){
                            selectedListName = l.name;
                            }
                        }else{
                            toast.error('You have no client lists to send to.');
                            setTimeout(()=>{
                                window.location = '/';
                            },2000);
                        }
                    });
                    setLists(lists);
                });                                      
        }  
        
        async function checkLimits(){
            await checkPlanLimits(user, 'sms')
                .then(results => {
                    setLimitReached({...limitReached, pass: results.pass, reason: results.reason});
                });
        }

        /* if((cronMinute.length > 1 && cronMinute.find(x => x === '*')) || (cronMinute.length > 1 && cronMinute.find(x => x === '0'))){
            setCronMinute(cronMinute.splice(0,1));
        } */
        if(cronHour.length > 1 && cronHour.find(x => x === '*')){
            setCronHour(cronHour.splice(0,1));
        }
        if(cronDayOfMonth.length > 1 && cronDayOfMonth.find(x => x === '*')){
            setCronDayOfMonth(cronDayOfMonth.splice(0,1));
        }
        if(cronMonth.length > 1 && cronMonth.find(x => x === '*')){
            setCronMonth(cronMonth.splice(0,1));
        }
        if(cronDayOfWeek.length > 1 && cronDayOfWeek.find(x => x === '*')){
            setCronDayOfWeek(cronDayOfWeek.splice(0,1));
        }
        setCronAsText(convertCron(`${cronMinute.toString()} ${cronHour.toString()} ${cronDayOfMonth.toString()} ${cronMonth.toString()} ${cronDayOfWeek.toString()}`));

        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            checkLimits();
            fetchMessages();
            fetchLists();
            setTimeout(() =>{
                if(source === 'steps' && selectedMessageName !== '' && selectedListName !== ''){
                    setInputValue({...inputValues, messageName: selectedMessageName, listName: selectedListName})
                }
            },500);
        }
    },[user, limitReached, cronMinute, cronHour, cronDayOfMonth, cronMonth, cronDayOfWeek, user._id, inputValues, selectedMessage, selectedList, source]);

    return ( 
        <React.Fragment>
            <h3 className='h3-centered'>Schedule Text Message</h3>
            {limitReached.pass === false ? (
                <div className="error-box container-width-5">{limitReached.reason}</div>
            ):(
                <div>
                    <div className="form-container" style={{textAlign:"left",fontSize:"10pt"}}>
                        <TaskDisclaimer />
                    </div>
                    <div className="container-full-width">
                        <form onSubmit={handleSubmit} style={{textAlign:"left",marginTop:"10px"}}>
                            <TaskForm 
                                inputValues={inputValues}
                                user={user}
                                messages={source === 'steps' ? null : messages}
                                lists={source === 'steps' ? null : lists}
                                handleChange={(e) => handleChange(e)}
                                cronMinute={cronMinute}
                                cronHour={cronHour}
                                cronDayOfMonth={cronDayOfMonth}
                                cronMonth={cronMonth}
                                cronDayOfWeek={cronDayOfWeek}
                                cronAsText={cronAsText}
                                validation={validation}
                                formClass={formClass}
                            />
                            <div>&nbsp;</div>
                            <div style={{textAlign:"center"}}>
                                {(source === 'steps') ? (
                                    <span>
                                        <Link 
                                            className="btn btn-secondary shadow" 
                                            onClick={() => setShowTaskCreate(false, document.getElementById('showTaskCreate').click(), window.scrollTo(0, 0))} >
                                            Cancel
                                        </Link>
                                    </span>
                                ):(
                                    <span>
                                        <Link className="btn btn-secondary shadow" to="/tasks">Cancel</Link>
                                    </span>
                                )}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-primary shadow">Schedule Task</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default TaskAddNew;
