import React, { useState, useEffect, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import UnsubscribeConfirm from './unsubscribeConfirm';
import { unsubscribe } from '../../services/userService';

function Unsubscribe({user}) {
    const [unsubscribed, setUnsubscribed] = useState(false);

    const handleCancelClick = () =>{
        toast(<UnsubscribeConfirm setUnsubscribed={setUnsubscribed} />,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'logout'});
    }

    const getPlanId = () => {
        let subscriptionPlanID = 0;
        switch(user.plan){
            case 'Bronze Plan':
                subscriptionPlanID = window.env.BRONZE_PLAN_ID;
            break;
            case 'Bronze Plan Plus':
                subscriptionPlanID = window.env.BRONZE_PLAN_PLUS_ID;
            break;
            case 'Silver Plan':
                subscriptionPlanID = window.env.SILVER_PLAN_ID;
            break;
            case 'Silver Plan Plus':
                subscriptionPlanID = window.env.SILVER_PLAN_PLUS_ID;
            break;
            default:
            break;
        }
        return subscriptionPlanID;
    }

    useEffect(() => {
        //console.log('unsubscribed',unsubscribed);
        if(unsubscribed === true){
            callUnsubscribe();
        }

        async function callUnsubscribe(){
            const planId = getPlanId();
            const data = {
                _id: user._id,
                plan: user.plan,
                subscriptionPlanID: planId
            }
            await unsubscribe(data)
                .then(res => {
                    toast("Subscription cancelled!");
                    setTimeout(function(){
                        window.location = '/';
                    },2000);
                });
        }

    },[user, unsubscribed]);

    return ( 
        <Fragment>
            <h3 style={{textAlign:'center'}}>We hate to see you go! 😢</h3>

            <div className="container-width-5">
                <p>Are you sure you want to unsubscribe?</p>
                <p>Please tell us why you're leaving. We are always making changes to enhance this application, please contact us with suggestions to make it better.</p>
                <p><Link to="/contact">Contact Us</Link> with your suggestions or concerns.</p>
                <p>&nbsp;</p>
                <p style={{textAlign:'center'}}><Link onClick={()=>handleCancelClick()} className="btn btn-danger shadow cn-custom">Unsubscribe</Link></p>
            </div>
        </Fragment>
     );
}

export default Unsubscribe;