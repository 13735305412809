import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import TaskAddNew from "../tasks/taskAddNew";
import Input from '../common/input';

function Step5({onStep5NextClick, onStep5BackClick, loading, messageSent, reset, selectedMessage, selectedList, userID}) {
    const [showTaskCreate, setShowTaskCreate] = useState(false);

    function handleShowTaskCreate(e){
        if(e.target.checked){
            setShowTaskCreate(true);
        }else{
            setShowTaskCreate(false);
        }
    }

    window.scrollTo(0, 0);
    return ( 
        <React.Fragment>
            <div style={{textAlign:"center"}}>
            <Link className="btn btn-secondary shadow" onClick={onStep5BackClick}>Back</Link>&nbsp;&nbsp;&nbsp;
            {(messageSent === false) ? (
                <span>
                    <Link className={`btn btn-primary shadow `} onClick={onStep5NextClick}>
                        {(loading === true) ? (
                            <span>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;&nbsp;Sending...</span>
                            </span>
                        ):(
                            <span>Send Message</span>
                        )}
                    </Link>
                        <div><br/>
                            <Input name="showTaskCreate" label="Schedule this message to repeat?" type="checkbox" value="true" onChange={(e) => handleShowTaskCreate(e)} customClass=""/>
                            {showTaskCreate &&
                                <TaskAddNew selectedMessage={selectedMessage} selectedList={selectedList} userID={userID} setShowTaskCreate={setShowTaskCreate} source="steps"/>
                            }   
                        </div>
                </span>          
            ):(
                <Link className={`btn btn-primary shadow `} onClick={reset}>Send Another Message</Link>
            )}
            </div>
        </React.Fragment>
     );
}

export default Step5;