import Joi from 'joi';
import React, {useState, useRef} from 'react';
import { toast } from 'react-toastify';
import {createClient, accountStatusOptions, premierMemberOptions, contactPreferenceOptions} from '../../services/clientService';
import Input from '../common/input';
import Select from '../common/select';
import CNDate from '../common/cndate';
import { formatDate } from '../../utils/formatDate';
import { default as countries } from "../../services/countries.json";
import { default as states } from "../../services/states.json";
import { checkPlanLimits } from "../../services/userService";
import { useEffect } from "react";

function ClientAddNew({user}) {
    const dataFetchedRef = useRef(false);
    const [limitReached, setLimitReached] = useState({pass: true, reason: ''});
    const [inputValues, setInputValue] = useState({
        userID: user._id,
        accountID: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        entryDate: '',
        lastOrderDate: '',
        accountStatus: '',
        premierMember: '',
        contactPreference: '',
        customField1: '',
        customField2: '',
        customField3: '',
        customField4: '',
        customField5: ''
    });

    const [validation, setValidation] = useState({
        accountID: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        entryDate: '',
        lastOrderDate: '',
        accountStatus: '',
        premierMember: '',
        contactPreference: '',
        customField1: '',
        customField2: '',
        customField3: '',
        customField4: '',
        customField5: ''
    });

    const refValidation = {
        accountID: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        entryDate: '',
        lastOrderDate: '',
        accountStatus: '',
        premierMember: '',
        contactPreference: '',
        customField1: '',
        customField2: '',
        customField3: '',
        customField4: '',
        customField5: ''
    };

    const schema = Joi.object({
        accountID: Joi.number().label('Account ID'),
        firstName: Joi.string().min(1).max(50).required().label('First Name'),
        lastName: Joi.string().min(1).max(50).required().label('Last Name'),
        email: Joi.string().min(3).max(255).email({ tlds: { allow: false }}).label('Email'),
        phone: Joi.string().required().pattern(/^[0-9]+$/).label('Phone'), // from twilio ^\+[1-9]\d{1,14}$  // ^[0-9]+$
        street1: Joi.string().max(250).optional().allow('').label('Street 1'),
        street2: Joi.string().max(250).optional().allow('').label('Street 2'),
        city: Joi.string().max(250).optional().allow('').label('City'),
        state: Joi.string().max(100).optional().allow('').label('State'),
        zip: Joi.string().max(10).optional().allow('').label('Zip'),
        countryCode: Joi.string().max(50).optional().allow('').label('Country Code'),
        entryDate: Joi.date().label('Entry Date'),
        lastOrderDate: Joi.alternatives([
                                Joi.date(),
                                Joi.string().valid('')
                            ]).label('Last Order Date'),
        accountStatus: Joi.string().max(50).required().label('Account Status'),
        premierMember: Joi.string().max(3).empty('No').label('Premier Member'),
        contactPreference: Joi.string().optional().label('Contact Preference'),
        customField1: Joi.string().allow('').empty(''),
    customField2: Joi.string().allow('').empty(''),
    customField3: Joi.string().allow('').empty(''),
    customField4: Joi.string().allow('').empty(''),
    customField5: Joi.string().allow('').empty('')
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function handleChange(e){
        const { name, value } = e.target;
        setInputValue({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
    }

    function handleDateChange(date, name){
        if(date === null){
            setInputValue({...inputValues, [name]: '' });
        }else{
            setInputValue({...inputValues, [name]: formatDate(date) });
        }
        setValidation({validation, [name]: ''});
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;
        
        try {
            const result = await createClient(inputValues);
            if(result){
                toast("Client added.");
                setTimeout(function(){
                    window.location = '/clients';
                },2000);
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }        
    };

    useEffect(()=>{
        async function checkLimits(){
            await checkPlanLimits(user, 'client')
                .then(results => {
                    setLimitReached({...limitReached, pass: results.pass, reason: results.reason});
                })
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkLimits();
    });


    return(
        <React.Fragment>
            <h3 className='h3-centered'>Add New Client</h3>
            <div className='form-container'>
            {limitReached.pass === false ? (
                <div className="error-box container-width-8">{limitReached.reason}</div>
            ):(
            <form onSubmit={handleSubmit}>
                <Input name="userID" label="" value={inputValues.userID} onChange={() => {}} error="" type="hidden" />
                <Input name="accountID" label="Account ID" value={inputValues.accountID} onChange={(e) => handleChange(e)} error={validation.accountID} autoFocus={true} />
                <Input name="firstName" label="First Name" value={inputValues.firstName} onChange={(e) => handleChange(e)} error={validation.firstName} />
                <Input name="lastName" label="Last Name" value={inputValues.lastName} onChange={(e) => handleChange(e)} error={validation.lastName} />
                <Input name="email" label="Email" value={inputValues.email} onChange={(e) => handleChange(e)} error={validation.email} />
                <Input name="phone" label="Mobile Phone (Numbers only, include area code)" value={inputValues.phone} onChange={(e) => handleChange(e)} error={validation.phone} />
                <Input name="street1" label="Street 1" value={inputValues.street1} onChange={(e) => handleChange(e)} error={validation.street1} />
                <Input name="street2" label="Street 2" value={inputValues.street2} onChange={(e) => handleChange(e)} error={validation.street2} />
                <Input name="city" label="City" value={inputValues.city} onChange={(e) => handleChange(e)} error={validation.city} />
                <Select name="state" label="State" value={inputValues.state} items={states} keys={['name','abbreviation']} onChange={(e) => handleChange(e)} error={validation.state} selectedOption={inputValues.state} />
                <Input name="zip" label="Zip" value={inputValues.zip} onChange={(e) => handleChange(e)} error={validation.zip} />
                <Select name="countryCode" label="Country" value={inputValues.countryCode} items={countries} keys={['name','code']} error={validation.countryCode} onChange={(e) => handleChange(e)} selectedOption={inputValues.countryCode}/>
                <CNDate name="entryDate" label="Entry Date" value={inputValues.entryDate} selected={inputValues.entryDate !== '' ? new Date(inputValues.entryDate) : new Date()} onChange={(date) => handleDateChange(date,'entryDate')} error={validation.entryDate} />
                <CNDate name="lastOrderDate" label="Last Order Date" value={inputValues.lastOrderDate} selected={inputValues.lastOrderDate !== '' ? new Date(inputValues.lastOrderDate) : new Date()} onChange={(date) => handleDateChange(date,'lastOrderDate')} error={validation.lastOrderDate} />
                <Select name="accountStatus" label="Account Status" value={inputValues.accountStatus} items={accountStatusOptions} keys={['text','value']} error={validation.accountStatus} onChange={(e) => handleChange(e)} selectedOption={inputValues.accountStatus}/>
                <Select name="premierMember" label="Premier Member" value={inputValues.premierMember} items={premierMemberOptions} keys={['text','value']} error={validation.premierMember} onChange={(e) => handleChange(e)} selectedOption={inputValues.premierMember}/>
                <Select name="contactPreference" label="Contact Preference" value={inputValues.contactPreference} items={contactPreferenceOptions} keys={['text','value']} error={validation.contactPreference} onChange={(e) => handleChange(e)} selectedOption={inputValues.contactPreference}/>
                <Input name="customField1" label="Custom Field 1" value={inputValues.customField1} onChange={(e) => handleChange(e)} error={validation.customField1} />
                <Input name="customField2" label="Custom Field 2" value={inputValues.customField2} onChange={(e) => handleChange(e)} error={validation.customField2} />
                <Input name="customField3" label="Custom Field 3" value={inputValues.customField3} onChange={(e) => handleChange(e)} error={validation.customField3} />
                <Input name="customField4" label="Custom Field 4" value={inputValues.customField4} onChange={(e) => handleChange(e)} error={validation.customField4} />
                <Input name="customField5" label="Custom Field 5" value={inputValues.customField5} onChange={(e) => handleChange(e)} error={validation.customField5} />
                <div>&nbsp;</div>
                <div style={{textAlign:'center'}}>
                    <button className="btn btn-primary shadow">Add Client</button>
                </div>
            </form>
            )}
            </div>
        </React.Fragment>
    );
};
export default ClientAddNew;