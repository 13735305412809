import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { weekArray, gridArray, yearOptions, monthOptions } from '../../services/calendarService';
import moment from "moment/moment";
import Select from "../common/select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import DayDetail from "./dayDetail";

function Calendar({selectedDate, setSelectedDate, inputValues, headerOnClick, taskOnClick, removeTask}) {
    const { year, month } = useParams();
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonthName, setSelectedMonthName] = useState('');

    const currentDay = moment().date();
    const currentMonth = moment().month()+1;
    const currentYear = moment().year();
    
    const startOfMonth = moment().year(selectedYear).month(selectedMonth-1).startOf("month").format('ddd');
    const monthSize = parseInt(moment().year(selectedYear).month(selectedMonth-1).endOf("month").format('DD'));
    
    const startIndex = parseInt(Object.keys(weekArray).find(w => weekArray[w].value === startOfMonth));
    const endIndex = startIndex + monthSize;

    /* console.log('gridArray',gridArray);
    console.log('startOfMonth',startOfMonth);
    console.log('monthSize',monthSize);
    console.log('startIndex',startIndex);
    console.log('endIndex',endIndex); */

    const onYearSelect = (e) => {
        const { value } = e.target;
        setSelectedYear( parseInt(value) )
    }
  
    const onMonthSelect = (e) => {
        const { value } = e.target;
        setSelectedMonth( parseInt(value) )
    }

    const calendarNavPrev = () => {
        if(selectedMonth === 1){
            setSelectedYear(selectedYear-1);
            setSelectedMonth(12);
        }else{
            setSelectedMonth(selectedMonth-1);
        }
    }

    const calendarNavNext = () => {
        if(selectedMonth === 12){
            setSelectedYear(selectedYear+1);
            setSelectedMonth(1);
        }else{
            setSelectedMonth(selectedMonth+1);
        }
    }

    useEffect(() => {
        const defaultYear = year || moment().format('YYYY');
        const defaultMonth = month || moment().format('MM');
        
        setSelectedYear( parseInt(defaultYear) );
        setSelectedMonth( parseInt(defaultMonth) );
    }, [ year, month ]);

    useEffect(() => {
        let monthName = '';
        monthOptions.forEach(m => {
            if(Number(m.value) === Number(selectedMonth)){
                monthName = m.name;
            }
        });
        setSelectedMonthName(monthName);
    },[selectedMonth]);

    return ( 
        <React.Fragment>
            <div className="calendar-container">
                <div className="calendar-header">
                    <div className="calendar-selector-container-left">
                    <Select 
                        name="year"
                        label="" 
                        value={selectedYear} 
                        selectedOption={selectedYear} 
                        items={yearOptions} 
                        keys={['value','value']} 
                        onChange={(e) => onYearSelect(e)}
                        customClass="cn-custom-year"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Select 
                        name="month"
                        label="" 
                        value={selectedMonth} 
                        selectedOption={selectedMonth} 
                        items={monthOptions} 
                        keys={['abbrev','value']} 
                        onChange={(e) => onMonthSelect(e)}
                        customClass="cn-custom-year"
                    />  
                    </div>
                    <div className="calendar-selector-container-middle">{selectedMonthName}</div>
                    <div className="calendar-selector-container-right">
                        <Link onClick={() => calendarNavPrev()}><FontAwesomeIcon icon={faCaretLeft} size="xl" /></Link>&nbsp;&nbsp;&nbsp;
                        <Link onClick={() => calendarNavNext()}><FontAwesomeIcon icon={faCaretRight} size="xl" /></Link>
                    </div>
                </div>
                <div className="calendar-body">
                    <div className="calendar-week-container">
                        {
                            Object.keys(weekArray).map((data, i) => 
                                <div key={i} className="card shadow calendar-card-weekday">
                                    { weekArray[i].value }
                                </div>
                            )
                        }                        
                    </div>
                    <div className="calendar-date-container">
                    {
                        gridArray.map((data, i) => 
                            ((i - startIndex + 1) === currentDay && selectedYear === currentYear && selectedMonth === currentMonth) ? (
                                <DayDetail 
                                    key={i}
                                    index={i}
                                    startIndex={startIndex}
                                    inputValues={inputValues}
                                    selectedYear={selectedYear}
                                    selectedMonth={selectedMonth}
                                    headerOnClick={headerOnClick}
                                    taskOnClick={taskOnClick}
                                    removeTask={removeTask}
                                    dayClass="today"
                                />
                            ):(
                                (i >= startIndex && i < endIndex) ? (
                                    <DayDetail 
                                        key={i}
                                        index={i}
                                        startIndex={startIndex}
                                        inputValues={inputValues}
                                        selectedYear={selectedYear}
                                        selectedMonth={selectedMonth}
                                        headerOnClick={headerOnClick}
                                        taskOnClick={taskOnClick}
                                        removeTask={removeTask}
                                        dayClass="active"
                                    />
                            ):(
                                <div key={i} className="card shadow calendar-card-disable" style={{backgroundColor:"gray",margin:"1px"}}>
                                    <div className="card-body calendar-card-header-disable">&nbsp;</div>
                                    <div className="card-body calendar-card-body-disable"></div>
                                </div>
                            ))
                        )
                    }                        
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}

export default Calendar;