function init() {}

function log(error) {
  console.error(error);
}

const exported = {
  init,
  log
};

export default exported;