import React, {useEffect} from 'react';

function Redirect({url}) {
    useEffect(() => {
        window.location = url;
    });

    return ( 
        <div className="container-full-width">
            <h4 className="h4-centered">Redirecting...</h4>
        </div>
     );
}

export default Redirect;