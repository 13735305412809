import Joi from 'joi';
import React, { useState } from 'react';
import Input from '../common/input';
import { Link } from 'react-router-dom';
import { login } from '../../services/authService';
import Modal from 'react-bootstrap/Modal';
import { forgotEmail } from "../../services/userService";
import {toast} from "react-toastify";

const Login = () =>  {
    const [showModal, setShowModal] = useState(false);
    const [errorBoxDisplay, setErrorBoxDisplay] = useState('none');
    const [errorBoxText, setErrorBoxText] = useState('');
    const [inputValues, setInputValue] = useState({
        email: '',
        password: ''
    });
    const [email2, setEmail2] = useState('');

    const [validation, setValidation] = useState({
        email: '',
        password: ''
    });

    const refValidation = {
        email: '',
        password: ''
    };

    const schema = Joi.object({
        email: Joi.string().min(3).max(255).email({ tlds: { allow: false }}),
        password: Joi.string().min(6).max(1024).required()
    }).options({abortEarly: false});

    function validate(e){
        const result = schema.validate(inputValues);      
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    async function handleForgotPassword(e){
        e.preventDefault();
       
        let error = false;
        Array.prototype.forEach.call(e.target.elements, (element) => {
            const {name} = element;
            switch(name){
                case 'email2':
                    if(email2 === ''){                        
                        setErrorBoxText('Email is required.');
                        setErrorBoxDisplay('block');
                        error = true;
                    }
                break;
                default:
                break;
            }
        });
       
        if(!error){
            setShowModal(false);
            setErrorBoxDisplay('none');
            setErrorBoxText('');
            // send forgot password email
            await forgotEmail({email: email2})
                .then(results => {
                    if(results){
                        toast(results);
                        setTimeout(function(){
                            window.location = '/login';
                        },3000);
                    }
                });
        }
    }

    function handleChange(e){
        const { name, value } = e.target;
        setInputValue({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
    }

    function handleFPEmailChange(e){
        const { name, value } = e.target;
        setEmail2(value);
        setValidation({validation, [name]: ''});
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;
        
        try {
            await login(inputValues.email, inputValues.password);
            window.location = '/';
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                setValidation({...validation, email: ex.response.data});
            }
        }        
    };

    window.scrollTo(0, 0);

    return (
        <React.Fragment>   
            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <div>
                    <form onSubmit={handleForgotPassword}>
                        <div style={{textAlign:"center",margin:"10px 0px"}}>
                            <p>A temporary password will be sent to the email provided. Continue?</p>
                            <div  className="login container-width-4" style={{textAlign:'left'}} >
                                <Input name="email2" label="Email" value={email2} onChange={(e) => handleFPEmailChange(e)} autoFocus={true} />
                                <div id="errorBox" className="error-box" style={{display:`${errorBoxDisplay}`}}>{errorBoxText}</div>
                            </div>                             
                            <div>&nbsp;</div>
                            <Link onClick={()=>setShowModal(false)} className="btn btn-secondary shadow cn-custom">Cancel</Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="btn btn-primary shadow cn-custom">Continue</button>
                        </div>
                    </form>
                </div>
            </Modal>         
            <div className="login container-width-4">
                <h3 style={{textAlign:'center'}}>User Login</h3>
                <form onSubmit={handleSubmit}>
                    <Input name="email" label="Email" value={inputValues.email} onChange={(e) => handleChange(e)} error={validation.email} autoFocus={true} />
                    <Input name="password" label="Password" value={inputValues.password} onChange={(e) => handleChange(e)} error={validation.password} type="password" />
                    <div>&nbsp;</div>
                    <div style={{textAlign:'center'}}>
                        <button className="btn btn-primary">Login</button>
                    </div>   
                </form>
                <div>&nbsp;</div>
                <div style={{textAlign:'center'}}>
                    <Link onClick={() => setShowModal(true)} className="cn-custom2">Forgot Password?</Link>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default Login;