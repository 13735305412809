import Joi from 'joi';
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Input from "../common/input";
import { getUserValidation } from "../../services/userService";
import { toast } from 'react-toastify';

function Validation() {
    let [searchParams] = useSearchParams();

    const [inputValues, setInputValue] = useState({
        validationCode: '',
        userID: searchParams.get('user')
    });

    const [validation, setValidation] = useState({
        validationCode: '',
        userID: ''
    });

    const refValidation = {
        validationCode: '',
        userID: ''
    };

    const schema = Joi.object({
        validationCode: Joi.number().required().label('Validation Code'),
        userID: Joi.string().required().label('User ID')
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(e);
        if(inputValues.userID === ''){
            return toast.error('Please use link provided in email.')
        }

        if(errors) return;        
        
        try {
            const result = await getUserValidation(inputValues);
            if(result){
                toast(result);                                  
                setTimeout(function(){
                    window.location = '/login';
                },2000);
                
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }
    }

    return ( 
        <React.Fragment>
            <h3 className="h3-centered">User Validation</h3>
            <div className="container-width-5" style={{textAlign:'center'}}>Check your email for the verification code that was sent to you.</div>
            <div className="form-container" style={{width:"25%"}}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Input 
                        name="validationCode" 
                        label="Validation Code" 
                        value={inputValues.validationCode} 
                        onChange={(e) => handleChange(e)} 
                        error={validation.validationCode}
                        autoFocus={true} 
                        style={{width:"100% !important"}}
                    />
                    <div>&nbsp;</div>
                    <div style={{textAlign:"center"}}>
                        <button className="btn btn-primary shadow">Validate Code</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default Validation;