import React from "react";
import { toast } from "react-toastify";

function PopDelete({itemId, itemName, itemType, handleDelete, toastId}) {
  const extraMsg = handleItemType();

  function handleItemType(){
    let msg = '';
    switch(itemType){
      case 'message':
        msg = 'This will also remove any scheduled SMS that is configured with this message.';
      break;
      case 'list':
        msg = 'This will also remove any scheduled SMS that is configured with this list.';
      break;
      default:
        msg = '';
      break;
    }
    return msg;
  }

  function passDelete(){
    handleDelete(itemId);
  }

  function handleCancel(){
    toast.dismiss({toastId});
  }

  return ( 
    <div>
      {extraMsg !== '' &&
        <span>{extraMsg}&nbsp;</span>
      }
      Are you sure you want to delete {itemName}?<br/><br/>
      <div style={{textAlign:"center"}}>
        <button className="btn btn-primary" onClick={passDelete}>Yes</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
      </div>
    </div>
   );
}

export default PopDelete;