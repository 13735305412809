import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/tasks";
http.setJwt(auth.getJwt());
 
export async function getTasks(userid) {
    try{
        let { data: task } = await http.get(apiEndpoint+'/all/'+userid, {});
        return task.sort(t => t.name);
    }catch(ex){
        //toast.error(ex.response.data);
        return {};
    }
}

export async function getTask(id) {
    const { data: task } = await http.get(apiEndpoint+'/'+id, {});
    return task;
}

export async function createTask(data) {
    try {
        let { data: task } = await http.post(apiEndpoint, data);
        return task;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function updateTask(data) {    
    const {_id, values} = data;
    try {
        delete values._id;
        let { data } = await http.put(apiEndpoint+'/'+_id, values);
        return data;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function deleteTask(_id) {
    try {
        let { data: task } = await http.delete(apiEndpoint+'/'+_id, {});
        return task;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function getTaskStatus(_id) {
    try {
        let { data: task } = await http.get(apiEndpoint+'/status/'+_id, {});
        return task;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

const exports = {
    getTasks,
    getTask,
    createTask,
    updateTask,
    deleteTask,
    getTaskStatus
}
  
export default exports;