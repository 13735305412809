import React from 'react';

const MessageVariables = () => {
    
    return ( 
        <React.Fragment>
        <div>
            <p>
            You may insert substitution variables inside your message between the <b>[[ ]]</b> tags that will be replaced when your message is sent. 
            Example. "Hello [[first]] [[last]], this is [[myfirst]] [[mylast]], we have a special offer this week..."
            </p>
            <p>Vulgarity, racial, or hate speech will not be tolorated and will result in your account being banned with no refund.</p>
            <p>Thank you for your understanding and cooperation!</p>
            Possible Substitution Variables:<br/>
            <table className="shadow container-width-5">
                <thead>
                <tr style={{borderBottom:"solid 1px gray"}}>
                    <th>Field</th>
                    <th>Variable</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Client First Name</td>
                    <td>[[first]]</td>
                </tr>
                <tr>
                    <td>Client Last Name</td>
                    <td>[[last]]</td>
                </tr>
                <tr>
                    <td>Your First Name</td>
                    <td>[[myfirst]]</td>
                </tr>
                <tr>
                    <td>Your Last Name</td>
                    <td>[[mylast]]</td>
                </tr>
                <tr>
                    <td>Your Email</td>
                    <td>[[myemail]]</td>
                </tr>
                <tr>
                    <td>Your Phone</td>
                    <td>[[myphone]]</td>
                </tr>
                </tbody>
            </table>
        </div>
        <br/>
        </React.Fragment>
    );
};

export default MessageVariables;