import http from './httpService';
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/users";
const tokenKey = "token";

export async function getUser(id) {
    const { data: user } = await http.get(apiEndpoint+'/'+id, {});
    return user;
}

export async function getUserValidation(data) {
    try{
        const { data: user } = await http.post(apiEndpoint+'/validation', data);
        //console.log('user',user);
        return user;
    }catch(ex){
        toast.error(ex.response.data);
    }
}

export async function createUser(user) {
    try{
        delete user.confPassword;
        let {data: newUser} = await http.post(apiEndpoint, {
            "firstName": user.firstName,
            "lastName": user.lastName,
            "email": user.email,
            "phone": user.phone,
            "street1": user.street1,
            "street2": user.street2,
            "city": user.city,
            "state": user.state,
            "zip": user.zip,
            "countryCode": user.countryCode,
            "password": user.password,
            "isOptavia": user.isOptavia,
            "toc": user.toc
        });
        return newUser;
    }catch(ex){
        toast.error(ex.response.data);
    }
}

export async function updateUser(data) {
    const {_id} = data;
    try {
        delete data._id;
        await http.put(apiEndpoint+'/'+_id, data)
            .then(results => {
                /* console.log('data',results.data);
                console.log('user',results.data.user);
                console.log('token',results.data.token); */
                // set localStorage token
                if(results.data.token)
                    localStorage.setItem(tokenKey, results.data.token);
                return results.data.user;
            });        
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function updatePassword(data) {
    const {_id} = data;
    try {
        delete data._id;
        delete data.savedPassword;
        delete data.oldPassword;
        delete data.email;
        delete data.phone;
        delete data.confirmPassword;
        let { data: user } = await http.put(apiEndpoint+'/updatepassword/'+_id, data);
        return user;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function forgotEmail(data) {
    try{
        const { data: user } = await http.post(apiEndpoint+'/forgotpassword/', data);
        return user;
    }catch (ex) {
        toast.error(ex.response.data);
        return {};
    }
}

/* export async function getCurrentUser() {
    http.setJwt('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzMxYzZjMzZmZTMzZGViNGJiM2IxZDgiLCJmaXJzdE5hbWUiOiJDYXJhIiwibGFzdE5hbWUiOiJTdGFya2V5IiwibGV2ZWwiOiJzaWx2ZXIiLCJhZG1pbiI6ZmFsc2UsImlhdCI6MTY2NTUxNTI1M30.DMeVGJuJM-rXNzKYRhfPLF-k60-JqOwR--KF1EDuBaI');
    const {data: currentUser} = await http.get(apiEndpoint+'/me');
    console.log('currentUser',currentUser);
    localStorage.setItem("currentUser", JSON.stringify(JSON.parse(currentUser)));
    return currentUser;
} */

export async function getUserStats(id) {
    const { data: stats } = await http.get(window.env.API_URL+'/userlogs/stats/'+id, {});
    return stats;
}

export async function updatePaymentActivity(data) {
    const {_id} = data;
    try {
        delete data._id;
        let { data: jwt } = await http.put(window.env.API_URL+'/payments/subscribe/'+_id, data);
        // set localStorage token
        localStorage.setItem(tokenKey, jwt);
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function unsubscribe(data) {
    const {_id} = data;
    try {
        delete data._id;
        let { data: jwt } = await http.post(window.env.API_URL+'/payments/unsubscribe/'+_id, data);
        // set localStorage token
        localStorage.setItem(tokenKey, jwt);
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export const checkPlanLimits = async (user, type) => {
    return new Promise(async (resolve, reject) => {
    const results = {
        pass: true,
        reason: ''
    };

    await getUserStats(user._id)
        .then(stats => {
            // testing sms limits
            /* if(user._id === '63aa1409e66c063f48402527'){
                stats.monthSMS = 350;
                stats.clientCount = 25;
            } */
            if(user.admin ===true)
                return resolve(results);
            //console.log('stats',stats);
            switch(user.plan){
                case 'Bronze Plan':
                    if(type === 'client' && stats.clientCount >= window.env.BRONZE_PLAN_CLIENT_LIMIT){                        
                        results.pass = false;
                        results.reason = 'Client limit reached! You may want to upgrade your plan.';
                    }else if(type === 'sms' && stats.monthSMS >= window.env.BRONZE_PLAN_SMS_LIMIT){
                        results.pass = false;
                        results.reason = 'Monthly SMS limit reached! You may want to upgrade your plan.';
                    }
                break;
                case 'Silver Plan':
                    if(type === 'client' && stats.clientCount >= window.env.SILVER_PLAN_CLIENT_LIMIT){
                        results.pass = false;
                        results.reason = 'Client limit reached! You may want to upgrade your plan.';
                    }else if(type === 'sms' && stats.monthSMS >= window.env.SILVER_PLAN_SMS_LIMIT){
                        results.pass = false;
                        results.reason = 'Monthly SMS limit reached! You may want to upgrade your plan.';
                    }
                break;
                case 'Bronze Plus Plan':
                    if(type === 'client' && stats.clientCount >= window.env.BRONZE_PLUS_CLIENT_LIMIT){
                        results.pass = false;
                        results.reason = 'Client limit reached! You may want to upgrade your plan.';
                    }else if(type === 'sms' && stats.monthSMS >= window.env.BRONZE_PLUS_SMS_LIMIT){
                        results.pass = false;
                        results.reason = 'Monthly SMS limit reached! You may want to upgrade your plan.';
                    }
                break;
                case 'Silver Plus Plan':
                    if(type === 'client' && stats.clientCount >= window.env.SILVER_PLUS_PLAN_CLIENT_LIMIT){
                        results.pass = false;
                        results.reason = 'Client limit reached! You may want to upgrade your plan.';
                    }else if(type === 'sms' && stats.monthSMS >= window.env.SILVER_PLUS_PLAN_SMS_LIMIT){
                        results.pass = false;
                        results.reason = 'Monthly SMS limit reached! You may want to upgrade your plan.';
                    }
                break;
                default:
                    results.pass = false;
                    results.reason = 'Client has no plan!';
            }
            resolve(results);
        });
    });
}

const exports = {
    getUser,
    getUserValidation,
    createUser,
    updateUser,
    updatePassword,
    forgotEmail,
    getUserStats,
    updatePaymentActivity,
    checkPlanLimits,
    unsubscribe
}

export default exports;