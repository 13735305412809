import { toast } from 'react-toastify';
import { convertCron } from '../../utils/convertCron';
import { formatDate } from '../../utils/formatDate';

async function popCron(task) {
    let cronAsText = '';
    switch(task.type){
        case 'nonrepeating':
            if(task)
                cronAsText = `Scheduled ${new Date(formatDate(task.startDate) + ' ' + task.startHourText) < new Date() ? 'on' : 'for'} ${formatDate(task.startDate)} ${task.startHourText} EST`;
            break;
        case 'repeating':
            if(task)
                cronAsText = convertCron(task.cron);
            break;
        default:
    }
        
    if(cronAsText)
        toast(cronAsText, {
            position: "top-center",
            autoClose: 7000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        });
};

export default popCron;