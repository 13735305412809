import React from 'react';

function Contact() {
    window.scroll(0, 0);
    return ( 
        <>
            <h3 className="h3-centered">Contact</h3>
            <div className='container-width-5'>
                <p>
                    For general inquiries or suggestion to help make this app better, you may contact ClientPro staff at: <b>{window.env.APP_EMAIL}</b>
                </p>
                <p>
                    For application support: <b>{window.env.SUPPORT_EMAIL}</b>
                </p>
            </div>
        </>
    );
}

export default Contact;