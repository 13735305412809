import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";
import axios from "axios";

const apiEndpoint = window.env.API_URL + "/files";
http.setJwt(auth.getJwt());
 
export async function uploadFile(formData) {   
    try{
        let { data: file } = await axios({
            method: "post",
            url: apiEndpoint,
            data: formData,
            headers: {"Content-Type": "multipart/form-data"}
        });
        return file;
    }catch(ex){
        toast.error(ex.response.data);
        return {};
    }
}

export async function getFile(filename) {
    try{
        let { data: file } = await http.get(apiEndpoint+'/'+filename, {});
        return file;
    }catch(ex){
        toast.error(ex.response.data);
        return {};
    }
}

export async function deleteFile(filename) {
    try {
        let { data: file } = await http.delete(apiEndpoint+'/'+filename, {});
        return file;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function processUploadedFile(data){
    try{
        try{
            const result = await getFile(data.fileUploaded.file);
            if(result)
            return {filename: data.fileUploaded.file, data: result};
        }catch(ex){
            return null;
        }
    } catch (error) {
        return null;
    } 
}

const exports = {
    uploadFile,
    getFile,
    deleteFile,
    processUploadedFile
}
  
export default exports;