import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/lists";
http.setJwt(auth.getJwt());
 
export async function getLists(userid) {
    try{
        let { data: list } = await http.get(apiEndpoint+'/all/'+userid, {});
        return list.sort(l => l.name);
    }catch(ex){
        //toast.error(ex.response.data);
        return {};
    }
}

export async function getList(id) {
    const { data: list } = await http.get(apiEndpoint+'/'+id, {});
    return list;
}

export async function createList(data) {
    try {
        let { data: list } = await http.post(apiEndpoint, data);
        //console.log(list);
        return list;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function updateList(data) {
    const {_id} = data;
    try {
        delete data._id;
        let { data: list } = await http.put(apiEndpoint+'/'+_id, data);
        return list;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function deleteList(_id) {
    try {
        let { data: list } = await http.delete(apiEndpoint+'/'+_id, {});
        return list;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

const exports = {
    getLists,
    getList,
    createList,
    updateList,
    deleteList
}
  
export default exports;