import Joi from 'joi';
import React, {useState, useEffect, useRef, useReducer} from 'react';
import {formatDate} from '../../utils/formatDate';
import { toast } from 'react-toastify';
import {createList} from '../../services/listService';
import {getClientList} from '../../services/clientService';
import Input from '../common/input';
import ClientFilters from "../clients/clientFilters";

function ListAddNew({user, customStyle, source, handleSelectedList}) { 
    const dataFetchedRef = useRef(false);
    const [clients, setClients] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [inputValues, setInputValues] = useState({
        userID: user._id,
        name: '',
        clients: [],
        filters: []
    });

    const [validation, setValidation] = useState({
        name: '',
        clients: []
    });

    const refValidation = {
        name: '',
        clients: []
    };

    const schema = Joi.object({
        name: Joi.string().min(1).max(50).required(),
        clients: Joi.array(),
        filters: Joi.array()
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function handleSelectAll(e){
        const { name } = e.target;
        let checkStatus = document.getElementById(name).checked;
        let clientList = clients;
        
        Object.keys(clientList).forEach(c => {
            clientList[c].selected = checkStatus;
        });
        
        setSelectAll(!selectAll);
        setClients(clientList);
    }

    function handleChange(e){
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
        let checkStatus = document.getElementById(name).checked;
               
        let clientList = clients;
        Object.keys(clientList).forEach(c => {
            if(clientList[c]._id === name){
                clientList[c].selected = checkStatus;                
            }
        });
        setClients(clientList);
        forceUpdate();
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;
        
        let data = {userID: user._id, name: inputValues.name, clients:[], filters: inputValues.filters};
        
        try {
            let selectedClients = [];
            Object.keys(clients).forEach(c => {
                if(clients[c].selected){
                    selectedClients.push(clients[c]._id);
                }
            });
            
            if(selectedClients.length){
                data.clients = selectedClients;
                const result = await createList(data);
                //console.log('result', result);
                if(result){
                    toast("List added.");
                    if(source === "home"){
                        handleSelectedList(result._id);
                    }else{                    
                        setTimeout(function(){
                            window.location = '/lists';
                        },2000);
                    }
                }
            }else{
                toast.error("Please choose at least one client.");
            }            
            
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }
    };

    useEffect(() => {
        async function fetchClients(){
            try {
                const clientList = await getClientList(user._id);
                if(Object.keys(clientList).length){
                    Object.keys(clientList).forEach(c => {
                        // add selected key
                        clientList[c] = {...clientList[c], selected: false};
                    });
                    setClients(clientList);
                }                
            } catch (ex) {
               toast('No clients found.');
            }                         
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchClients();
    },[user._id, clients]);

    return ( 
        <React.Fragment>
            <h3 className='h3-centered'>Add Client List</h3>
            <div style={customStyle}>
                          
                <form onSubmit={handleSubmit}> 
                    <div className="form-container" style={{width:"300px"}}>                       
                    <Input name="userID" label="" value={inputValues.userID} onChange={() => {}} error="" type="hidden" />
                    <Input name="name" label="List Title" value={inputValues.name} onChange={(e) => handleChange(e)} error={validation.name} autoFocus={true} />                             
                    </div>
                    <div className="filter-container">
                    <ClientFilters user={user} setClients={setClients} setListFilters={(f) => {setInputValues({...inputValues, filters: f})}} />                        
                    </div>  
                    <div>Choose clients for your list.</div>
                    <table className="client-list table table-striped shadow">
                    <thead>
                        <tr>
                        <th scope="col" nowrap="nowrap" align="center">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name="selectAll" 
                                    id="selectAll" 
                                    value={selectAll} 
                                    checked={selectAll} 
                                    onChange={(e) => handleSelectAll(e)} 
                                />
                            </th>
                            <th scope="col" nowrap="nowrap">First Name</th>
                            <th scope="col" nowrap="nowrap">Last Name</th>
                            <th scope="col" nowrap="nowrap">Email</th>
                            <th scope="col">Phone</th>
                            {user.isOptavia === false && 
                                <th scope="col">State</th>
                            }
                            <th scope="col">Entry Date</th>
                            {user.isOptavia === true &&
                                <th scope="col">Last Order</th>
                            }
                            <th scope="col">Status</th>
                            {user.isOptavia === true &&
                                <th scope="col">Premier</th>
                            }
                            {user.isOptavia === false &&
                                <th scope="col">Contact Pref</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(clients).map((key) => 
                        <tr key={key}>
                            <td>
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    name={clients[key]._id} 
                                    id={clients[key]._id} 
                                    value={clients[key]._id}
                                    checked={clients[key].selected} 
                                    onChange={(e) => handleChange(e)}
                                    disabled=""
                                />
                            </td>
                            <td>{clients[key].firstName}</td> 
                            <td>{clients[key].lastName}</td>
                            <td>{clients[key].email}</td>
                            <td>{clients[key].phone}</td>
                            {user.isOptavia === false &&
                                <td>{clients[key].state}</td>
                            }
                            <td>{formatDate(clients[key].entryDate)}</td>
                            {/* <td>{(clients[key].lastOrderDate !== '' ? formatDate(clients[key].lastOrderDate) : '')}</td>
                            <td>{clients[key].accountStatus}</td>
                            <td>{clients[key].premierMember}</td>
                            <td>{clients[key].contactPreference}</td> */}
                            {user.isOptavia === true &&
                                <td>{(clients[key].lastOrderDate !== '' ? formatDate(clients[key].lastOrderDate) : '')}</td>
                            }
                                <td>{clients[key].accountStatus}</td>
                            {user.isOptavia === true &&
                                <td>{clients[key].premierMember}</td>
                            }
                            {user.isOptavia === false &&
                                <td>{clients[key].contactPreference}</td>
                            }
                        </tr>
                    )}
                    </tbody>
                </table>
                <div>&nbsp;</div>
                <div style={{textAlign:"center"}}><button className="btn btn-primary shadow">Add Client List</button></div>
                <div>&nbsp;</div>
                </form>
            </div>
            <div>
            
            </div>
        </React.Fragment>
    );
}

export default ListAddNew;
