import http from './httpService';
import { toast } from "react-toastify";

const apiEndpoint = window.env.API_URL + "/notifications";

export async function getNotifications(_id){
    try {
        let { data: notifications } = await http.get(apiEndpoint+'/'+_id, {});
        return notifications;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function updateNotification(data) {
    try {
        let { data: notification } = await http.put(apiEndpoint+'/update',data);
        return notification;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

export async function deleteNotification(data) {
    try {
        let { data: notification } = await http.put(apiEndpoint+'/delete',data);
        return notification;
    } catch (ex) {
        toast.error(ex.response.data);
        return {};
    } 
}

const exports = {
    getNotifications,
    updateNotification,
    deleteNotification
}

export default exports;