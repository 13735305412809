import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { updatePaymentActivity } from "../../services/userService";

function PayPalButton({user, plan}) {
    const onError = (data, actions) => {
        //console.log('onError...');
        //console.log('data',data);
        //console.log('actions',actions);
        toast.error("An error occured with your payment.");
    };

    const createSubscription = (data, actions) => {
        //console.log("create subscription...");
        //console.log('data', data);
        //console.log('actions', actions);
        return actions.subscription.create({
            plan_id: plan.subscriptionPlanID
        });
    };

    const onApprove = async (data, detail) => {
        // call backend to store transaction details
        //console.log("On approve...");
        //console.log('data', data);
        //console.log('selected plan', plan.subscriptionPaymentPlan);
        await updatePaymentActivity({
            _id: user._id,
            plan: plan.subscriptionPaymentPlan,
            facilitatorAccessToken: data.facilitatorAccessToken,
            orderID: data.orderID,
            paymentSource: data.paymentSource,
            subscriptionID: data.subscriptionID
        })
            .then(results => {
                toast("Payment transaction approved!");
                setTimeout(function(){
                    window.location = '/';
                },2000);
            });        
    };

    return ( 
        <React.Fragment>
            <PayPalScriptProvider options={{
                "client-id": window.env.PAYPAL_CLIENT_ID,
                intent: "subscription",
                vault: true
            }}>
                <div style={{width:'100%'}}>
                    <div className="subscription-desc shadow">
                        <div style={{fontWeight:'bold',textAlign:'center'}}>{plan.subscriptionPaymentPlan}</div>
                        <div>{plan.subscriptionDescription}</div>
                        <div style={{fontWeight:'bold',textAlign:'center'}}>{plan.currencySign}{plan.paymentAmount.toString()}</div>
                    </div>
                    <PayPalButtons
                        style={{layout: "vertical"}}
                        createSubscription={createSubscription}
                        onApprove={onApprove}
                        onError={onError}                        
                    />
                </div>
            </PayPalScriptProvider> 
        </React.Fragment>
    );
}

export default PayPalButton;