import Joi from 'joi';
import React, {useState, createRef} from 'react';
import { toast } from 'react-toastify';
import {createUser} from '../../services/userService';
import Input from '../common/input';
import { Link } from 'react-router-dom';
import Select from '../common/select';
import ReCAPTCHA from 'react-google-recaptcha';
import { default as countries } from "../../services/countries.json";
import { default as states } from "../../services/states.json";

const Signup = () =>  {
    const recaptchaRef = createRef();
    const [inputValues, setInputValue] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        confirmPassword: '',
        isOptavia: false,
        toc: false,
        recaptcha: -1
    });

    const [validation, setValidation] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        confirmPassword: '',
        isOptavia: false,
        toc: false,
        recaptcha: -1        
    });

    const refValidation = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        countryCode: '',
        password: '',
        confirmPassword: '',
        isOptavia: false,
        toc: false,
        recaptcha: -1
    };

    const schema = Joi.object({
        firstName: Joi.string().min(1).max(50).required().label("First Name"),
        lastName: Joi.string().min(1).max(50).required().label("Last Name"),
        email: Joi.string().min(3).max(255).email({ tlds: { allow: false }}).label("Email"),
        phone: Joi.string().required().pattern(/^[0-9]+$/).label('Phone'), // from twilio ^\+[1-9]\d{1,14}$  // ^[0-9]+$
        street1: Joi.string().max(250).required().label('Street Address 1'),
        street2: Joi.string().max(250).allow('').label('Street Address 2'),
        city: Joi.string().max(250).required().label('City'),
        state: Joi.string().max(100).required().label('State'),
        zip: Joi.string().max(10).required().label('Zip Code'),
        countryCode: Joi.string().max(50).required().label('Country'),
        password: Joi.string().required().label("Password"),
        confirmPassword: Joi.ref('password'),
        isOptavia: Joi.boolean().default(false).label('Optavia')
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function handleRecaptcha(e){
        const recaptchaValue = recaptchaRef.current.getValue();
        //console.log('recaptchaValue', recaptchaValue);
    }

    function handleChange(e){
        const { name, value } = e.target;
        switch(name){
            case 'isOptavia':                
                if(e.target.checked === true){
                    setInputValue({...inputValues, [name]: true });
                }else if(e.target.checked === false){
                    setInputValue({...inputValues, [name]: false });
                }
            break;
            case 'toc':                
                if(e.target.checked === true){
                    setInputValue({...inputValues, [name]: true });
                }else if(e.target.checked === false){
                    setInputValue({...inputValues, [name]: false });
                }
            break;
            default:
                setInputValue({...inputValues, [name]: value });
                setValidation({validation, [name]: ''});
            break;
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        
        if(inputValues.toc === false){
            return toast.error('You must agree to the Terms and Conditions.');
        }
        const recaptchaValue = recaptchaRef.current.getValue();
        if(recaptchaValue === ''){
            return toast.error('Please verify that you are not a robot!');
        }
        if(inputValues.countryCode !== 'US'){
            return toast.error('We are not currently accepting non-US customers at this time.');
        }

        const errors = validate(e);        
        if(errors) return; 

        try {
            const result = await createUser(inputValues);
            if(result){
                toast('Please check email to finish registration.');
                setTimeout(function(){
                    window.location = '/validation?user='+result._id;
                },2000);
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }        
    };

    return(
        <React.Fragment>
            <h3 style={{textAlign:'center'}}>Register for an Account</h3>
            <div className='form-container'>
            <form onSubmit={handleSubmit}>
                <Input name="firstName" label="First Name" value={inputValues.firstName} onChange={(e) => handleChange(e)} error={validation.firstName} autoFocus={true} />
                <Input name="lastName" label="Last Name" value={inputValues.lastName} onChange={(e) => handleChange(e)} error={validation.lastName} />
                <Input name="email" label="Email" value={inputValues.email} onChange={(e) => handleChange(e)} error={validation.email} />
                <Input name="phone" label="Phone (numbers only)" value={inputValues.phone} onChange={(e) => handleChange(e)} error={validation.phone} />
                <Input name="street1" label="Street Address 1" value={inputValues.street1} onChange={(e) => handleChange(e)} error={validation.street1} />
                <Input name="street2" label="Street Address 2" value={inputValues.street2} onChange={(e) => handleChange(e)} error={validation.street2} />
                <Input name="city" label="City" value={inputValues.city} onChange={(e) => handleChange(e)} error={validation.city} />
                <Select name="state" label="State" items={states} keys={['name','abbreviation']} onChange={(e) => handleChange(e)} error={validation.state} selectedOption={inputValues.state} />
                <Input name="zip" label="Zip Code" value={inputValues.zip} onChange={(e) => handleChange(e)} error={validation.zip} />
                <Select name="countryCode" label="Country" items={countries} keys={['name','code']} error={validation.countryCode} onChange={(e) => handleChange(e)} selectedOption={inputValues.countryCode}/>
                <Input name="password" label="Password" value={inputValues.password} onChange={(e) => handleChange(e)} error={validation.password} type="password" />
                <Input name="confirmPassword" label="Confirm Password" value={inputValues.confirmPassword} onChange={(e) => handleChange(e)} error={validation.confirmPassword} type="password" />
                <div>I'm an Optavia<span style={{fontSize:'7pt',position:'relative',top:'-3px'}}>&reg;</span> Health Coach&nbsp;&nbsp;
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        name="isOptavia" 
                        id="isOptavia" 
                        value={inputValues.isOptavia}
                        checked={inputValues.isOptavia} 
                        onChange={(e) => handleChange(e)}
                        disabled=""
                    />
                </div>
                <div>&nbsp;</div>
                <div>
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        name="toc" 
                        id="toc" 
                        value={inputValues.toc}
                        checked={inputValues.toc} 
                        onChange={(e) => handleChange(e)}
                        disabled=""
                    />
                    &nbsp;&nbsp;I have read and agree to the ClientPro <Link to="/terms">Terms and Conditions</Link>. I agree that my clients used within this application have agreed to recieve SMS messages and I agree to not send SMS messages to my clients if they refuse to accept messages.
                </div>
                <div>&nbsp;</div>
                <div className="recaptcha-container">
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6LcLEAckAAAAAJBgh2wjHtjnXBnnKuWyBDOECOiH"  onChange={(e) => handleRecaptcha(e)} />
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign:'center'}}>
                    <button className="btn btn-primary">Sign Up</button>
                </div>
            </form>
            </div>
        </React.Fragment>
    );
}
 
export default Signup;