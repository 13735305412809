
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom';
import { getTasks, createTask, deleteTask } from "../../services/taskService";
import { getMessageList } from "../../services/messageService";
import { getLists } from "../../services/listService";
import Calendar from "../calendar/calendar";
import TaskDisclaimer from "./taskDisclaimer";
import Input from '../common/input';
import Select from '../common/select';
import popTask from "../common/popTask";
import PopDelete from "../common/popDelete";
import {default as cronData} from '../../services/cronService.json';
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { formatDate } from '../../utils/formatDate';
import { checkPlanLimits } from "../../services/userService";

function NonRepeatingTasks({ user }) {
    const dataFetchedRef = useRef(false);
    const [limitReached, setLimitReached] = useState({pass: true, reason: ''});
    const [showModal, setShowModal] = useState(false);
    const [selectedMessageName, setSelectedMessageName] = useState('');
    const [selectedMessage, setSelectedMessage] = useState('');
    const [selectedList, setSelectedList] = useState('');
    const [selectedListName, setSelectedListName] = useState('');
    const [selectedDate, setSelectedDate] = useState(''); 
    const [selectedHour, setSelectedHour] = useState('');  
    const [selectedHourText, setSelectedHourText] = useState('');
    const [messages, setMessages] = useState({});
    const [lists, setLists] = useState({});
    const [inputValues, setInputValue] = useState({
        tasks: []           
    });
    const [validation, setValidation] = useState({
        messageID: '',
        listID: '',
        startHour: ''
    });

    const refValidation = {
        messageID: '',
        listID: '',
        startHour: ''
    };

    const now = new Date();
    let today = `${now.getMonth()+1}/${now.getDate()}/${now.getFullYear()}`
    let yesterday = new Date(now.getTime());
    yesterday.setDate(now.getDate() -1);
    const yesterdayText = `${yesterday.getMonth()+1}/${yesterday.getDate()}/${yesterday.getFullYear()}`;
    const currentHour = now.getHours();

    function headerOnClick(date){
        //console.log('headerOnClick', date);
        //console.log('yesterdayText', yesterdayText);
        let thisDate = new Date(date);
        let yesterdayDate = new Date(yesterdayText);
        //alert(`thisDate = ${thisDate.toString()}, yesterdayDate = ${yesterdayDate.toString()}, comparison thisDate > yesterdayDate = ${thisDate > yesterdayDate}`);
        if(thisDate > yesterdayDate){
            setShowModal(true);
            setSelectedDate(date);
        }else{
            toast.error('Date must be today or in the future.');
        }
    }

    function taskOnClick(task){
        popTask(task);
    }

    function handleMessageName(e){
        //console.log(e.target);
        const { name } = e.target;
        let messageName = Array.from(e.target.selectedOptions, (item) => item.text).toString();
        setSelectedMessageName(messageName);
        setValidation({validation, [name]: ''});
    }

    function handleListName(e){
        const { name } = e.target;
        let listName = Array.from(e.target.selectedOptions, (item) => item.text).toString();
        setSelectedListName(listName);
        setValidation({validation, [name]: ''});
    }

    function handleHourChange(e){
        const { name, value } = e.target;
        let hourText = Array.from(e.target.selectedOptions, (item) => item.text).toString();       
        setSelectedHour(value);
        setValidation({validation, [name]: ''});
        setSelectedHourText(hourText);
    }

    function myValidation(e){
        let result = [];
        Array.prototype.forEach.call(e.target.elements, (element) => {
            const {name, value} = element;
            //console.log('name: ' + name + ' value: ' + value);
            switch(name){
                case 'messageID':
                    if(value === ''){
                        result.push({key: name, message: 'Message is required'});
                    }
                break;
                case 'listID':
                    if(value === ''){
                        result.push({key: name, message: 'Client List is required'});
                    }
                break;
                case 'startHour':
                    if(value === ''){
                        result.push({key: name, message: 'Start Hour is required'});
                    }
                break;
                default:
                break;
            }
        });
       return result;
    }

    function validate(e){
        const result = myValidation(e);
        
        if(result.length){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.find(e => e.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return result;
        }
        
        return false;
    }

    async function handleAdd(e){
        e.preventDefault();
        let thisDate = new Date(selectedDate);
        let todayDate = new Date(today);
        if((thisDate.getTime() === todayDate.getTime()) && (parseInt(selectedHour) <= parseInt(currentHour))){
            toast.error('Hour must be in the future.');
        }else{
            let newTask = [];
            newTask.push({
                uid: uuid(),
                name: selectedMessageName,
                type: 'nonrepeating',
                userID: user._id,
                userEmail: user.email,
                userName: user.firstName + ' ' + user.lastName,
                messageID: selectedMessage,
                messageName: selectedMessageName, 
                listID: selectedList,
                listName: selectedListName,                 
                startDate: selectedDate, 
                startHour: selectedHour, 
                startHourText: selectedHourText
            }); 

            const errors = validate(e);

            if(errors){
                console.log('errors',errors);
                return;
            }

            setShowModal(false);
            setValidation(refValidation);

            try {
                const result = await createTask(newTask[0]);
                if(result){
                    //console.log('create task result',result);
                    // after task is created in the database, update the ids in the object array to match        
                    newTask[0]._id = result._id;
                    newTask[0].uid = result._id;
                    let tasks = inputValues.tasks; 
                    tasks.push(newTask[0]);
                    setInputValue({...inputValues, tasks: tasks});
                    toast(result.message);
                }      
            } catch (ex) {
                if(ex.response && ex.response.status === 400){
                    toast.error(ex.response.message);
                }
            }
        }
    }

    function removeTask(item){
        //console.log('item',item);
        if(item.hasOwnProperty('_id')){
            toast(
                <PopDelete 
                    handleDelete={handleDelete} 
                    itemId={item._id} 
                    itemName={item.name}
                    toastId='delete'
                />
            ,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'delete'});
        }else{
            handleDelete(item.uid);
        }
    }

    async function handleDelete(uid){
        let taskArr = inputValues.tasks;
        let filtered = taskArr.filter(obj => obj.hasOwnProperty('_id') === true && obj.uid === uid);
        //console.log('filtered',filtered);
        taskArr.splice(taskArr.findIndex(obj => obj.uid === uid),1);
        setInputValue({...inputValues, tasks: taskArr});
        const res = await deleteTask(filtered[0]._id);
        if(res){
            toast('Task deleted.');
        }
    }

    useEffect(() => {
        /* console.log('====================================');
        console.log('now',now);
        console.log('today',today);
        console.log('yesterday',yesterday);
        console.log('yesterdayText',yesterdayText);
        console.log('currentHour',currentHour);
        console.log('selectedMessage',selectedMessage);
        console.log('selectedList',selectedList);
        console.log('selectedDate',selectedDate);
        console.log('selectedHour',selectedHour);
        console.log('selectedHourText',selectedHourText);
        console.log('inputValues', inputValues);
        console.log('===================================='); */

        async function checkLimits(){
            await checkPlanLimits(user, 'sms')
                .then(results => {
                    setLimitReached({...limitReached, pass: results.pass, reason: results.reason});
                });
        }

        async function fetchTasks(){
            await getTasks(user._id)
                .then(result => {
                    //console.log('result', result)
                    if(result.length){
                        let filtered = result.filter(t => t.type === 'nonrepeating');
                        filtered.forEach((f,i) => {
                            if(f.hasOwnProperty('uid') === false){
                                filtered[i].uid = filtered[i]._id;
                            }
                            filtered[i].startDate = formatDate(filtered[i].startDate);
                        })                  
                        
                        //console.log('filtered tasks', filtered);
                        //let tasks = inputValues.tasks;
                        //tasks.push(filtered);
                        setInputValue({...inputValues, tasks: filtered}); 
                    }                
                });                                       
        }

        async function fetchMessages(){
            await getMessageList(user._id)
                .then(result => {
                    setMessages(result);                 
                });                                       
        }

        async function fetchLists(){
            setLists(await getLists(user._id));                                      
        }
        
        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            checkLimits();
            fetchTasks();
            fetchMessages();
            fetchLists();
        }
    },[user, user._id, selectedMessage, selectedList, selectedDate, selectedHour, selectedHourText, inputValues, limitReached]);

    return ( 
        <React.Fragment>
            <h3 className='h3-centered'>Schedule SMS Messages</h3>
            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <form onSubmit={handleAdd} style={{textAlign:"left",marginTop:"10px"}}>
                    <Input name="userID" label="" value={inputValues.userID} onChange={() => {}} error="" type="hidden" />
                    <Input name="startDate" label="" value={selectedDate} onChange={() => {}} error="" type="hidden" />
                    <h3 className="h3-centered">Schedule a Message for {selectedDate}</h3>
                    <div className="form-container">
                        <Input name="name" label="Schedule Name" value={selectedMessageName} onChange={() => {}} type="hidden" />
                        {messages && messages.length &&
                        <Select name="messageID" 
                            label="Select Message" 
                            value={inputValues.messageID} 
                            selectedOption={inputValues.messageID} 
                            items={messages} 
                            keys={['name','_id']} 
                            onChange={(e) => setSelectedMessage(e.target.value, handleMessageName(e))}
                            error={validation.messageID} 
                            customClass=""
                        />
                        }
                        {lists && lists.length &&
                        <Select name="listID"
                            label="Client List" 
                            value={inputValues.listID} 
                            selectedOption={inputValues.listID} 
                            items={lists} 
                            keys={['name','_id']} 
                            onChange={(e) => setSelectedList(e.target.value, handleListName(e))}
                            error={validation.listID}  
                            customClass=""
                        />
                        }
                        <Select name="startHour" 
                            label="Start Hour" 
                            items={cronData.hour} 
                            keys={['name','value']} 
                            nullValue="" 
                            onChange={(e) => handleHourChange(e)}
                            selectedOption={inputValues.startHour} 
                            error={validation.startHour} 
                            customClass=""
                        />
                    </div>
                    <div style={{textAlign:"center",margin:"10px 0px"}}>
                        <Link onClick={()=>setShowModal(false, setValidation(refValidation))} className="btn btn-secondary shadow cn-custom">Close</Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-primary shadow cn-custom">Add</button>
                    </div>
                </form>
            </Modal>
            {limitReached.pass === false ? (
                <div className="error-box container-width-5">{limitReached.reason}</div>
            ):(
                <div>
                    <div className="container-width-7" style={{fontSize:'10pt'}}>
                        <TaskDisclaimer/>
                    </div>
                    <Calendar 
                        setShowModal={setShowModal} 
                        selectedDate={selectedDate} 
                        setSelectedDate={(e) => setSelectedDate(e)} 
                        inputValues={inputValues} 
                        removeTask={removeTask}
                        headerOnClick={headerOnClick}
                        taskOnClick={taskOnClick}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default NonRepeatingTasks;
