import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { uploadFile, processUploadedFile, deleteFile } from '../../services/fileService';
import { checkPlanLimits } from "../../services/userService";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function ClientImport({user}) {
    const [showImportModal, setShowImportModal] = useState(false);
    const [showOptaviaModal, setShowOptaviaModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [fileContents, setFileContents] = useState('');
    const [isNormal, setisNormal] = useState(true);
    const [isOptavia, setIsOptavia] = useState(false);
    const dataFetchedRef = useRef(false);
    const [limitReached, setLimitReached] = useState({pass: true, reason: ''});

    function handleCheck(){
        setIsOptavia(!isOptavia);
        setisNormal(!isNormal);        
    }

    function handleChange(e){
        setSelectedFile(e.target.files[0]);
        setIsSelected(true);
    }

    async function handleSubmit(e){
        e.preventDefault();
        const formData = new FormData();

		formData.append('file', selectedFile);

        try {
            const result = await uploadFile(formData);
            if(result){
                toast(result.message);
                const res = await processUploadedFile(result);
                if(res){
                    setFileContents(res.data);
                    await deleteFile(res.filename);
                }
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }		
    }

    useEffect(()=>{
        async function checkLimits(){
            await checkPlanLimits(user, 'client')
                .then(results => {
                    setLimitReached({...limitReached, pass: results.pass, reason: results.reason});
                })
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkLimits();
    }, [user, limitReached]);

    window.scrollTo(0, 0);
    
    return ( 
        <React.Fragment>
            <Modal show={showImportModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Example format of client import CSV file with available fields:</h3>
                <div className="container client-import-modal">
                    <table border="1px">
                        <thead>
                            <tr>
                                <th>Field Name</th><th>Data Type</th><th>Required?</th><th>Options</th><th>Example</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>FirstName</td><td>String</td><td>Required</td><td>Min 1, max 50</td><td>John</td>
                            </tr>
                            <tr>
                                <td>LastName</td><td>String</td><td>Required</td><td>Min 1, max 50</td><td>Doe</td>
                            </tr>
                            <tr>
                                <td>Email</td><td>String</td><td>Required</td><td>Min length 5, max 255</td><td>john.doe@gmail.com</td>
                            </tr>
                            <tr>
                                <td>Phone</td><td>Number</td><td>Required</td><td>(Numbers only), min 7, max 12</td><td>18882225555</td>
                            </tr>
                            <tr>
                                <td>Street1</td><td>String</td><td>Optional</td><td>Max 250</td><td>123 Street</td>
                            </tr>
                            <tr>
                                <td>Street2</td><td>String</td><td>Optional</td><td>Max 250</td><td>Apt 11</td>
                            </tr>
                            <tr>
                                <td>City</td><td>String</td><td>Optional</td><td>Max 250</td><td>Miami</td>
                            </tr>
                            <tr>
                                <td>State</td><td>String</td><td>Optional</td><td>Max 100</td><td>Florida</td>
                            </tr>
                            <tr>
                                <td>Zip</td><td>String</td><td>Optional</td><td>Max 10</td><td>33109</td>
                            </tr>
                            <tr>
                                <td>CountryCode</td><td>String</td><td>Optional</td><td>Max 50</td><td>US</td>
                            </tr>
                            <tr>
                                <td>EntryDate</td><td>Date</td><td>Required</td><td>Must be valid date. (Represents when person became your client)</td><td>1/10/2022</td>
                            </tr>
                            <tr>
                                <td>LastOrderDate</td><td>Date</td><td>Optional</td><td></td><td>12/30/2022</td>
                            </tr>
                            <tr>
                                <td>AccountStatus</td><td>String</td><td>Optional</td><td>Active, Inactive</td><td>Active</td>
                            </tr>
                            <tr>
                                <td>ContactPreference</td><td>String</td><td>Optional</td><td>none (default), morning, mid-may, evening, never</td><td>evening</td>
                            </tr>
                            <tr>
                                <td>CustomField1</td><td>String</td><td>Optional</td><td>Max 1024</td><td></td>
                            </tr>
                            <tr>
                                <td>CustomField2</td><td>String</td><td>Optional</td><td>Max 1024</td><td></td>
                            </tr>
                            <tr>
                                <td>CustomField3</td><td>String</td><td>Optional</td><td>Max 1024</td><td></td>
                            </tr>
                            <tr>
                                <td>CustomField4</td><td>String</td><td>Optional</td><td>Max 1024</td><td></td>
                            </tr>
                            <tr>
                                <td>CustomField5</td><td>String</td><td>Optional</td><td>Max 1024</td><td></td>
                            </tr>
                        </tbody>                        
                    </table>
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    <Link onClick={()=>setShowImportModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>

            <Modal show={showOptaviaModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Example format of Optavia<span style={{fontSize:'5pt',position:'relative',top:'-3px'}}>&reg;</span> import CSV file with available fields:</h3>
                <div className="container client-import-modal">
                    <table border="1px">
                        <thead>
                            <tr>
                                <th>Field Name</th><th>Data Type</th><th>Required?</th><th>Options</th><th>Example</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>OPTAVIAID</td><td>Number</td><td>Required</td><td>Numbers only</td><td>120001234567</td>
                            </tr>
                            <tr>
                                <td>FirstName</td><td>String</td><td>Required</td><td>Min 1, max 50</td><td>John</td>
                            </tr>
                            <tr>
                                <td>LastName</td><td>String</td><td>Required</td><td>Min 1, max 50</td><td>Doe</td>
                            </tr>
                            <tr>
                                <td>CountryCode</td><td>String</td><td>Optional</td><td>Max 50</td><td>US</td>
                            </tr>
                            <tr>
                                <td>Level</td><td>Number</td><td>Optional</td><td>Default is 0</td><td>1</td>
                            </tr>
                            <tr>
                                <td>Email</td><td>String</td><td>Required</td><td>Min length 5, max 255</td><td>john.doe@gmail.com</td>
                            </tr>
                            <tr>
                                <td>Phone</td><td>Number</td><td>Required</td><td>(Numbers only), min 7, max 12</td><td>18882225555</td>
                            </tr>                            
                            <tr>
                                <td>EntryDate</td><td>Date</td><td>Required</td><td>Must be valid date. (Represents when person became your client)</td><td>1/10/2022</td>
                            </tr>
                            <tr>
                                <td>LastOrderDate</td><td>Date</td><td>Optional</td><td></td><td>12/30/2022</td>
                            </tr>
                            <tr>
                                <td>AccountStatus</td><td>String</td><td>Optional</td><td>Active, Inactive</td><td>Active</td>
                            </tr>
                            <tr>
                                <td>PQV</td><td>Number</td><td>Optional</td><td>Default is 0</td><td>450.03</td>
                            </tr>
                            <tr>
                                <td>PremierMember</td><td>String</td><td>Optional</td><td>Default is No</td><td>Yes</td>
                            </tr>
                            <tr>
                                <td>CurrentCoachName</td><td>String</td><td>Optional</td><td>Max 150</td><td>Jane Doe</td>
                            </tr>
                            <tr>
                                <td>CurrentCoachID</td><td>Number</td><td>Optional</td><td>Numbers only</td><td>120007654321</td>
                            </tr>
                            <tr>
                                <td>GlobalDirector</td><td>String</td><td>Optional</td><td>Max 150</td><td>Sally Mae</td>
                            </tr>
                            <tr>
                                <td>PresidentialDirector</td><td>String</td><td>Optional</td><td>Max 150</td><td>Sally Mae</td>
                            </tr>
                        </tbody>                        
                    </table>
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    <Link onClick={()=>setShowOptaviaModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>

            <h3 className='h3-centered'>Import Clients</h3> 
            {limitReached.pass === false ? (
                <div className="error-box container-width-4">{limitReached.reason}</div>
            ):(           
                <div className='form-container'>
                    {!fileContents ? (
                    <><p style={{ color: "#0275d8", fontWeight: 'bold' }}>Upload a CSV file for client import. Excepted files: (.csv)</p><form onSubmit={handleSubmit}>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" value="normal" name="isNormal" id="isisNormal" checked={isNormal} onChange={handleCheck} />
                                <label className="form-check-label" htmlFor="isNormal">Normal Client Import</label> &nbsp;
                                <Link onClick={() => setShowImportModal(true)}><FontAwesomeIcon icon={faQuestionCircle} size="sm" /></Link><br/>
                                <input className="form-check-input" type="radio" value="optavia" name="isOptavia" id="isOptavia" checked={isOptavia} onChange={handleCheck} />
                                <label className="form-check-label" htmlFor="isOptavia">Optavia<span style={{fontSize:'5pt',position:'relative',top:'-3px'}}>&reg;</span> Client Import</label> &nbsp;
                                <Link onClick={() => setShowOptaviaModal(true)}><FontAwesomeIcon icon={faQuestionCircle} size="sm" /></Link><br/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="file">Choose File:</label>&nbsp;&nbsp;
                                <input type="file" name="file" onChange={handleChange} className="btn btn-primary shadow" />
                                {isSelected ? (
                                    <div>
                                        <div>Filename: {selectedFile.name}</div>
                                        <div>Filetype: {selectedFile.type}</div>
                                        <div>Size in bytes: {selectedFile.size}</div>
                                        <div>
                                            lastModifiedDate:{' '}
                                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                                        </div>
                                    </div>
                                ) : (
                                    <p>Select a file to show details</p>
                                )}
                                <div>&nbsp;</div>
                                <div style={{textAlign:'center'}}>
                                    <button className="btn btn-primary shadow">Upload</button>
                                </div>    
                            </div>
                        </form></>
                    ):(
                        <div className='form-container' style={{textAlign:"center",marginTop:"60px"}}>
                            {isOptavia === true ? (
                                <Link to={`/importoptavia`} className="btn btn-primary shadow" state={{fileContents: fileContents}} style={{margin:"auto"}}>Next</Link>
                            ):(
                                <Link to={`/importclients`} className="btn btn-primary shadow" state={{fileContents: fileContents}} style={{margin:"auto"}}>Next</Link>
                            )}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default ClientImport;