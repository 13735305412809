import React, { forwardRef } from "react";

//const Select = ({ref, name, label, value, items, keys, error, selectedOption, onChange}) => {
const Select = forwardRef((props, ref) => {
  const {name, label, items, keys, error, selectedOption, onChange, customClass, nullValue, size} = props;
  let {multiple} = props;
  if(multiple !== "true" && multiple !== "false"){
    multiple = false;
  }else if(multiple === "true"){
    multiple = true;
  }else if(multiple === "false"){
    multiple = false;
  }
  
  /* const test = [
    {key1:"Active",val1:"ActiveVal"},
    {key1:"Inactive",val1:"InactiveVal"},
    {key1:"Reverted",val1:"RevertedVal"}
  ]; */
  
  // convert options passed in to default structure used for the select
  // example if key : value passed in is "name":"Florida", "abbreviation":"FL"
  // it will be converted to "text":"Florida", "value":"FL"

  let selectItems = '[';
  let count = 0
  items.forEach(item => {
    selectItems += '{';
    let keyName = '';
    let valName = '';
    Object.keys(item).map(key => {      
      if(key === keys[0]) 
        keyName = `"text":"${item[key]}", `;
      if(key === keys[1]){
        valName = `"value":"${item[key]}"`;
      }
      if(keyName !== '' && valName !== ''){
        selectItems += keyName + valName;
        keyName = '';
        valName = '';
      }
      return null;
    });
    selectItems += '}';
    count++;
    if(count < Object.keys(items).length) selectItems += ',';
    return item;
  });
  selectItems += ']';
  selectItems = JSON.parse(selectItems);

  return (
    <div className="form-group mb-2">
      <label htmlFor={name}>{label}</label>
      <select 
        ref={ref} 
        name={name} 
        id={name} 
        className={`form-select shadow ` + customClass} 
        value={selectedOption}
        onChange={onChange} 
        multiple={multiple} 
        size={size}
      >
        {(nullValue !== '') ? (
          <option value={nullValue} />
        ):(
          <option value="" />
        )}
        {selectItems.map(item => (
            /* (
              (typeof selectedOption === 'number' ? Number(selectedOption) === Number(item.value) : selectedOption === item.value) ? 
              <option key={item.value} value={item.value} selected>{item.text}</option> : 
              <option key={item.value} value={item.value}>{item.text}</option>
            ) */
            <option key={item.value} value={item.value}>{item.text}</option>
          )
        )}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
});

export default Select;
