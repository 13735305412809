import React, { useState, useEffect, useRef } from 'react';
import { getUserStats } from "../../services/userService";

function UserStats({user}) {
    const dataFetchedRef = useRef(false);
    const [stats, setStats] = useState({
        totalSMS: 0,
        monthSMS: 0,
        clientCount: 0
    });

    useEffect(() => {
        async function fetchStats(){
            setStats(await getUserStats(user._id));
        }

        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            fetchStats();
            window.scrollTo(0, 0);
        }
    },[user._id]);

    return ( 
        <React.Fragment>
            <h3 style={{textAlign:'center'}}>My Stats</h3>
            <div className='container text-center'>
                <div className="row">
                    <div className="col" style={{textAlign:'right'}}>SMS Count This Billing Cycle:</div>
                    <div className="col" style={{textAlign:'left'}}>{stats.monthSMS}</div>
                </div>
                <div className="row">
                    <div className="col" style={{textAlign:'right'}}>Total SMS Count:</div>
                    <div className="col" style={{textAlign:'left'}}>{stats.totalSMS}</div>
                </div>
                <div className="row">
                    <div className="col" style={{textAlign:'right'}}>Total Clients:</div>
                    <div className="col" style={{textAlign:'left'}}>{stats.clientCount}</div>
                </div>
            </div>
        </React.Fragment>
     );
}

export default UserStats;