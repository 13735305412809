import React from 'react';

function Cookies({user}) {
    // we can enable this at another time if we decide to start using cookies, privacy policy will also need to be updated
    /*
    enable accept button click here in which we would set the user flag: cookieConsented to true
    */
    return ( 
        <div className="cookie-popup shadow" style={{display:'none'}}>
            <div>By choosing ‘Accept Cookies’ you allow ClientPro to collect device and browser information. Some of these cookies are necessary 
                for the website to function properly (e.g. security and login). Other cookies may allow us to personalize the website and 
                monitor website performance. For more information, see the Privacy Agreement.
                <div style={{width:'100%',textAlign:'center',marginTop:'8px'}}>
                    <button name="acceptCookies" className="btn btn-dark sm cookie-popup-accept shadow">Accept Cookies</button>
                </div>
            </div>
        </div>
    );
}

export default Cookies;