import React from 'react';
import { getMessage, mapMessageVariables } from '../../services/messageService';
import { toast } from 'react-toastify';


async function popMessage(messageID, user) {
    setTimeout(async () => {
        if(messageID){
            await getMessage(messageID)
                .then(async results => {
                    const client = {firstName: "Jane", lastName: "Doe", phone: "15555555555", email: "jdoe@gmail.com"};
                    const msg = await mapMessageVariables(results.message, user, client);

                    if(msg){
                        const toastProps = {
                            position: "top-center",
                            autoClose: 7000,
                            closeOnClick: true,
                            hideProgressBar: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"
                        }
                    
                        const theMsg = ({closeToast, toastProps}) => (
                            <div style={{width:"100%",maxHeight:'300px',overflow:'hidden scroll'}}>
                                <table style={{width:"100%",fontSize:"8px"}}>
                                    <thead>
                                        <tr><th style={{textAlign:"center"}}>{results.name}</th></tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{msg}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        );
                        
                        toast(theMsg, toastProps);

                    }
                }); 
        }
    },300);
};

export default popMessage;