import Joi from 'joi';
import React, {useState} from 'react';
import { toast } from 'react-toastify';
import {createMessage} from '../../services/messageService';
import Input from '../common/input';
//import Select from '../common/select';
import Textarea from '../common/textarea';
import MessageVariables from "../common/messageVariables";

function MessageAddNew({user, customStyle, source, handleSelectedMessage}) {
    const limit = Number(window.env.MESSAGE_LIMIT);
    //const [messageTypesFiltered, setMessageTypesFiltered] = useState([]);

    const [inputValues, setInputValue] = useState({
        userID: user._id,
        name: '',
        description: '',
        message: '',
        //type: ''
    });

    const [validation, setValidation] = useState({
        name: '',
        description: '',
        message: '',
        //type: ''
    });

    const refValidation = {
        name: '',
        description: '',
        message: '',
        //type: ''
    };

    const schema = Joi.object({
        name: Joi.string().min(1).max(50).required().label('Name'),
        description: Joi.string().max(255).empty('').label('Description'),
        message: Joi.string().min(3).max(limit).required().label('Message'),
        //type: Joi.string().allow('')
    }).options({abortEarly: false, allowUnknown: true});

    function validate(e){
        const result = schema.validate(inputValues);     
        if(result.error){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.error.details.find(e => e.context.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return true;
        }
        return false;
    }

    function handleChange(e){
        const { name, value } = e.target;
        setInputValue({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;
        
        try {
            const result = await createMessage(inputValues);
            if(result){
                toast("Message added.");
                if(source === "home"){
                    handleSelectedMessage(result._id);
                }else{                    
                    setTimeout(function(){
                        window.location = '/messages';
                    },2000);
                }
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }
    };

    /* useEffect(() => {
        async function fetchMessages(){
            const typesUsed = [];
            let filtered = [];
            await getMessageList(user._id)
                .then(messages => {                    
                    messages.forEach(m => {
                        if(m.type !== undefined && m.type !== '')
                            typesUsed.push(m);
                    });
                    filtered = messageTypeOptions.filter(({ value: id1 }) => !typesUsed.some(({ type: id2 }) => id2 === id1));
                    setMessageTypesFiltered(filtered);
                });                                                    
        }

        fetchMessages();
    },[user._id]);
 */
    return ( 
        <React.Fragment>
            <h3 className='h3-centered'>Add Message</h3>
            <div className='form-container' style={customStyle}>
                <MessageVariables/>
                <form onSubmit={handleSubmit}>
                    <Input name="userID" label="" value={inputValues.userID} onChange={() => {}} error="" type="hidden" />
                    <Input name="name" label="Message Title" value={inputValues.name} onChange={(e) => handleChange(e)} error={validation.name} autoFocus={source === 'home' ? false : true} />
                    <Input name="description" label="Description (optional)" value={inputValues.description} onChange={(e) => handleChange(e)} error={validation.description} />
                    <Textarea 
                        className="form-control shadow" 
                        name="message" 
                        label="Message" 
                        value={inputValues.message} 
                        onChange={(e) => handleChange(e)} 
                        setInputValue={(value) => setInputValue({...inputValues, message: value})}
                        error={validation.message} 
                        rows="8"
                    />
                    {/* <Select name="type" 
                        label="Message Type (Used for first week client scheduling)" 
                        items={messageTypesFiltered} 
                        keys={['text','value']} 
                        nullValue="" 
                        onChange={(e) => handleChange(e)}
                        selectedOption={inputValues.type} 
                        customClass="cn-custom"
                    />  */}
                    <div>&nbsp;</div>
                    <div style={{textAlign:"center"}}><button className="btn btn-primary shadow">Add Message</button></div>
                    <div>&nbsp;</div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default MessageAddNew;
