import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {getLists, getList} from '../../services/listService';
import {getClient} from '../../services/clientService';
import Select from '../common/select';
import ListAddNew from "../lists/listAddNew";
import Modal from 'react-bootstrap/Modal';

function Step4({onStep4NextClick, onStep4BackClick, user, selectedList, setSelectedList}) {
    const [lists, setLists] = useState({});
    const [nextDisabled, setNextDisabled] = useState("disabled");
    const [displayOr, setDisplayOr] = useState("block");
    const [showModal, setShowModal] = useState(false);
    const [selectedListDetails, setSelectedListDetails] = useState([]);
    const [showPreviewButton, setShowPreviewButton] = useState(false);

    function handleChange(e){
        const { value } = e.target;
        handleSelectedList(value);
        if(value !== ''){
            setNextDisabled("");
            setDisplayOr("none");
        }else{
            setNextDisabled("disabled");
            setDisplayOr("block");
        }
    }

    async function handleSelectedList(_id){
        //console.log('handleSelectedList _id', _id);
        if(_id !== ''){
            setSelectedList(_id);
            // get list
            await getList(_id)
                .then(async list => {
                    if(list){
                        var clientArray = [];
                        list.clients.forEach(async c => {                            
                            await getClient(c)
                                .then(client => {
                                    if(client)
                                        clientArray.push(client);
                                });
                        });
                        setTimeout(() => {
                            setSelectedListDetails(clientArray);
                            setShowPreviewButton(true);
                        },1000);
                    }
                });
            setNextDisabled("");
            setDisplayOr("none");
        }else{
            setSelectedList("");
        }
    }

    useEffect(() => {
        async function fetchLists(){
            setLists(await getLists(user._id));                          
        }
        if(selectedList !== ''){
            handleSelectedList(selectedList);
        }

        fetchLists();
    },[user._id, selectedList]);

    return (  
        <React.Fragment>

            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Selected List Details</h3>
                <div className="container">
                    {Object.keys(selectedListDetails).map(sld =>
                    <div className="row" key={selectedListDetails[sld].phone}>
                        <div className="col">
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Client:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{selectedListDetails[sld].firstName} {selectedListDetails[sld].lastName}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Phone:</div>
                                <div className="col" style={{maxWidth:"70%"}}>+{selectedListDetails[sld].phone}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{width:"100%"}}><hr/></div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    <Link onClick={()=>setShowModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>

        {Object.keys(lists).length > 0 &&  
            <div style={{width:"50%",margin:"auto"}}>          
            <Select 
                name="list" 
                label="Select List" 
                value={selectedList} 
                selectedOption={selectedList} 
                items={lists} 
                keys={['name','_id']} 
                onChange={(e) => handleChange(e)} 
            />
            <div style={{display:displayOr}}>Or</div>
            </div>
        }
        {!selectedList &&
        <div style={{width:"100%"}}>           
            <ListAddNew 
                user={user} 
                customStyle={{width:"100%",margin:"auto"}}
                source="home"
                handleSelectedList={handleSelectedList}
            />
        </div>
        }
        <div style={{textAlign:"center"}}>
            <Link className="btn btn-secondary shadow" onClick={onStep4BackClick}>Back</Link>
                { selectedList !== '' &&
                <span>&nbsp;&nbsp;&nbsp;
                <Link className={`btn btn-light shadow ${showPreviewButton === false ? 'disabled-link' : ''}`} onClick={() => {setShowModal(true)}}>
                    {(showPreviewButton === false ? <span>Loading...</span> : <span>Preview List</span>)}
                </Link>
                </span>
                }
            &nbsp;&nbsp;&nbsp;<Link className={`btn btn-primary shadow ` + nextDisabled} onClick={onStep4NextClick}>Next</Link>
        <br/>
        </div>
        </React.Fragment>
    );
}

export default Step4;