import React, { forwardRef } from 'react';
import DatePicker from "react-datepicker";

const CNDate = forwardRef((props, ref) => {
    const {name, label, value, selected, onChange, error, customClass} = props;
    return ( 
        <div className="form-group mb-2">
            <label htmlFor={name}>{label}</label>
            <DatePicker 
                ref={ref}
                value={value} 
                selected={selected} 
                onChange={onChange} 
                className={`form-control shadow `+customClass} 
            /> 
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
});

export default CNDate;