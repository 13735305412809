import moment from 'moment';

export function formatDate(date){
    let myDate = new Date(date);
    return `${myDate.getMonth()+1}/${myDate.getDate()}/${myDate.getFullYear()}`;
}

export function formatDateTime(date){
    let d = new Date(date);
    //console.log(`${d.getMonth()}/${d.getDate()}/${d.getFullYear()} ${d.getHours() > 9 ? d.getHours() : '0' + d.getHours().toString()}:${d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes().toString()} EST`);
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()} ${d.getHours() > 9 ? d.getHours() : '0' + d.getHours().toString()}:${d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes().toString()} EST`;
}

export function convertEpochTime(timeEpoch){
    var myDate = new Date(timeEpoch);
    let dateStr = reverseString(myDate.toLocaleString());
    let amPM = reverseString(dateStr.substring(0,2));
    dateStr = reverseString(dateStr.substring(6)) + ' ' + amPM;
    //console.log('timeEpoch:',timeEpoch);
    //console.log('myDate:',myDate);
    //console.log('locale:',myDate.toLocaleTimeString);
    //return myDate.toLocaleString();
    return dateStr;
}

function reverseString(str) {
    return str.split("").reverse().join("");
}
 
export function getDurationWithMS(timestamp, delay) {
    let now = Date.now();
    let scheduledTime = Number(timestamp) + Number(delay);
    /* console.log('timestamp',timestamp);
    console.log('delay', delay);
    console.log('now',now);
    console.log('scheduledTime',scheduledTime); */
    let tmpTime = moment.duration(scheduledTime - now);
    return tmpTime.days() + ' Days, ' + tmpTime.hours() + ' Hours, ' + tmpTime.minutes() + ' minutes';
}