import React, { useState, useEffect, useRef } from 'react';
import { getUser, updatePassword } from "../../services/userService";
import Input from "../common/input";
import { toast } from "react-toastify";
import bcrypt from "bcryptjs-react";

function ChangePassword({user}) {
    const dataFetchedRef = useRef(false);
    const [errorBoxDisplay, setErrorBoxDisplay] = useState('none');
    const [errorBoxText, setErrorBoxText] = useState('');
    const [inputValues, setInputValue] = useState({
        _id: user._id,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        savedPassword: '',
        oldPassword: '',
        password: '',
        confirmPassword: ''
    });

    const [validation, setValidation] = useState({
        oldPassword: '',
        password: '',
        confirmPassword: ''
    });

    const refValidation = {
        oldPassword: '',
        password: '',
        confirmPassword: '' 
    };

    function handleChange(e){
        const { name, value } = e.target;
        setInputValue({...inputValues, [name]: value });
        setValidation({validation, [name]: ''});
    }

    function myValidation(e){
        let result = [];
        Array.prototype.forEach.call(e.target.elements, (element) => {
            const {name, value} = element;
            //console.log('name: ' + name + ' value: ' + value);
            switch(name){
                case 'oldPassword':
                    if(value === ''){
                        result.push({key: name, message: 'Old Password is required'});
                    }
                break;
                case 'password':
                    if(value === ''){
                        result.push({key: name, message: 'New Password is required'});
                    }
                break;
                case 'confirmPassword':
                    if(value === ''){
                        result.push({key: name, message: 'Confirm New Password is required'});
                    }
                break;
                default:
                break;
            }
        });
       return result;
    }

    function validate(e){
        const result = myValidation(e);
        
        if(result.length){
            Object.keys(refValidation).forEach(field => {
                let errorDetails = result.find(e => e.key === field);
                if(errorDetails)
                    refValidation[field] = errorDetails.message;
            });
            setValidation(refValidation);
            return result;
        }

        if(bcrypt.compareSync(inputValues.oldPassword, inputValues.savedPassword) === false){
            setErrorBoxText('Old Password is invalid.');
            setErrorBoxDisplay('block');
            return true;
        }

        if(inputValues.password !== inputValues.confirmPassword){
            setErrorBoxText('New Password and Confirm Password must be the same.');
            setErrorBoxDisplay('block');
            return true;
        }
        
        setErrorBoxText('');
        setErrorBoxDisplay('none');
        return false;
    }

    async function handleSubmit(e){
        e.preventDefault();
        const errors = validate(e);
        if(errors) return;        
        try {
            const result = await updatePassword(inputValues);
            if(result.hasOwnProperty('user')){
                //console.log('result',result);
                toast('Password updated.');
                // update the current user object
                if(result.hasOwnProperty('token')){
                    localStorage.setItem("token", result.token);
                }
                setTimeout(function(){
                    window.location = '/profile';
                },2000);
            }
        } catch (ex) {
            if(ex.response && ex.response.status === 400){
                toast(ex.response.message);
            }
        }        
    };

    useEffect(() => {
        async function fetchUser(){
            await getUser(user._id)
                .then(results => {
                    const data = {
                        _id: results._id,
                        firstName: results.firstName,
                        lastName: results.lastName,
                        email: results.email,
                        phone: results.phone,
                        password: '',
                        savedPassword: results.password,
                        oldPassword: '',
                        confirmPassword: ''
                    };
                    setInputValue(data);
                });
        }

        if(dataFetchedRef.current === false){
            dataFetchedRef.current = true;
            fetchUser();
            window.scrollTo(0,0);
        }
    },[user._id, inputValues]);

    return ( 
        <React.Fragment>
            <div className="container-width-4">
                <h3 className="h3-centered">Reset Password</h3>
                <form onSubmit={handleSubmit}>
                    <Input name="oldPassword" label="Old Password" value={inputValues.oldPassword} onChange={(e) => handleChange(e)} error={validation.oldPassword} type="password" />
                    <Input name="password" label="New Password" value={inputValues.password} onChange={(e) => handleChange(e)} error={validation.password} type="password" />
                    <Input name="confirmPassword" label="Confirm New Password" value={inputValues.confirmPassword} onChange={(e) => handleChange(e)} error={validation.confirmPassword} type="password" />
                    <div id="errorBox" className="error-box" style={{display:`${errorBoxDisplay}`}}>{errorBoxText}</div>
                    <div>&nbsp;</div>
                    <div style={{textAlign:'center'}}>
                        <button className="btn btn-primary">Change Password</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default ChangePassword;