import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {getMessageList, deleteMessage} from '../../services/messageService';
import PopDelete from "../common/popDelete";
import popMessage from "../common/popMessage";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen, faMessage, faTrash, faClipboard, faChartLine } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

function MessageList({user}) {
    const [messages, setMessages] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [messageLog, setMessageLog] = useState({});
    const dataFetchedRef = useRef(false);

    async function popStats(message){
        if(message.hasOwnProperty('stats')){
            const toastProps = {
                position: "top-center",
                autoClose: 7000,
                closeOnClick: true,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            }

            var _lastSentDate = new Date(message.stats.lastSentDate);
            /* console.log(_lastSentDate);
            console.log('lastJobName',message.stats.lastJobName); */
            _lastSentDate = _lastSentDate.getTime();
            
            const msg = ({closeToast, toastProps}) => (
                <div style={{width:"100%"}}>
                    <table style={{width:"100%",fontSize:"8px"}}>
                        <thead>
                            <tr><th colSpan="2" style={{textAlign:"center"}}>Message Stats</th></tr>
                        </thead>
                        <tbody>
                        <tr><td colSpan="2"><b>{message.name}</b></td></tr>
                        <tr>
                            <td width="30%">Last Sent On:</td><td width="70%">{new Date(new Date(_lastSentDate).toGMTString()).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td width="30%">Last Sent To:</td><td width="70%">{message.stats.lastSentListName}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Total Successful Client Sends: {message.stats.totalClientSends}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
            
            toast(msg, toastProps);
        }
    }

    function popDelete(item){
        toast(
            <PopDelete 
                handleDelete={handleDelete} 
                itemId={item._id} 
                itemName={item.name}
                itemType='message'
                toastId='delete'
        />
        ,{pauseOnHover: true, pauseOnFocusLoss: true, toastid: 'delete'});
    }

    async function handleDelete(_id){
        const res = await deleteMessage(_id);
        if(res){
            toast(res.message);
           setTimeout(function(){
                window.location = '/messages';
            },2000);
        }
    }

    useEffect(() => {
        async function fetchMessages(){
            try{
                //setMessages(await getMessageList(user._id));
                const messages = await getMessageList(user._id);
                if(messages){
                    //console.log('messages',messages);
                    setMessages(messages);
                }
            }catch(ex){
                toast('No messages found.');
            }                            
        }
        if(dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchMessages();
        window.scrollTo(0, 0);
    },[user._id]);

    return ( 
        <React.Fragment>
            <Modal show={showModal} className="modal" centered="true" style={{margin:"auto"}}>
                <h3 className="h3-centered">Latest Message Send Results</h3>
                <div className="container">
                    {Object.keys(messageLog).map(m =>
                    <div className="row" key={messageLog[m].phone}>
                        <div className="col">
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Client:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{messageLog[m].clientName}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Phone:</div>
                                <div className="col" style={{maxWidth:"70%"}}>+{messageLog[m].phone}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Status:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{messageLog[m].status}</div>
                            </div>
                            <div className="row">
                                <div className="col" style={{maxWidth:"30%"}}>Date Time:</div>
                                <div className="col" style={{maxWidth:"70%"}}>{new Date(new Date(new Date(messageLog[m].timestamp).getTime()).toGMTString()).toLocaleString()}</div>
                            </div>
                            {messageLog[m].status === "Error" &&
                                <div className="row">
                                    <div className="col" style={{maxWidth:"30%"}}>Error:</div>
                                    <div className="col" style={{maxWidth:"70%",color:"red"}}>{messageLog[m].result}</div>
                                </div>
                            }
                            <div className="row">
                                <div className="col" style={{width:"100%"}}><hr/></div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <div style={{textAlign:"center",margin:"10px 0px"}}>
                    <Link onClick={()=>setShowModal(false)} className="btn btn-primary shadow cn-custom">Close</Link>
                </div>
            </Modal>
            <h3 className="h3-centered">My Messages</h3>
            
            <Link to="/addmessage" className="btn btn-primary" style={{ marginBottom: 20 }}>Add Message</Link>            
            
            <p>Showing {Object.keys(messages).length} messages in the database.</p>
            <table className="message-list table table-striped">
                <thead>
                    <tr>
                        <th scope="col" width="30%">Name</th>
                        <th scope="col" width="40%">Description</th>
                        {/* <th scope="col" width="10%">Type</th> */}
                        <th scope="col" width="5%" style={{textAlign:"center"}}>Sent</th>
                        <th scope="col" width="5%" style={{textAlign:"center"}}>Preview</th>                        
                        <th scope="col" width="5%" style={{textAlign:"center"}}>Stats</th>
                        <th scope="col" width="5%" style={{textAlign:"center"}}>Log</th>
                        <th scope="col" width="10%"></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(messages).map((key) => 
                    <tr key={key}>                        
                        <td>{messages[key].name}</td> 
                        <td>{messages[key].description}</td>
                        {/* <td>{messages[key].type}</td> */}
                        <td align="center">{messages[key].sendCount}</td>
                        <td align="center">
                            <Link onClick={() => {popMessage(messages[key]._id, user)}}>
                                <FontAwesomeIcon icon={faMessage} size="sm" />
                            </Link>
                        </td>                        
                        <td align="center">
                            <Link onClick={() => {popStats(messages[key])}}>
                                <FontAwesomeIcon icon={faChartLine} size="sm" />
                            </Link>
                        </td>
                        <td align="center">
                            <Link onClick={() => {setShowModal(true, setMessageLog(messages[key].hasOwnProperty('log') ? messages[key].log : {}))}}>
                                <FontAwesomeIcon icon={faClipboard} size="sm" />
                            </Link>
                        </td>
                        <td align="center">
                            <Link to={`/message`} state={{message: messages[key]}}><FontAwesomeIcon icon={faFilePen} size="sm" /></Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link onClick={() => popDelete(messages[key])}><FontAwesomeIcon icon={faTrash} size="sm" /></Link>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </React.Fragment>
    );
}

export default MessageList;