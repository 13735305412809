import React from "react";
import Input from '../common/input';
import Select from '../common/select';
import {default as cronData} from '../../services/cronService.json';

function TaskForm({inputValues, user, messages, lists, handleChange, cronMinute, cronHour, cronDayOfMonth, cronMonth, cronDayOfWeek, cronAsText, validation, formClass}) {
    return ( 
        <React.Fragment>
            <div className="form-container">
                <Input name="userID" label="" value={inputValues.userID} onChange={() => {}} error="" type="hidden" />
                {messages && messages.length &&
                <Select 
                    name="message" 
                    label="Select Message" 
                    value={inputValues.messageID} 
                    selectedOption={inputValues.messageID} 
                    items={messages} 
                    keys={['name','_id']} 
                    onChange={handleChange} 
                    error={validation.messageID}
                    customClass=""                    
                />
                }
                {lists && lists.length &&
                <Select 
                    name="list"
                    label="Select Client List" 
                    value={inputValues.listID} 
                    selectedOption={inputValues.listID} 
                    items={lists} 
                    keys={['name','_id']} 
                    onChange={handleChange}
                    error={validation.listID} 
                    customClass=""
                />
                }
                <Input name="name" label="Task Name (Example: My Saturday 6:00 PM Message)" value={inputValues.name} onChange={handleChange} error={validation.name} customClass="" />
            </div>
            <div style={{textAlign:"center",fontSize:"16pt"}}>
                <b>{cronMinute.toString()}&nbsp;&nbsp;{cronHour.toString()}&nbsp;&nbsp;{cronDayOfMonth.toString()}&nbsp;&nbsp;{cronMonth.toString()}&nbsp;&nbsp;{cronDayOfWeek.toString()}</b>
                <div style={{fontSize:"12pt"}}><b>{cronAsText}</b></div>
            </div>
            <div className={formClass} style={{fontSize:"11pt"}}>
                <div className="row">
                    {user.admin === true &&
                        <div className="col">                        
                            <Select name="minute" 
                                label="Minutes" 
                                items={cronData.minute} 
                                keys={['name','value']} 
                                nullValue="*" 
                                onChange={handleChange}
                                selectedOption={cronMinute} 
                                customClass="cn-custom"
                                size="6"
                            />
                        </div>
                    }
                    <div className="col">
                        <Select name="hour" 
                            label="Hour of the Day" 
                            items={cronData.hour} 
                            keys={['name','value']} 
                            nullValue="*" 
                            onChange={handleChange}
                            selectedOption={cronHour} 
                            customClass="cn-custom" 
                            multiple="true"
                            size="6"
                        /> 
                    </div>
                    <div className="col">
                        <Select name="dayOfMonth" 
                            label="Day of the Month" 
                            items={cronData.dayOfMonth} 
                            keys={['name','value']} 
                            nullValue="*" 
                            onChange={handleChange}
                            selectedOption={cronDayOfMonth} 
                            customClass="cn-custom"
                            multiple="true"
                            size="6"
                        /> 
                    </div>
                    <div className="col">
                        <Select name="month" 
                            label="Month" 
                            items={cronData.month} 
                            keys={['name','value']} 
                            nullValue="*" 
                            onChange={handleChange}
                            selectedOption={cronMonth} 
                            customClass="cn-custom"
                            multiple="true"
                            size="6"
                        /> 
                    </div>
                    <div className="col">
                        <Select name="dayOfWeek" 
                            label="Day of the Week" 
                            items={cronData.dayOfWeek} 
                            keys={['name','value']} 
                            nullValue="*" 
                            onChange={handleChange}
                            selectedOption={cronDayOfWeek} 
                            customClass="cn-custom"
                            multiple="true"
                            size="6"
                        /> 
                    </div>
                </div>
                <div className="row"></div>
            </div>
        </React.Fragment>
     );
}

export default TaskForm;