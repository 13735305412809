import React from 'react';
import { Link } from 'react-router-dom';

function Step2({onStep2NextClick}) {
    window.scrollTo(0, 0);
    return (
        <div style={{textAlign:"center"}} >
        <Link 
            className="btn btn-primary shadow" 
            onClick={onStep2NextClick} 
        >Send a Message</Link>
        </div>
    );
}

export default Step2;
