import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    const currentYear = new Date().getFullYear();
    return ( 
        <div className="footer-container">
            <div className="footer-inner-container">
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <Link to="/privacy">Privacy Policy</Link> &nbsp;|&nbsp;
                            <Link to="/terms">Terms and Conditions</Link> &nbsp;|&nbsp;
                            <Link to="/contact">Contact</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        &copy;{currentYear} ClientPro, All rights reserved.
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Footer;