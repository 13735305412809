import React, {useEffect, useRef, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './components/home';
import Signup from './components/auth/signup';
import Login from './components/auth/login';
import Menu from './components/menu.jsx';
import Footer from "./components/footer";
import ClientList from './components/clients/clientList';
import Client from './components/clients/client';
import ClientAddNew from './components/clients/clientAddNew';
import ClientUpload from './components/clients/clientUpload';
import ImportClients from "./components/imports/importClients";
import MessageList from './components/messages/messageList';
import Message from './components/messages/message';
import MessageAddNew from './components/messages/messageAddNew';
import ListList from './components/lists/listList';
import List from './components/lists/list';
import ListAddNew from './components/lists/listAddNew';
import TaskList from "./components/tasks/taskList";
import Task from "./components/tasks/task";
import TaskAddNew from "./components/tasks/taskAddNew";
import NonRepeatingTasks from "./components/tasks/nonrepeatingTasks";
import NotificationList from "./components/notifications/notificationList";
import EmailTemplate from "./components/admin/emailTemplate";
import Optavia from './components/imports/optavia';
import Profile from './components/users/profile';
import Subscribe from './components/subscription/subscribe';
import Unsubscribe from './components/subscription/unsubscribe';
import Validation from "./components/auth/validation";
import ChangePassword from "./components/users/changePassword";
import UserStats from "./components/users/userStats";
import NotFound from "./components/notFound";
import Redirect from "./components/redirect";
import auth from "./services/authService";
import io from 'socket.io-client';
import { getNotifications } from "./services/notificationService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Privacy from "./components/legal/privacy";
import Terms from "./components/legal/terms";
import Contact from "./components/contact";
import Cookies from "./components/legal/cookies";

function App() {
  const location = useLocation();
  const user = auth.authCurrentUser();
  const bullBoardUrl = `${window.env.WEB_SOCKET_URL}/api/admin/queues/`;
  var ioSocket = {};

  if(window.env.WEB_SOCKET_ENABLED === "TRUE"){
    ioSocket = io.connect(window.env.WEB_SOCKET_URL);
  }
  const dataFetchedRef = useRef(false);
  const [notificationCount, setNotificationCount] = useState(0);
  
  // redirect user if they are not paid up
  if(user && user.plan === "none" && location.pathname !== "/subscribe"){
    window.location = "/subscribe";
  }

  useEffect(() => {
      async function fetchNotifications(_id){
        await getNotifications(_id)
          .then(notifications => {
            let count = 0;
            notifications.forEach(n => {
              if(n.status === 'unread')
                count++;
            });
            setNotificationCount(count);
          });
      }

      if(dataFetchedRef.current) return;
      dataFetchedRef.current = true;      
      
      //socket.emit('client_message', {userID: user._id, message: "hello"});
      if(user){
        fetchNotifications(user._id);
        console.log('App user',user);

        if(window.env.WEB_SOCKET_ENABLED === "TRUE"){
          ioSocket.on('server_message', (data) => {
              if((data.userID === user._id || data.userID === 'everyone') && typeof data.message !== 'undefined'){
                  //console.log('data', data);
                  let serverMessage = data.message;
                  toast(serverMessage);
                  setNotificationCount(data.count);
                  console.log('server message: ',data.message);
              }                
          });
        }
      }

      document.title = window.env.APP_NAME;

      //return () => ioSocket.removeAllListeners('server_message');
  });
  
  return (
    <React.Fragment>
      <Menu user={user} notificationCount={notificationCount} />
      <ToastContainer autoClose="3000"/>
      <main className="main-container">
      {user !== null ? (
      <Routes>
          <Route path="/" element={<Home user={user} />} />
        {user !== null && user.admin === true && 
          <Route>
          <Route path="/bullboard" element={<Redirect url={bullBoardUrl} />} />
          <Route path="/email" element={<EmailTemplate />} /> 
          </Route>
        }                
          <Route path="/profile" element={<Profile user={user} />} />
          <Route path="/notifications" element={<NotificationList user={user} />} />
          <Route path="/changepassword" element={<ChangePassword user={user} />} />
          <Route path="/stats" element={<UserStats user={user} />} />

          <Route path="/clients" element={<ClientList user={user} />} />
          <Route path="/client" element={<Client user={user} />}>
            <Route path=":_id" element={<Client/>} />
          </Route>
        
          <Route>
            <Route path="/addclient" element={<ClientAddNew user={user} />} />
            <Route path="/uploadclients" element={<ClientUpload user={user} />} />            
            <Route path="/importoptavia" element={<Optavia user={user} />} />
            <Route path="/importclients" element={<ImportClients user={user} />} />
          </Route>
        
          <Route path="/messages" element={<MessageList user={user} />} />
          <Route path="/message" element={<Message user={user} />}>
            <Route path=":_id" element={<Message/>} />
          </Route>
          <Route path="/addmessage" element={<MessageAddNew user={user} />} />
          
          <Route path="/lists" element={<ListList user={user} />} />
          <Route path="/list" element={<List user={user} />}>
            <Route path=":_id" element={<List/>} />
          </Route>
          <Route path="/addlist" element={<ListAddNew user={user} />} />
          
          <Route path="/tasks" element={<TaskList user={user} />} />
          <Route path="/task" element={<Task user={user} />}>
            <Route path=":_id" element={<Task/>} />
          </Route>
        
          <Route>
            <Route path="/addtask" element={<TaskAddNew user={user} />} />

            <Route path="/calendar" element={<NonRepeatingTasks user={user} />}>
              <Route path="/calendar/:year/:month" element={<NonRepeatingTasks user={user} />}/>
            </Route>
          </Route>
        
          <Route path="/subscribe" element={<Subscribe user={user} />} />
          <Route path="/unsubscribe" element={<Unsubscribe user={user} />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact user={user} />} />

          <Route path="*" element={<NotFound/>}/>
      </Routes>
      ):(
        <Routes>
         {/* <Route path="/splashpage" element={<SplashPage/>}/> */}
          <Route path="/" element={<Home user={user} />} />
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/validation" element={<Validation/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact user={user} />} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      )}
      </main>
      {user && 
        <Cookies user={user} />
      }
      <Footer/>
    </React.Fragment>
  );
}

export default App;