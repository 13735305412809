import React from 'react';
import appLogo from '../../assets/logo.png';

function EmailTemplate() {

    return ( 
        <>
            <div style={{width:'100%',backgroundColor:'#48d1cc',fontFamily:'font-family: GothamBook,Helvetica,Arial,sans-serif'}}>
                <div style={{width:'100%',height:'50px',backgroundColor:'#212529',padding:'10px 0px'}}>
                    <div style={{textAlign:'center'}}>
                        <img style={{width:'30px',height:'20px'}} src={appLogo} alt="ClientPro" />
                        <span style={{color:'#48d1cc',fontWeight:'bold',fontSize:'12pt'}}>&nbsp;ClientPro</span>                      
                    </div>
                </div>
                <div style={{width:'100%',minHeight:'200px',padding:'10px',border:'solid 2px #212529',fontSize:'8pt'}}>
                    <p>Hello Client Name,</p>
                    <p>My 5 Minute Message completed successfully. You can view the log from your My Scheduled Messages page.</p>
                    <p>Your task was executed on: 12/23/2022 15:12 EST</p>
                </div>
                <div style={{width:'100%',height:'30px',backgroundColor:'#212529'}}>
                    <div style={{width:'100%',textAlign:'center',color:'#48d1cc',padding:'10px',fontSize:'6pt'}}>
                        <p>&copy;2022 ClientPro, All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
     );
}

export default EmailTemplate;
